const counties = [
  { id: "60f05826a26f650268a1f7c2", name: "mombasa" },
  { id: "60f058dea26f650268a1f7c5", name: "kwale" },
  { id: "60f05901a26f650268a1f7c8", name: "kilifi" },
  { id: "60f05948a26f650268a1f7cb", name: "tana river" },
  { id: "60f05960a26f650268a1f7ce", name: "lamu" },
  { id: "60f05985a26f650268a1f7d1", name: "taita taveta" },
  { id: "60f059a9a26f650268a1f7d4", name: "garissa" },
  { id: "60f059c7a26f650268a1f7d7", name: "wajir" },
  { id: "60f06354a26f650268a1f7dd", name: "mandera" },
  { id: "60f06594a26f650268a1f7e3", name: "marsabit" },
  { id: "60f06a73a26f650268a1f7e9", name: "isiolo" },
  { id: "60f06aaaa26f650268a1f7ee", name: "meru" },
  { id: "60f06ac3a26f650268a1f7f1", name: "tharaka nithi" },
  { id: "60f06af5a26f650268a1f7f7", name: "embu" },
  { id: "60f06b2ba26f650268a1f7fa", name: "kitui" },
  { id: "60f06b5da26f650268a1f7fd", name: "machakos" },
  { id: "60f06b69a26f650268a1f800", name: "makueni" },
  { id: "60f06b84a26f650268a1f803", name: "nyandarua" },
  { id: "60f06b93a26f650268a1f806", name: "nyeri" },
  { id: "60f06bb5a26f650268a1f80b", name: "kirinyaga" },
  { id: "60f06be5a26f650268a1f80e", name: "murang’a" },
  { id: "60f06bf2a26f650268a1f811", name: "kiambu" },
  { id: "60f06c38a26f650268a1f814", name: "turkana" },
  { id: "60f06c4ca26f650268a1f81a", name: "west pokot" },
  { id: "60f06c5ea26f650268a1f81d", name: "samburu" },
  { id: "60f06c77a26f650268a1f820", name: "trans nzoia" },
  { id: "60f06c8aa26f650268a1f823", name: "uasin gishu" },
  { id: "60f06ca3a26f650268a1f826", name: "elgeyo marakwet" },
  { id: "60f06cb4a26f650268a1f829", name: "nandi" },
  { id: "60f06cc4a26f650268a1f82c", name: "baringo" },
  { id: "60f06cdaa26f650268a1f82f", name: "laikipia" },
  { id: "60f06ce5a26f650268a1f832", name: "nakuru" },
  { id: "60f06cf0a26f650268a1f835", name: "narok" },
  { id: "60f06cfea26f650268a1f838", name: "kajiado" },
  { id: "60f06d13a26f650268a1f83b", name: "kericho" },
  { id: "60f06d1fa26f650268a1f83e", name: "bomet" },
  { id: "60f06d8ca26f650268a1f845", name: "kakamega" },
  { id: "60f06d9ca26f650268a1f848", name: "vihiga" },
  { id: "60f06da9a26f650268a1f84b", name: "bungoma" },
  { id: "60f06db6a26f650268a1f84e", name: "busia" },
  { id: "60f06dfaa26f650268a1f856", name: "siaya" },
  { id: "60f06e0aa26f650268a1f859", name: "kisumu" },
  { id: "60f06e17a26f650268a1f85c", name: "homa bay" },
  { id: "60f06e25a26f650268a1f85f", name: "migori" },
  { id: "60f06e32a26f650268a1f862", name: "kisii" },
  { id: "60f06e3fa26f650268a1f865", name: "nyamira" },
  { id: "60f06e7ba26f650268a1f86d", name: "nairobi" },
];

const constituencies = [
  {
    id: "60f0af5a9d08c91e109695d2",
    name: "changamwe",
    countyId: "60f05826a26f650268a1f7c2",
  },
  {
    id: "60f0b13a53b7bd2f2ca8ce00",
    name: "jomvu",
    countyId: "60f05826a26f650268a1f7c2",
  },
  {
    id: "60f0b14e53b7bd2f2ca8ce04",
    name: "kisauni",
    countyId: "60f05826a26f650268a1f7c2",
  },
  {
    id: "60f0b15853b7bd2f2ca8ce08",
    name: "nyali",
    countyId: "60f05826a26f650268a1f7c2",
  },
  {
    id: "60f0b16253b7bd2f2ca8ce0c",
    name: "likoni",
    countyId: "60f05826a26f650268a1f7c2",
  },
  {
    id: "60f0b1ceca855b1b38aa25f3",
    name: "mvita",
    countyId: "60f05826a26f650268a1f7c2",
  },
  {
    id: "60f0b210ca855b1b38aa25f9",
    name: "msambweni",
    countyId: "60f058dea26f650268a1f7c5",
  },
  {
    id: "60f0b21aca855b1b38aa25fd",
    name: "lunga lunga",
    countyId: "60f058dea26f650268a1f7c5",
  },
  {
    id: "60f0b227ca855b1b38aa2601",
    name: "matuga",
    countyId: "60f058dea26f650268a1f7c5",
  },
  {
    id: "60f0b238ca855b1b38aa2605",
    name: "kinango",
    countyId: "60f058dea26f650268a1f7c5",
  },
  {
    id: "60f0b258ca855b1b38aa2609",
    name: "kilifi north",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b273ca855b1b38aa260d",
    name: "kilifi south",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b284ca855b1b38aa2613",
    name: "kaloleni",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b290ca855b1b38aa2617",
    name: "rabai",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b2a1ca855b1b38aa261b",
    name: "ganze",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b2adca855b1b38aa261f",
    name: "malindi",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b2b9ca855b1b38aa2623",
    name: "magarini",
    countyId: "60f05901a26f650268a1f7c8",
  },
  {
    id: "60f0b2dcca855b1b38aa2627",
    name: "garsen",
    countyId: "60f05948a26f650268a1f7cb",
  },
  {
    id: "60f0b2e9ca855b1b38aa262b",
    name: "galole",
    countyId: "60f05948a26f650268a1f7cb",
  },
  {
    id: "60f0b2f3ca855b1b38aa262f",
    name: "bura",
    countyId: "60f05948a26f650268a1f7cb",
  },
  {
    id: "60f0b30aca855b1b38aa2633",
    name: "lamu east",
    countyId: "60f05960a26f650268a1f7ce",
  },
  {
    id: "60f0b337ca855b1b38aa2638",
    name: "lamu west",
    countyId: "60f05960a26f650268a1f7ce",
  },
  {
    id: "60f0b357ca855b1b38aa263c",
    name: "taveta",
    countyId: "60f05985a26f650268a1f7d1",
  },
  {
    id: "60f0b365ca855b1b38aa2640",
    name: "wundanyi",
    countyId: "60f05985a26f650268a1f7d1",
  },
  {
    id: "60f0b371ca855b1b38aa2644",
    name: "mwatate",
    countyId: "60f05985a26f650268a1f7d1",
  },
  {
    id: "60f0b37bca855b1b38aa2648",
    name: "voi",
    countyId: "60f05985a26f650268a1f7d1",
  },
  {
    id: "60f0b3adca855b1b38aa264c",
    name: "garissa township",
    countyId: "60f059a9a26f650268a1f7d4",
  },
  {
    id: "60f0b3bfca855b1b38aa2652",
    name: "balambala",
    countyId: "60f059a9a26f650268a1f7d4",
  },
  {
    id: "60f0b3c8ca855b1b38aa2656",
    name: "lagdera",
    countyId: "60f059a9a26f650268a1f7d4",
  },
  {
    id: "60f0b3d1ca855b1b38aa265a",
    name: "dadaab",
    countyId: "60f059a9a26f650268a1f7d4",
  },
  {
    id: "60f0b3dbca855b1b38aa265e",
    name: "fafi",
    countyId: "60f059a9a26f650268a1f7d4",
  },
  {
    id: "60f0b3e7ca855b1b38aa2662",
    name: "ijara",
    countyId: "60f059a9a26f650268a1f7d4",
  },
  {
    id: "60f0b3ffca855b1b38aa2666",
    name: "wajir north",
    countyId: "60f059c7a26f650268a1f7d7",
  },
  {
    id: "60f0b406ca855b1b38aa266a",
    name: "wajir east",
    countyId: "60f059c7a26f650268a1f7d7",
  },
  {
    id: "60f0b410ca855b1b38aa266e",
    name: "tarbaj",
    countyId: "60f059c7a26f650268a1f7d7",
  },
  {
    id: "60f0b41dca855b1b38aa2672",
    name: "wajir west",
    countyId: "60f059c7a26f650268a1f7d7",
  },
  {
    id: "60f0b428ca855b1b38aa2676",
    name: "eldas",
    countyId: "60f059c7a26f650268a1f7d7",
  },
  {
    id: "60f0b431ca855b1b38aa267a",
    name: "wajir south",
    countyId: "60f059c7a26f650268a1f7d7",
  },
  {
    id: "60f0b446ca855b1b38aa267e",
    name: "mandera west",
    countyId: "60f06354a26f650268a1f7dd",
  },
  {
    id: "60f0b451ca855b1b38aa2682",
    name: "banissa",
    countyId: "60f06354a26f650268a1f7dd",
  },
  {
    id: "60f0b45bca855b1b38aa2686",
    name: "mandera north",
    countyId: "60f06354a26f650268a1f7dd",
  },
  {
    id: "60f0b465ca855b1b38aa268a",
    name: "mandera south",
    countyId: "60f06354a26f650268a1f7dd",
  },
  {
    id: "60f0b473ca855b1b38aa268e",
    name: "mandera east",
    countyId: "60f06354a26f650268a1f7dd",
  },
  {
    id: "60f0b47fca855b1b38aa2692",
    name: "lafey",
    countyId: "60f06354a26f650268a1f7dd",
  },
  {
    id: "60f0b4f4ca855b1b38aa2696",
    name: "moyale",
    countyId: "60f06594a26f650268a1f7e3",
  },
  {
    id: "60f0b506ca855b1b38aa269c",
    name: "north horr",
    countyId: "60f06594a26f650268a1f7e3",
  },
  {
    id: "60f0b512ca855b1b38aa26a0",
    name: "saku",
    countyId: "60f06594a26f650268a1f7e3",
  },
  {
    id: "60f0b51cca855b1b38aa26a4",
    name: "laisamis",
    countyId: "60f06594a26f650268a1f7e3",
  },
  {
    id: "60f0b535ca855b1b38aa26a8",
    name: "isiolo north",
    countyId: "60f06a73a26f650268a1f7e9",
  },
  {
    id: "60f0b546ca855b1b38aa26ac",
    name: "isiolo south",
    countyId: "60f06a73a26f650268a1f7e9",
  },
  {
    id: "60f0b55bca855b1b38aa26b0",
    name: "igembe south",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b56fca855b1b38aa26b6",
    name: "igembe central",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b57aca855b1b38aa26ba",
    name: "igembe north",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b583ca855b1b38aa26be",
    name: "tigania west",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b58fca855b1b38aa26c2",
    name: "tigania east",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b59aca855b1b38aa26c6",
    name: "north imenti",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b5a5ca855b1b38aa26ca",
    name: "buuri",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b5b2ca855b1b38aa26ce",
    name: "central imenti",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b5bfca855b1b38aa26d2",
    name: "south imenti",
    countyId: "60f06aaaa26f650268a1f7ee",
  },
  {
    id: "60f0b5dcca855b1b38aa26d6",
    name: "maara",
    countyId: "60f06ac3a26f650268a1f7f1",
  },
  {
    id: "60f0b5efca855b1b38aa26da",
    name: "chuka/igambang'ombe",
    countyId: "60f06ac3a26f650268a1f7f1",
  },
  {
    id: "60f0b657ca855b1b38aa26de",
    name: "tharaka",
    countyId: "60f06ac3a26f650268a1f7f1",
  },
  {
    id: "60f0b66eca855b1b38aa26e2",
    name: "manyatta",
    countyId: "60f06af5a26f650268a1f7f7",
  },
  {
    id: "60f0b679ca855b1b38aa26e6",
    name: "runyenjes",
    countyId: "60f06af5a26f650268a1f7f7",
  },
  {
    id: "60f0b685ca855b1b38aa26ea",
    name: "mbeere south",
    countyId: "60f06af5a26f650268a1f7f7",
  },
  {
    id: "60f0b68fca855b1b38aa26ee",
    name: "mbeere north",
    countyId: "60f06af5a26f650268a1f7f7",
  },
  {
    id: "60f0b6a6ca855b1b38aa26f2",
    name: "mwingi north",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6b0ca855b1b38aa26f6",
    name: "mwingi west",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6c4ca855b1b38aa26fa",
    name: "mwingi central",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6cfca855b1b38aa26fe",
    name: "kitui west",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6daca855b1b38aa2702",
    name: "kitui rural",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6e4ca855b1b38aa2706",
    name: "kitui central",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6f0ca855b1b38aa270a",
    name: "kitui east",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b6fcca855b1b38aa270e",
    name: "kitui south",
    countyId: "60f06b2ba26f650268a1f7fa",
  },
  {
    id: "60f0b732ca855b1b38aa2712",
    name: "masinga",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b73dca855b1b38aa2716",
    name: "yatta",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b749ca855b1b38aa271a",
    name: "kangundo",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b754ca855b1b38aa271e",
    name: "matungulu",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b75fca855b1b38aa2722",
    name: "kathiani",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b768ca855b1b38aa2726",
    name: "mavoko",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b772ca855b1b38aa272a",
    name: "machakos town",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b77cca855b1b38aa272e",
    name: "mwala",
    countyId: "60f06b5da26f650268a1f7fd",
  },
  {
    id: "60f0b791ca855b1b38aa2732",
    name: "mbooni",
    countyId: "60f06b69a26f650268a1f800",
  },
  {
    id: "60f0b799ca855b1b38aa2736",
    name: "kilome",
    countyId: "60f06b69a26f650268a1f800",
  },
  {
    id: "60f0b7a2ca855b1b38aa273a",
    name: "kaiti",
    countyId: "60f06b69a26f650268a1f800",
  },
  {
    id: "60f0b7abca855b1b38aa273e",
    name: "makueni",
    countyId: "60f06b69a26f650268a1f800",
  },
  {
    id: "60f0b7b4ca855b1b38aa2742",
    name: "kibwezi west",
    countyId: "60f06b69a26f650268a1f800",
  },
  {
    id: "60f0b7beca855b1b38aa2746",
    name: "kibwezi east",
    countyId: "60f06b69a26f650268a1f800",
  },
  {
    id: "60f0b7e6ca855b1b38aa274a",
    name: "kinangop",
    countyId: "60f06b84a26f650268a1f803",
  },
  {
    id: "60f0b832ca855b1b38aa274e",
    name: "kipipiri",
    countyId: "60f06b84a26f650268a1f803",
  },
  {
    id: "60f0b83bca855b1b38aa2752",
    name: "ol kalou",
    countyId: "60f06b84a26f650268a1f803",
  },
  {
    id: "60f0b845ca855b1b38aa2756",
    name: "ol jorok",
    countyId: "60f06b84a26f650268a1f803",
  },
  {
    id: "60f0b852ca855b1b38aa275a",
    name: "ndaragwa",
    countyId: "60f06b84a26f650268a1f803",
  },
  {
    id: "60f0b867ca855b1b38aa275e",
    name: "tetu",
    countyId: "60f06b93a26f650268a1f806",
  },
  {
    id: "60f0b872ca855b1b38aa2762",
    name: "kieni",
    countyId: "60f06b93a26f650268a1f806",
  },
  {
    id: "60f0b892ca855b1b38aa2766",
    name: "mathira",
    countyId: "60f06b93a26f650268a1f806",
  },
  {
    id: "60f0b89aca855b1b38aa276a",
    name: "othaya",
    countyId: "60f06b93a26f650268a1f806",
  },
  {
    id: "60f0b8a9ca855b1b38aa276e",
    name: "mukurweini",
    countyId: "60f06b93a26f650268a1f806",
  },
  {
    id: "60f0b8b6ca855b1b38aa2772",
    name: "nyeri town",
    countyId: "60f06b93a26f650268a1f806",
  },
  {
    id: "60f0b953ca855b1b38aa2776",
    name: "mwea",
    countyId: "60f06bb5a26f650268a1f80b",
  },
  {
    id: "60f0b96dca855b1b38aa277a",
    name: "gichugu",
    countyId: "60f06bb5a26f650268a1f80b",
  },
  {
    id: "60f0b976ca855b1b38aa277e",
    name: "ndia",
    countyId: "60f06bb5a26f650268a1f80b",
  },
  {
    id: "60f0b983ca855b1b38aa2782",
    name: "kirinyaga central",
    countyId: "60f06bb5a26f650268a1f80b",
  },
  {
    id: "60f0b999ca855b1b38aa2786",
    name: "kangema",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9a3ca855b1b38aa278a",
    name: "mathioya",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9abca855b1b38aa278e",
    name: "kiharu",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9b4ca855b1b38aa2792",
    name: "kigumo",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9c5ca855b1b38aa2796",
    name: "maragwa",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9ceca855b1b38aa279a",
    name: "kandara",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9deca855b1b38aa279e",
    name: "gatanga",
    countyId: "60f06be5a26f650268a1f80e",
  },
  {
    id: "60f0b9fcca855b1b38aa27a2",
    name: "gatundu south",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba08ca855b1b38aa27a6",
    name: "gatundu north",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba13ca855b1b38aa27aa",
    name: "juja",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba1dca855b1b38aa27ae",
    name: "thika town",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba27ca855b1b38aa27b2",
    name: "ruiru",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba38ca855b1b38aa27b6",
    name: "githunguri",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba43ca855b1b38aa27ba",
    name: "kiambu",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba4dca855b1b38aa27be",
    name: "kiambaa",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba58ca855b1b38aa27c2",
    name: "kabete",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba64ca855b1b38aa27c6",
    name: "kikuyu",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba6fca855b1b38aa27ca",
    name: "limuru",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0ba7bca855b1b38aa27ce",
    name: "lari",
    countyId: "60f06bf2a26f650268a1f811",
  },
  {
    id: "60f0baadca855b1b38aa27d2",
    name: "turkana north",
    countyId: "60f06c38a26f650268a1f814",
  },
  {
    id: "60f0bab7ca855b1b38aa27d6",
    name: "turkana west",
    countyId: "60f06c38a26f650268a1f814",
  },
  {
    id: "60f0bac6ca855b1b38aa27da",
    name: "turkana central",
    countyId: "60f06c38a26f650268a1f814",
  },
  {
    id: "60f0bad0ca855b1b38aa27de",
    name: "loima",
    countyId: "60f06c38a26f650268a1f814",
  },
  {
    id: "60f0badaca855b1b38aa27e2",
    name: "turkana south",
    countyId: "60f06c38a26f650268a1f814",
  },
  {
    id: "60f0bae5ca855b1b38aa27e6",
    name: "turkana east",
    countyId: "60f06c38a26f650268a1f814",
  },
  {
    id: "60f0bc065103d52e8c76c583",
    name: "kapenguria",
    countyId: "60f06c4ca26f650268a1f81a",
  },
  {
    id: "60f0bc5e74a8da3390672931",
    name: "sigor",
    countyId: "60f06c4ca26f650268a1f81a",
  },
  {
    id: "60f0bc7274a8da3390672935",
    name: "kacheliba",
    countyId: "60f06c4ca26f650268a1f81a",
  },
  {
    id: "60f0bc7b74a8da3390672939",
    name: "pokot south",
    countyId: "60f06c4ca26f650268a1f81a",
  },
  {
    id: "60f0bc8f74a8da339067293d",
    name: "samburu west",
    countyId: "60f06c5ea26f650268a1f81d",
  },
  {
    id: "60f0bc9c74a8da3390672941",
    name: "samburu north",
    countyId: "60f06c5ea26f650268a1f81d",
  },
  {
    id: "60f0bca674a8da3390672945",
    name: "samburu east",
    countyId: "60f06c5ea26f650268a1f81d",
  },
  {
    id: "60f0bcba74a8da3390672949",
    name: "kwanza",
    countyId: "60f06c77a26f650268a1f820",
  },
  {
    id: "60f0bccd74a8da339067294d",
    name: "endebess",
    countyId: "60f06c77a26f650268a1f820",
  },
  {
    id: "60f0bcd674a8da3390672951",
    name: "saboti",
    countyId: "60f06c77a26f650268a1f820",
  },
  {
    id: "60f0bce374a8da3390672955",
    name: "kiminini",
    countyId: "60f06c77a26f650268a1f820",
  },
  {
    id: "60f0bcee74a8da3390672959",
    name: "cherangany",
    countyId: "60f06c77a26f650268a1f820",
  },
  {
    id: "60f0bcff74a8da339067295d",
    name: "soy",
    countyId: "60f06c8aa26f650268a1f823",
  },
  {
    id: "60f0bd1e74a8da3390672961",
    name: "turbo",
    countyId: "60f06c8aa26f650268a1f823",
  },
  {
    id: "60f0bd2774a8da3390672965",
    name: "moiben",
    countyId: "60f06c8aa26f650268a1f823",
  },
  {
    id: "60f0bd3074a8da3390672969",
    name: "ainabkoi",
    countyId: "60f06c8aa26f650268a1f823",
  },
  {
    id: "60f0bd3974a8da339067296d",
    name: "kapseret",
    countyId: "60f06c8aa26f650268a1f823",
  },
  {
    id: "60f0bd4474a8da3390672971",
    name: "kesses",
    countyId: "60f06c8aa26f650268a1f823",
  },
  {
    id: "60f0bd5974a8da3390672975",
    name: "marakwet east",
    countyId: "60f06ca3a26f650268a1f826",
  },
  {
    id: "60f0bd6574a8da3390672979",
    name: "marakwet west",
    countyId: "60f06ca3a26f650268a1f826",
  },
  {
    id: "60f0bd6e74a8da339067297d",
    name: "keiyo north",
    countyId: "60f06ca3a26f650268a1f826",
  },
  {
    id: "60f0bd7874a8da3390672981",
    name: "keiyo south",
    countyId: "60f06ca3a26f650268a1f826",
  },
  {
    id: "60f0bd9874a8da3390672985",
    name: "tinderet",
    countyId: "60f06cb4a26f650268a1f829",
  },
  {
    id: "60f0bda274a8da3390672989",
    name: "aldai",
    countyId: "60f06cb4a26f650268a1f829",
  },
  {
    id: "60f0bdb074a8da339067298d",
    name: "nandi hills",
    countyId: "60f06cb4a26f650268a1f829",
  },
  {
    id: "60f0bdb874a8da3390672991",
    name: "chesumei",
    countyId: "60f06cb4a26f650268a1f829",
  },
  {
    id: "60f0bdc974a8da3390672995",
    name: "emgwen",
    countyId: "60f06cb4a26f650268a1f829",
  },
  {
    id: "60f0bdd074a8da3390672999",
    name: "mosop",
    countyId: "60f06cb4a26f650268a1f829",
  },
  {
    id: "60f0be6c74a8da339067299d",
    name: "tiaty",
    countyId: "60f06cc4a26f650268a1f82c",
  },
  {
    id: "60f0be7374a8da33906729a1",
    name: "baringo north",
    countyId: "60f06cc4a26f650268a1f82c",
  },
  {
    id: "60f0be7a74a8da33906729a5",
    name: "baringo central",
    countyId: "60f06cc4a26f650268a1f82c",
  },
  {
    id: "60f0be8174a8da33906729a9",
    name: "baringo south",
    countyId: "60f06cc4a26f650268a1f82c",
  },
  {
    id: "60f0be8774a8da33906729ad",
    name: "mogotio",
    countyId: "60f06cc4a26f650268a1f82c",
  },
  {
    id: "60f0be8f74a8da33906729b1",
    name: "eldama ravine",
    countyId: "60f06cc4a26f650268a1f82c",
  },
  {
    id: "60f0bea074a8da33906729b5",
    name: "laikipia west",
    countyId: "60f06cdaa26f650268a1f82f",
  },
  {
    id: "60f0bea974a8da33906729b9",
    name: "laikipia east",
    countyId: "60f06cdaa26f650268a1f82f",
  },
  {
    id: "60f0beb074a8da33906729bd",
    name: "laikipia north",
    countyId: "60f06cdaa26f650268a1f82f",
  },
  {
    id: "60f0bec274a8da33906729c1",
    name: "molo",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0beca74a8da33906729c5",
    name: "njoro",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bed474a8da33906729c9",
    name: "naivasha",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0beda74a8da33906729cd",
    name: "gilgil",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bee174a8da33906729d1",
    name: "kuresoi south",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0beea74a8da33906729d5",
    name: "kuresoi north",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bef174a8da33906729d9",
    name: "subukia",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bef974a8da33906729dd",
    name: "rongai",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0befe74a8da33906729e1",
    name: "bahati",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bf0674a8da33906729e5",
    name: "nakuru town west",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bf1174a8da33906729e9",
    name: "nakuru town east",
    countyId: "60f06ce5a26f650268a1f832",
  },
  {
    id: "60f0bf2674a8da33906729ed",
    name: "kilgoris",
    countyId: "60f06cf0a26f650268a1f835",
  },
  {
    id: "60f0bf3274a8da33906729f1",
    name: "emurua dikirr",
    countyId: "60f06cf0a26f650268a1f835",
  },
  {
    id: "60f0bf3a74a8da33906729f5",
    name: "narok north",
    countyId: "60f06cf0a26f650268a1f835",
  },
  {
    id: "60f0bf4174a8da33906729f9",
    name: "narok east",
    countyId: "60f06cf0a26f650268a1f835",
  },
  {
    id: "60f0bf4874a8da33906729fd",
    name: "narok south",
    countyId: "60f06cf0a26f650268a1f835",
  },
  {
    id: "60f0bf4f74a8da3390672a01",
    name: "narok west",
    countyId: "60f06cf0a26f650268a1f835",
  },
  {
    id: "60f0bf6c74a8da3390672a05",
    name: "kajiado north",
    countyId: "60f06cfea26f650268a1f838",
  },
  {
    id: "60f0bf7374a8da3390672a09",
    name: "kajiado central",
    countyId: "60f06cfea26f650268a1f838",
  },
  {
    id: "60f0bf7874a8da3390672a0d",
    name: "kajiado east",
    countyId: "60f06cfea26f650268a1f838",
  },
  {
    id: "60f0bf7f74a8da3390672a11",
    name: "kajiado west",
    countyId: "60f06cfea26f650268a1f838",
  },
  {
    id: "60f0bf8574a8da3390672a15",
    name: "kajiado south",
    countyId: "60f06cfea26f650268a1f838",
  },
  {
    id: "60f0bf9d74a8da3390672a19",
    name: "kipkelion east",
    countyId: "60f06d13a26f650268a1f83b",
  },
  {
    id: "60f0bfa474a8da3390672a1d",
    name: "kipkelion west",
    countyId: "60f06d13a26f650268a1f83b",
  },
  {
    id: "60f0bfab74a8da3390672a21",
    name: "ainamoi",
    countyId: "60f06d13a26f650268a1f83b",
  },
  {
    id: "60f0bfb174a8da3390672a25",
    name: "bureti",
    countyId: "60f06d13a26f650268a1f83b",
  },
  {
    id: "60f0bfb974a8da3390672a29",
    name: "belgut",
    countyId: "60f06d13a26f650268a1f83b",
  },
  {
    id: "60f0bfc374a8da3390672a2d",
    name: "sigowet soin",
    countyId: "60f06d13a26f650268a1f83b",
  },
  {
    id: "60f0bfd574a8da3390672a31",
    name: "sotik",
    countyId: "60f06d1fa26f650268a1f83e",
  },
  {
    id: "60f0bfdc74a8da3390672a35",
    name: "chepalungu",
    countyId: "60f06d1fa26f650268a1f83e",
  },
  {
    id: "60f0bfe174a8da3390672a39",
    name: "bomet east",
    countyId: "60f06d1fa26f650268a1f83e",
  },
  {
    id: "60f0bfe774a8da3390672a3d",
    name: "bomet central",
    countyId: "60f06d1fa26f650268a1f83e",
  },
  {
    id: "60f0bfed74a8da3390672a41",
    name: "konoin",
    countyId: "60f06d1fa26f650268a1f83e",
  },
  {
    id: "60f0bffc74a8da3390672a45",
    name: "lugari",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c00b74a8da3390672a49",
    name: "likuyani",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c02074a8da3390672a4d",
    name: "malava",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c02574a8da3390672a51",
    name: "lurambi",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c02a74a8da3390672a55",
    name: "navakholo",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c03274a8da3390672a59",
    name: "mumias west",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c03874a8da3390672a5d",
    name: "mumias east",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c03f74a8da3390672a61",
    name: "matungu",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c04774a8da3390672a65",
    name: "butere",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c04d74a8da3390672a69",
    name: "khwisero",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c05474a8da3390672a6d",
    name: "shinyalu",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c05b74a8da3390672a71",
    name: "ikolomani",
    countyId: "60f06d8ca26f650268a1f845",
  },
  {
    id: "60f0c09174a8da3390672a75",
    name: "vihiga",
    countyId: "60f06d9ca26f650268a1f848",
  },
  {
    id: "60f0c09774a8da3390672a79",
    name: "sabatia",
    countyId: "60f06d9ca26f650268a1f848",
  },
  {
    id: "60f0c09d74a8da3390672a7d",
    name: "hamisi",
    countyId: "60f06d9ca26f650268a1f848",
  },
  {
    id: "60f0c0a274a8da3390672a81",
    name: "luanda",
    countyId: "60f06d9ca26f650268a1f848",
  },
  {
    id: "60f0c0aa74a8da3390672a85",
    name: "emuhaya",
    countyId: "60f06d9ca26f650268a1f848",
  },
  {
    id: "60f0c0c874a8da3390672a89",
    name: "mount elgon",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c0d074a8da3390672a8d",
    name: "sirisia",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c0d674a8da3390672a91",
    name: "kabuchai",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c0ea74a8da3390672a97",
    name: "bumula",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c10574a8da3390672a9b",
    name: "kanduyi",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c10c74a8da3390672a9f",
    name: "webuye east",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c11274a8da3390672aa3",
    name: "webuye west",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c11a74a8da3390672aa7",
    name: "kimilili",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c12074a8da3390672aab",
    name: "tongaren",
    countyId: "60f06da9a26f650268a1f84b",
  },
  {
    id: "60f0c13374a8da3390672aaf",
    name: "teso north",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c13b74a8da3390672ab3",
    name: "teso south",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c14274a8da3390672ab7",
    name: "nambale",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c14774a8da3390672abb",
    name: "matayos",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c14d74a8da3390672abf",
    name: "butula",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c15374a8da3390672ac3",
    name: "funyula",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c15974a8da3390672ac7",
    name: "budalangi",
    countyId: "60f06db6a26f650268a1f84e",
  },
  {
    id: "60f0c16874a8da3390672acb",
    name: "ugenya",
    countyId: "60f06dfaa26f650268a1f856",
  },
  {
    id: "60f0c17374a8da3390672acf",
    name: "ugunja",
    countyId: "60f06dfaa26f650268a1f856",
  },
  {
    id: "60f0c17974a8da3390672ad3",
    name: "alego usonga",
    countyId: "60f06dfaa26f650268a1f856",
  },
  {
    id: "60f0c18174a8da3390672ad7",
    name: "gem",
    countyId: "60f06dfaa26f650268a1f856",
  },
  {
    id: "60f0c18774a8da3390672adb",
    name: "bondo",
    countyId: "60f06dfaa26f650268a1f856",
  },
  {
    id: "60f0c18f74a8da3390672adf",
    name: "rarieda",
    countyId: "60f06dfaa26f650268a1f856",
  },
  {
    id: "60f0c1a674a8da3390672ae3",
    name: "kisumu east",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c1ad74a8da3390672ae7",
    name: "kisumu west",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c1e774a8da3390672aeb",
    name: "kisumu central",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c1ed74a8da3390672aef",
    name: "seme",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c1f474a8da3390672af3",
    name: "nyando",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c1f974a8da3390672af7",
    name: "muhoroni",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c20174a8da3390672afb",
    name: "nyakach",
    countyId: "60f06e0aa26f650268a1f859",
  },
  {
    id: "60f0c22474a8da3390672aff",
    name: "kasipul",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c22e74a8da3390672b03",
    name: "kabondo kasipul",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c23474a8da3390672b07",
    name: "karachuonyo",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c24474a8da3390672b0b",
    name: "rangwe",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c24c74a8da3390672b0f",
    name: "homa bay town",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c25474a8da3390672b13",
    name: "ndhiwa",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c25a74a8da3390672b17",
    name: "mbita",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c26474a8da3390672b1b",
    name: "suba",
    countyId: "60f06e17a26f650268a1f85c",
  },
  {
    id: "60f0c28674a8da3390672b1f",
    name: "rongo",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c28d74a8da3390672b23",
    name: "awendo",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c29374a8da3390672b27",
    name: "suna east",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c29974a8da3390672b2b",
    name: "suna west",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c2a374a8da3390672b2f",
    name: "uriri",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c2a874a8da3390672b33",
    name: "nyatike",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c2ae74a8da3390672b37",
    name: "kuria west",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c2b574a8da3390672b3b",
    name: "kuria east",
    countyId: "60f06e25a26f650268a1f85f",
  },
  {
    id: "60f0c2c674a8da3390672b3f",
    name: "bonchari",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c2cc74a8da3390672b43",
    name: "south mugirango",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c2d474a8da3390672b47",
    name: "bomachoge borabu",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c2da74a8da3390672b4b",
    name: "bobasi",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c2e174a8da3390672b4f",
    name: "bomachoge chache",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c2ec74a8da3390672b53",
    name: "nyaribari masaba",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c2f474a8da3390672b57",
    name: "nyaribari chache",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c30274a8da3390672b5b",
    name: "kitutu chache north",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c30a74a8da3390672b5f",
    name: "kitutu chache south",
    countyId: "60f06e32a26f650268a1f862",
  },
  {
    id: "60f0c31c74a8da3390672b63",
    name: "kitutu masaba",
    countyId: "60f06e3fa26f650268a1f865",
  },
  {
    id: "60f0c32374a8da3390672b67",
    name: "west mugirango",
    countyId: "60f06e3fa26f650268a1f865",
  },
  {
    id: "60f0c32c74a8da3390672b6b",
    name: "north mugirango",
    countyId: "60f06e3fa26f650268a1f865",
  },
  {
    id: "60f0c33274a8da3390672b6f",
    name: "borabu",
    countyId: "60f06e3fa26f650268a1f865",
  },
  {
    id: "60f0c35474a8da3390672b73",
    name: "westlands",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c35a74a8da3390672b77",
    name: "dagoretti north",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c36174a8da3390672b7b",
    name: "dagoretti south",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c36874a8da3390672b7f",
    name: "lang'ata",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c36f74a8da3390672b83",
    name: "kibra",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c37574a8da3390672b87",
    name: "roysambu",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c37c74a8da3390672b8b",
    name: "kasarani",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c38374a8da3390672b8f",
    name: "ruaraka",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c38974a8da3390672b93",
    name: "embakasi south",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c39d74a8da3390672b97",
    name: "embakasi north",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3a374a8da3390672b9b",
    name: "embakasi central",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3a974a8da3390672b9f",
    name: "embakasi east",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3af74a8da3390672ba3",
    name: "embakasi west",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3b574a8da3390672ba7",
    name: "makadara",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3bc74a8da3390672bab",
    name: "kamukunji",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3c174a8da3390672baf",
    name: "starehe",
    countyId: "60f06e7ba26f650268a1f86d",
  },
  {
    id: "60f0c3c974a8da3390672bb3",
    name: "mathare",
    countyId: "60f06e7ba26f650268a1f86d",
  },
];

const wards = [
  {
    id: "60f1bd8755894a1b4cfd021b",
    name: "kahenya ini",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b999ca855b1b38aa2786",
  },
  {
    id: "60f1bd9555894a1b4cfd0222",
    name: "muguru",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b999ca855b1b38aa2786",
  },
  {
    id: "60f1bd9d55894a1b4cfd0227",
    name: "rwathia",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b999ca855b1b38aa2786",
  },
  {
    id: "60f1bdae55894a1b4cfd022c",
    name: "gitugi",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9a3ca855b1b38aa278a",
  },
  {
    id: "60f1bdb755894a1b4cfd0231",
    name: "kiru",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9a3ca855b1b38aa278a",
  },
  {
    id: "60f1bdbe55894a1b4cfd0236",
    name: "kamacharia",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9a3ca855b1b38aa278a",
  },
  {
    id: "60f1bdcf55894a1b4cfd023b",
    name: "wangu",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9abca855b1b38aa278e",
  },
  {
    id: "60f1bdd755894a1b4cfd0240",
    name: "mugoiri",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9abca855b1b38aa278e",
  },
  {
    id: "60f1bde055894a1b4cfd0245",
    name: "mbiri",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9abca855b1b38aa278e",
  },
  {
    id: "60f1bdeb55894a1b4cfd024a",
    name: "township",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9abca855b1b38aa278e",
  },
  {
    id: "60f1bdf255894a1b4cfd024f",
    name: "murarandia",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9abca855b1b38aa278e",
  },
  {
    id: "60f1bdfa55894a1b4cfd0254",
    name: "gaturi",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9abca855b1b38aa278e",
  },
  {
    id: "60f1c15a55894a1b4cfd0263",
    name: "kahumbu",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9b4ca855b1b38aa2792",
  },
  {
    id: "60f1c16555894a1b4cfd0268",
    name: "muthithi",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9b4ca855b1b38aa2792",
  },
  {
    id: "60f1c16d55894a1b4cfd026d",
    name: "kigumo",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9b4ca855b1b38aa2792",
  },
  {
    id: "60f1c18555894a1b4cfd0272",
    name: "kangari",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9b4ca855b1b38aa2792",
  },
  {
    id: "60f1c18d55894a1b4cfd0277",
    name: "kinyona",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9b4ca855b1b38aa2792",
  },
  {
    id: "60f1c1b655894a1b4cfd027c",
    name: "kimorori/ wempa",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9c5ca855b1b38aa2796",
  },
  {
    id: "60f1c1c055894a1b4cfd0281",
    name: "makuyu",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9c5ca855b1b38aa2796",
  },
  {
    id: "60f1c1c555894a1b4cfd0286",
    name: "kambiti",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9c5ca855b1b38aa2796",
  },
  {
    id: "60f1c1cc55894a1b4cfd028b",
    name: "kamahuhu",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9c5ca855b1b38aa2796",
  },
  {
    id: "60f1c1d255894a1b4cfd0290",
    name: "ichagaki",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9c5ca855b1b38aa2796",
  },
  {
    id: "60f1c1d955894a1b4cfd0295",
    name: "nginda",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9c5ca855b1b38aa2796",
  },
  {
    id: "60f1c1e555894a1b4cfd029a",
    name: "ng'araria",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9ceca855b1b38aa279a",
  },
  {
    id: "60f1c1ee55894a1b4cfd029f",
    name: "muruka",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9ceca855b1b38aa279a",
  },
  {
    id: "60f1c1f855894a1b4cfd02a4",
    name: "kagundu ini",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9ceca855b1b38aa279a",
  },
  {
    id: "60f1c20155894a1b4cfd02a9",
    name: "gaichanjiru",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9ceca855b1b38aa279a",
  },
  {
    id: "60f1c20955894a1b4cfd02ae",
    name: "ithiru",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9ceca855b1b38aa279a",
  },
  {
    id: "60f1c20f55894a1b4cfd02b3",
    name: "ruchu",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9ceca855b1b38aa279a",
  },
  {
    id: "60f1c31a55894a1b4cfd02be",
    name: "ithanga",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9deca855b1b38aa279e",
  },
  {
    id: "60f1c32455894a1b4cfd02c3",
    name: "kakuzi/ mitubiri",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9deca855b1b38aa279e",
  },
  {
    id: "60f1c33255894a1b4cfd02c8",
    name: "mugumo ini",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9deca855b1b38aa279e",
  },
  {
    id: "60f1c33d55894a1b4cfd02cd",
    name: "kihumbu ini",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9deca855b1b38aa279e",
  },
  {
    id: "60f1c34555894a1b4cfd02d2",
    name: "gatanga",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9deca855b1b38aa279e",
  },
  {
    id: "60f1c34b55894a1b4cfd02d7",
    name: "kariara",
    countyId: "60f06be5a26f650268a1f80e",
    constituencyId: "60f0b9deca855b1b38aa279e",
  },
  {
    id: "60f1d46c4c62ee3018d4d4dc",
    name: "kamenu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba1dca855b1b38aa27ae",
  },
  {
    id: "60f1d4744c62ee3018d4d4e1",
    name: "hospital",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba1dca855b1b38aa27ae",
  },
  {
    id: "60f1d47b4c62ee3018d4d4e6",
    name: "gatuanyaga",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba1dca855b1b38aa27ae",
  },
  {
    id: "60f1d4be4c62ee3018d4d4f2",
    name: "thika township",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba1dca855b1b38aa27ae",
  },
  {
    id: "60f1d8676ee9a02804f72784",
    name: "gituamba",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba08ca855b1b38aa27a6",
  },
  {
    id: "60f1d8706ee9a02804f72789",
    name: "githobokoni",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba08ca855b1b38aa27a6",
  },
  {
    id: "60f1d8786ee9a02804f7278e",
    name: "chania",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba08ca855b1b38aa27a6",
  },
  {
    id: "60f1d8866ee9a02804f72793",
    name: "mang'u",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba08ca855b1b38aa27a6",
  },
  {
    id: "60f1d8c66ee9a02804f7279d",
    name: "kiamwangi",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0b9fcca855b1b38aa27a2",
  },
  {
    id: "60f1d8cf6ee9a02804f727a2",
    name: "kiganjo",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0b9fcca855b1b38aa27a2",
  },
  {
    id: "60f1d8d96ee9a02804f727a7",
    name: "ndarugo",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0b9fcca855b1b38aa27a2",
  },
  {
    id: "60f1d8e06ee9a02804f727ac",
    name: "ngenda",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0b9fcca855b1b38aa27a2",
  },
  {
    id: "60f1d8f26ee9a02804f727b1",
    name: "murera",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba13ca855b1b38aa27aa",
  },
  {
    id: "60f1d9036ee9a02804f727b6",
    name: "theta",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba13ca855b1b38aa27aa",
  },
  {
    id: "60f1d9476ee9a02804f727bb",
    name: "juja",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba13ca855b1b38aa27aa",
  },
  {
    id: "60f1d94f6ee9a02804f727c0",
    name: "witeithie",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba13ca855b1b38aa27aa",
  },
  {
    id: "60f1d9556ee9a02804f727c5",
    name: "kalimoni",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba13ca855b1b38aa27aa",
  },
  {
    id: "60f1d9806ee9a02804f727cb",
    name: "kinale",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba7bca855b1b38aa27ce",
  },
  {
    id: "60f1d98b6ee9a02804f727d0",
    name: "kijabe",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba7bca855b1b38aa27ce",
  },
  {
    id: "60f1d9936ee9a02804f727d5",
    name: "nyanduma",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba7bca855b1b38aa27ce",
  },
  {
    id: "60f1d9996ee9a02804f727da",
    name: "kamburu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba7bca855b1b38aa27ce",
  },
  {
    id: "60f1d9a26ee9a02804f727df",
    name: "lari/ kirenga",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba7bca855b1b38aa27ce",
  },
  {
    id: "60f1d9be6ee9a02804f727e4",
    name: "karai",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba64ca855b1b38aa27c6",
  },
  {
    id: "60f1d9c36ee9a02804f727e9",
    name: "nachu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba64ca855b1b38aa27c6",
  },
  {
    id: "60f1d9c96ee9a02804f727ee",
    name: "sigona",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba64ca855b1b38aa27c6",
  },
  {
    id: "60f1d9ce6ee9a02804f727f3",
    name: "kikuyu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba64ca855b1b38aa27c6",
  },
  {
    id: "60f1d9d46ee9a02804f727f8",
    name: "kinoo",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba64ca855b1b38aa27c6",
  },
  {
    id: "60f1d9ec6ee9a02804f727fd",
    name: "githunguri",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba38ca855b1b38aa27b6",
  },
  {
    id: "60f1d9f46ee9a02804f72802",
    name: "githiga",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba38ca855b1b38aa27b6",
  },
  {
    id: "60f1d9fb6ee9a02804f72807",
    name: "ikinu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba38ca855b1b38aa27b6",
  },
  {
    id: "60f1da036ee9a02804f7280c",
    name: "ngewa",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba38ca855b1b38aa27b6",
  },
  {
    id: "60f1da5f6ee9a02804f72811",
    name: "komothai",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba38ca855b1b38aa27b6",
  },
  {
    id: "60f1da7a6ee9a02804f72816",
    name: "gitaru",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba58ca855b1b38aa27c2",
  },
  {
    id: "60f1da866ee9a02804f7281b",
    name: "muguga",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba58ca855b1b38aa27c2",
  },
  {
    id: "60f1da8d6ee9a02804f72820",
    name: "nyadhuna",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba58ca855b1b38aa27c2",
  },
  {
    id: "60f1da926ee9a02804f72825",
    name: "kabete",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba58ca855b1b38aa27c2",
  },
  {
    id: "60f1da986ee9a02804f7282a",
    name: "uthiru",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba58ca855b1b38aa27c2",
  },
  {
    id: "60f1dac06ee9a02804f7282f",
    name: "gitothua",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1dac86ee9a02804f72834",
    name: "biashara",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1dad06ee9a02804f72839",
    name: "gatongora",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1dada6ee9a02804f7283e",
    name: "kahawa sukari",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1dae26ee9a02804f72843",
    name: "kahawa wendani",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1daeb6ee9a02804f72848",
    name: "kiuu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1daf16ee9a02804f7284d",
    name: "mwiki",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1daf86ee9a02804f72852",
    name: "mwihoko",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba27ca855b1b38aa27b2",
  },
  {
    id: "60f1db0b6ee9a02804f72857",
    name: "bibirioni",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba6fca855b1b38aa27ca",
  },
  {
    id: "60f1db146ee9a02804f7285c",
    name: "limuru central",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba6fca855b1b38aa27ca",
  },
  {
    id: "60f1db1b6ee9a02804f72861",
    name: "ndeiya",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba6fca855b1b38aa27ca",
  },
  {
    id: "60f1db236ee9a02804f72866",
    name: "limuru east",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba6fca855b1b38aa27ca",
  },
  {
    id: "60f1db2c6ee9a02804f7286b",
    name: "ngecha tigoni",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba6fca855b1b38aa27ca",
  },
  {
    id: "60f1db426ee9a02804f72870",
    name: "cianda",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba4dca855b1b38aa27be",
  },
  {
    id: "60f1db4a6ee9a02804f72875",
    name: "karuri",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba4dca855b1b38aa27be",
  },
  {
    id: "60f1db516ee9a02804f7287a",
    name: "ndenderu",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba4dca855b1b38aa27be",
  },
  {
    id: "60f1db586ee9a02804f7287f",
    name: "muchatha",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba4dca855b1b38aa27be",
  },
  {
    id: "60f1db5f6ee9a02804f72884",
    name: "kihara",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba4dca855b1b38aa27be",
  },
  {
    id: "60f1db816ee9a02804f72889",
    name: "ting'ang'a",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba43ca855b1b38aa27ba",
  },
  {
    id: "60f1db8b6ee9a02804f7288e",
    name: "ndumberi",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba43ca855b1b38aa27ba",
  },
  {
    id: "60f1db946ee9a02804f72893",
    name: "riabai",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba43ca855b1b38aa27ba",
  },
  {
    id: "60f1db9f6ee9a02804f72898",
    name: "kiambu township",
    countyId: "60f06bf2a26f650268a1f811",
    constituencyId: "60f0ba43ca855b1b38aa27ba",
  },
  {
    id: "60f1de786ee9a02804f728a2",
    name: "mutithi",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1de8b6ee9a02804f728a7",
    name: "kangai",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1de916ee9a02804f728ac",
    name: "wamumu",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1de986ee9a02804f728b1",
    name: "nyangati",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1dea46ee9a02804f728b6",
    name: "murindiko",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1deb06ee9a02804f728bb",
    name: "gathigiriri",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1debb6ee9a02804f728c0",
    name: "teberer",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1dec56ee9a02804f728c5",
    name: "thiba",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b953ca855b1b38aa2776",
  },
  {
    id: "60f1df4a6ee9a02804f728cc",
    name: "kabare baragwi",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b96dca855b1b38aa277a",
  },
  {
    id: "60f1df5a6ee9a02804f728d2",
    name: "njukiini",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b96dca855b1b38aa277a",
  },
  {
    id: "60f1df616ee9a02804f728d7",
    name: "ngariama",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b96dca855b1b38aa277a",
  },
  {
    id: "60f1df696ee9a02804f728dc",
    name: "karumandi",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b96dca855b1b38aa277a",
  },
  {
    id: "60f1dfa26ee9a02804f728e2",
    name: "mukure",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b976ca855b1b38aa277e",
  },
  {
    id: "60f1dfa86ee9a02804f728e7",
    name: "kiine",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b976ca855b1b38aa277e",
  },
  {
    id: "60f1dfaf6ee9a02804f728ec",
    name: "kariti",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b976ca855b1b38aa277e",
  },
  {
    id: "60f1dfdc6ee9a02804f728f1",
    name: "mutira",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b983ca855b1b38aa2782",
  },
  {
    id: "60f1dfe26ee9a02804f728f6",
    name: "kanyekini",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b983ca855b1b38aa2782",
  },
  {
    id: "60f1dfe96ee9a02804f728fb",
    name: "kerugoya",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b983ca855b1b38aa2782",
  },
  {
    id: "60f1dff06ee9a02804f72900",
    name: "inoi",
    countyId: "60f06bb5a26f650268a1f80b",
    constituencyId: "60f0b983ca855b1b38aa2782",
  },
  {
    id: "60f1e2116ee9a02804f7290a",
    name: "dedan kimathi",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b867ca855b1b38aa275e",
  },
  {
    id: "60f1e21a6ee9a02804f7290f",
    name: "wamagana",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b867ca855b1b38aa275e",
  },
  {
    id: "60f1e2246ee9a02804f72914",
    name: "aguthi gaaki",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b867ca855b1b38aa275e",
  },
  {
    id: "60f1e2516ee9a02804f7291a",
    name: "mweiga",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2576ee9a02804f7291f",
    name: "naromoro kiamthaga",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2616ee9a02804f72924",
    name: "mwiyogo/ endara sha",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2706ee9a02804f72929",
    name: "mugunda",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2766ee9a02804f7292e",
    name: "gatarakwa",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e27d6ee9a02804f72933",
    name: "thegu river",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2826ee9a02804f72938",
    name: "kabaru",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2886ee9a02804f7293d",
    name: "gakawa",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b872ca855b1b38aa2762",
  },
  {
    id: "60f1e2966ee9a02804f72942",
    name: "ruguru",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b892ca855b1b38aa2766",
  },
  {
    id: "60f1e29b6ee9a02804f72947",
    name: "magutu",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b892ca855b1b38aa2766",
  },
  {
    id: "60f1e2a26ee9a02804f7294c",
    name: "iriani",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b892ca855b1b38aa2766",
  },
  {
    id: "60f1e2a86ee9a02804f72951",
    name: "konyu",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b892ca855b1b38aa2766",
  },
  {
    id: "60f1e2ae6ee9a02804f72956",
    name: "kirimukuyu",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b892ca855b1b38aa2766",
  },
  {
    id: "60f1e2b56ee9a02804f7295b",
    name: "karatina town",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b892ca855b1b38aa2766",
  },
  {
    id: "60f1e2d56ee9a02804f72962",
    name: "mahiga",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b89aca855b1b38aa276a",
  },
  {
    id: "60f1e2e06ee9a02804f72967",
    name: "iria ini",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b89aca855b1b38aa276a",
  },
  {
    id: "60f1e2e96ee9a02804f7296c",
    name: "chinga",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b89aca855b1b38aa276a",
  },
  {
    id: "60f1e2ef6ee9a02804f72971",
    name: "karima",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b89aca855b1b38aa276a",
  },
  {
    id: "60f1e3026ee9a02804f72976",
    name: "gikondi",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8a9ca855b1b38aa276e",
  },
  {
    id: "60f1e3146ee9a02804f7297b",
    name: "rugi",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8a9ca855b1b38aa276e",
  },
  {
    id: "60f1e31f6ee9a02804f72980",
    name: "mukurwe ini west",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8a9ca855b1b38aa276e",
  },
  {
    id: "60f1e33c6ee9a02804f72985",
    name: "mukurwe ini central",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8a9ca855b1b38aa276e",
  },
  {
    id: "60f1e39a6ee9a02804f72991",
    name: "kiganjo/ mathari",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8b6ca855b1b38aa2772",
  },
  {
    id: "60f1e3a46ee9a02804f72996",
    name: "rware",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8b6ca855b1b38aa2772",
  },
  {
    id: "60f1e3ad6ee9a02804f7299b",
    name: "gatitu/ muruguru",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8b6ca855b1b38aa2772",
  },
  {
    id: "60f1e3bb6ee9a02804f729a0",
    name: "ruring'u",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8b6ca855b1b38aa2772",
  },
  {
    id: "60f1e3d66ee9a02804f729a5",
    name: "kamakwa/ mukaro",
    countyId: "60f06b93a26f650268a1f806",
    constituencyId: "60f0b8b6ca855b1b38aa2772",
  },
  {
    id: "60f1e4236ee9a02804f729ab",
    name: "engineer",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e42e6ee9a02804f729b0",
    name: "gathara",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e4356ee9a02804f729b5",
    name: "north kinangop",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e43c6ee9a02804f729ba",
    name: "murungaru",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e4466ee9a02804f729bf",
    name: "njabini/ kiburu",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e44e6ee9a02804f729c4",
    name: "nyakio",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e4546ee9a02804f729c9",
    name: "githabai",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e45a6ee9a02804f729ce",
    name: "magumu",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b7e6ca855b1b38aa274a",
  },
  {
    id: "60f1e4786ee9a02804f729d3",
    name: "wanjohi",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b832ca855b1b38aa274e",
  },
  {
    id: "60f1e47e6ee9a02804f729d8",
    name: "kipipiri",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b832ca855b1b38aa274e",
  },
  {
    id: "60f1e4836ee9a02804f729dd",
    name: "geta",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b832ca855b1b38aa274e",
  },
  {
    id: "60f1e48c6ee9a02804f729e2",
    name: "githioro",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b832ca855b1b38aa274e",
  },
  {
    id: "60f1e4ab6ee9a02804f729e7",
    name: "karau",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b83bca855b1b38aa2752",
  },
  {
    id: "60f1e4b46ee9a02804f729ec",
    name: "kanjuiri range",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b83bca855b1b38aa2752",
  },
  {
    id: "60f1e4ba6ee9a02804f729f1",
    name: "mirangine",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b83bca855b1b38aa2752",
  },
  {
    id: "60f1e4c56ee9a02804f729f6",
    name: "rurii",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b83bca855b1b38aa2752",
  },
  {
    id: "60f1e4cc6ee9a02804f729fb",
    name: "gathanji",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b845ca855b1b38aa2756",
  },
  {
    id: "60f1e4d16ee9a02804f72a00",
    name: "gatima",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b845ca855b1b38aa2756",
  },
  {
    id: "60f1e4f36ee9a02804f72a07",
    name: "weru",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b845ca855b1b38aa2756",
  },
  {
    id: "60f1e4fa6ee9a02804f72a0c",
    name: "charagita",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b845ca855b1b38aa2756",
  },
  {
    id: "60f1e5926ee9a02804f72a11",
    name: "leshau/ pondo",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b852ca855b1b38aa275a",
  },
  {
    id: "60f1e59b6ee9a02804f72a16",
    name: "kiriita",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b852ca855b1b38aa275a",
  },
  {
    id: "60f1e5a36ee9a02804f72a1b",
    name: "central",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b852ca855b1b38aa275a",
  },
  {
    id: "60f1e5aa6ee9a02804f72a20",
    name: "shamata",
    countyId: "60f06b84a26f650268a1f803",
    constituencyId: "60f0b852ca855b1b38aa275a",
  },
  {
    id: "60f4510e2586573630e2ac31",
    name: "port reitz",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0af5a9d08c91e109695d2",
  },
  {
    id: "60f4511c2586573630e2ac37",
    name: "kipevu",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0af5a9d08c91e109695d2",
  },
  {
    id: "60f451222586573630e2ac3c",
    name: "airport",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0af5a9d08c91e109695d2",
  },
  {
    id: "60f451292586573630e2ac41",
    name: "miritini",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0af5a9d08c91e109695d2",
  },
  {
    id: "60f4512f2586573630e2ac46",
    name: "chaani",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0af5a9d08c91e109695d2",
  },
  {
    id: "60f4513d2586573630e2ac4b",
    name: "jomvu kuu",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b13a53b7bd2f2ca8ce00",
  },
  {
    id: "60f451472586573630e2ac50",
    name: "magongo",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b13a53b7bd2f2ca8ce00",
  },
  {
    id: "60f4514d2586573630e2ac55",
    name: "mikindani",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b13a53b7bd2f2ca8ce00",
  },
  {
    id: "60f4515f2586573630e2ac5a",
    name: "mjambere",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f451632586573630e2ac5f",
    name: "junda",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f451692586573630e2ac64",
    name: "bamburi",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f4516e2586573630e2ac69",
    name: "mwakirunge",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f451762586573630e2ac6e",
    name: "mtopanga",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f451812586573630e2ac73",
    name: "magogoni",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f4518a2586573630e2ac78",
    name: "shanzu",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b14e53b7bd2f2ca8ce04",
  },
  {
    id: "60f451982586573630e2ac7d",
    name: "frere town",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b15853b7bd2f2ca8ce08",
  },
  {
    id: "60f451a42586573630e2ac82",
    name: "ziwa la ng'ombe",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b15853b7bd2f2ca8ce08",
  },
  {
    id: "60f451ab2586573630e2ac87",
    name: "mkomani",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b15853b7bd2f2ca8ce08",
  },
  {
    id: "60f451b12586573630e2ac8c",
    name: "kongowea",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b15853b7bd2f2ca8ce08",
  },
  {
    id: "60f451b72586573630e2ac91",
    name: "kadzandani",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b15853b7bd2f2ca8ce08",
  },
  {
    id: "60f451ce2586573630e2ac96",
    name: "mtongwe",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b16253b7bd2f2ca8ce0c",
  },
  {
    id: "60f451d52586573630e2ac9b",
    name: "shika adabu",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b16253b7bd2f2ca8ce0c",
  },
  {
    id: "60f451dc2586573630e2aca0",
    name: "bofu",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b16253b7bd2f2ca8ce0c",
  },
  {
    id: "60f451e22586573630e2aca5",
    name: "likoni",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b16253b7bd2f2ca8ce0c",
  },
  {
    id: "60f451e72586573630e2acaa",
    name: "timbwani",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b16253b7bd2f2ca8ce0c",
  },
  {
    id: "60f4520a2586573630e2acaf",
    name: "mji wa kale/ makadara",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b1ceca855b1b38aa25f3",
  },
  {
    id: "60f452152586573630e2acb4",
    name: "tudor",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b1ceca855b1b38aa25f3",
  },
  {
    id: "60f4521c2586573630e2acb9",
    name: "tononoka",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b1ceca855b1b38aa25f3",
  },
  {
    id: "60f452522586573630e2acbe",
    name: "shimanzi/ ganjoni",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b1ceca855b1b38aa25f3",
  },
  {
    id: "60f4525b2586573630e2acc3",
    name: "majengo",
    countyId: "60f05826a26f650268a1f7c2",
    constituencyId: "60f0b1ceca855b1b38aa25f3",
  },
  {
    id: "60f452da2586573630e2accb",
    name: "gombato bongwe",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b210ca855b1b38aa25f9",
  },
  {
    id: "60f452e32586573630e2acd0",
    name: "ukunda",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b210ca855b1b38aa25f9",
  },
  {
    id: "60f452ea2586573630e2acd5",
    name: "kinondo",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b210ca855b1b38aa25f9",
  },
  {
    id: "60f452ef2586573630e2acda",
    name: "ramisi",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b210ca855b1b38aa25f9",
  },
  {
    id: "60f453532586573630e2acdf",
    name: "pongwe kikoneni",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b21aca855b1b38aa25fd",
  },
  {
    id: "60f4535c2586573630e2ace4",
    name: "dzombo",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b21aca855b1b38aa25fd",
  },
  {
    id: "60f453632586573630e2ace9",
    name: "vanga",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b21aca855b1b38aa25fd",
  },
  {
    id: "60f453682586573630e2acee",
    name: "mwereni",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b21aca855b1b38aa25fd",
  },
  {
    id: "60f4537d2586573630e2acf3",
    name: "tsimba golini",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b227ca855b1b38aa2601",
  },
  {
    id: "60f453842586573630e2acf8",
    name: "waa",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b227ca855b1b38aa2601",
  },
  {
    id: "60f4538b2586573630e2acfd",
    name: "tiwi",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b227ca855b1b38aa2601",
  },
  {
    id: "60f453922586573630e2ad02",
    name: "kubo south",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b227ca855b1b38aa2601",
  },
  {
    id: "60f453a62586573630e2ad07",
    name: "mkongani",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b227ca855b1b38aa2601",
  },
  {
    id: "60f453e12586573630e2ad0c",
    name: "ndavaya",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f453e92586573630e2ad11",
    name: "puma",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f453f02586573630e2ad16",
    name: "kinango",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f453f82586573630e2ad1b",
    name: "chengoni/ samburu",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f454032586573630e2ad20",
    name: "mackinon road",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f4540b2586573630e2ad25",
    name: "mwavumbo",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f454112586573630e2ad2a",
    name: "kasemeni",
    countyId: "60f058dea26f650268a1f7c5",
    constituencyId: "60f0b238ca855b1b38aa2605",
  },
  {
    id: "60f454a52586573630e2ad30",
    name: "tezo",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454ac2586573630e2ad35",
    name: "sokoni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454b22586573630e2ad3a",
    name: "kibarani",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454b72586573630e2ad3f",
    name: "dabaso",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454bd2586573630e2ad44",
    name: "matsangoni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454c42586573630e2ad49",
    name: "watamu",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454cc2586573630e2ad4e",
    name: "mnarani",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b258ca855b1b38aa2609",
  },
  {
    id: "60f454e32586573630e2ad53",
    name: "junju",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b273ca855b1b38aa260d",
  },
  {
    id: "60f454eb2586573630e2ad58",
    name: "mwarakaya",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b273ca855b1b38aa260d",
  },
  {
    id: "60f454f22586573630e2ad5d",
    name: "shimo la tewa",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b273ca855b1b38aa260d",
  },
  {
    id: "60f454fc2586573630e2ad62",
    name: "chasimba",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b273ca855b1b38aa260d",
  },
  {
    id: "60f455032586573630e2ad67",
    name: "mtepeni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b273ca855b1b38aa260d",
  },
  {
    id: "60f4551b2586573630e2ad6c",
    name: "mariakani",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b284ca855b1b38aa2613",
  },
  {
    id: "60f455232586573630e2ad71",
    name: "kayafungo",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b284ca855b1b38aa2613",
  },
  {
    id: "60f4552a2586573630e2ad76",
    name: "kaloleni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b284ca855b1b38aa2613",
  },
  {
    id: "60f455322586573630e2ad7b",
    name: "mwanamwinga",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b284ca855b1b38aa2613",
  },
  {
    id: "60f455432586573630e2ad80",
    name: "mwawesa",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b290ca855b1b38aa2617",
  },
  {
    id: "60f4554a2586573630e2ad85",
    name: "ruruma",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b290ca855b1b38aa2617",
  },
  {
    id: "60f455512586573630e2ad8a",
    name: "jibana",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b290ca855b1b38aa2617",
  },
  {
    id: "60f4555c2586573630e2ad8f",
    name: "rabai/ kisurutuni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b290ca855b1b38aa2617",
  },
  {
    id: "60f455752586573630e2ad94",
    name: "dungicha",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2a1ca855b1b38aa261b",
  },
  {
    id: "60f4557d2586573630e2ad99",
    name: "bamba",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2a1ca855b1b38aa261b",
  },
  {
    id: "60f455832586573630e2ad9e",
    name: "jaribuni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2a1ca855b1b38aa261b",
  },
  {
    id: "60f4558a2586573630e2ada3",
    name: "sokoke",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2a1ca855b1b38aa261b",
  },
  {
    id: "60f4559d2586573630e2ada8",
    name: "jilore",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2adca855b1b38aa261f",
  },
  {
    id: "60f455a52586573630e2adad",
    name: "kakuyuni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2adca855b1b38aa261f",
  },
  {
    id: "60f455ad2586573630e2adb2",
    name: "ganda",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2adca855b1b38aa261f",
  },
  {
    id: "60f455b52586573630e2adb7",
    name: "malindi town",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2adca855b1b38aa261f",
  },
  {
    id: "60f455bf2586573630e2adbc",
    name: "shella",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2adca855b1b38aa261f",
  },
  {
    id: "60f455d62586573630e2adc1",
    name: "maarafa",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2b9ca855b1b38aa2623",
  },
  {
    id: "60f455da2586573630e2adc6",
    name: "magarini",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2b9ca855b1b38aa2623",
  },
  {
    id: "60f455e22586573630e2adcb",
    name: "gongoni",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2b9ca855b1b38aa2623",
  },
  {
    id: "60f455ea2586573630e2add0",
    name: "adu",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2b9ca855b1b38aa2623",
  },
  {
    id: "60f455f52586573630e2add5",
    name: "garashi",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2b9ca855b1b38aa2623",
  },
  {
    id: "60f455fb2586573630e2adda",
    name: "sabaki",
    countyId: "60f05901a26f650268a1f7c8",
    constituencyId: "60f0b2b9ca855b1b38aa2623",
  },
  {
    id: "60f4566a2586573630e2ade0",
    name: "kipini east",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2dcca855b1b38aa2627",
  },
  {
    id: "60f456732586573630e2ade5",
    name: "garsen south",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2dcca855b1b38aa2627",
  },
  {
    id: "60f456792586573630e2adea",
    name: "kipini west",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2dcca855b1b38aa2627",
  },
  {
    id: "60f4567f2586573630e2adef",
    name: "garsen central",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2dcca855b1b38aa2627",
  },
  {
    id: "60f456852586573630e2adf4",
    name: "garsen west",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2dcca855b1b38aa2627",
  },
  {
    id: "60f456902586573630e2adf9",
    name: "garsen north",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2dcca855b1b38aa2627",
  },
  {
    id: "60f456a32586573630e2adfe",
    name: "kinakomba",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2e9ca855b1b38aa262b",
  },
  {
    id: "60f456af2586573630e2ae03",
    name: "mikinduni",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2e9ca855b1b38aa262b",
  },
  {
    id: "60f456b72586573630e2ae08",
    name: "chewani",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2e9ca855b1b38aa262b",
  },
  {
    id: "60f456bd2586573630e2ae0d",
    name: "wayu",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2e9ca855b1b38aa262b",
  },
  {
    id: "60f456de2586573630e2ae13",
    name: "chewele",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2f3ca855b1b38aa262f",
  },
  {
    id: "60f456e72586573630e2ae18",
    name: "hirimani",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2f3ca855b1b38aa262f",
  },
  {
    id: "60f456ed2586573630e2ae1d",
    name: "bangale",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2f3ca855b1b38aa262f",
  },
  {
    id: "60f456f32586573630e2ae22",
    name: "sala",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2f3ca855b1b38aa262f",
  },
  {
    id: "60f456fa2586573630e2ae27",
    name: "madogo",
    countyId: "60f05948a26f650268a1f7cb",
    constituencyId: "60f0b2f3ca855b1b38aa262f",
  },
  {
    id: "60f457862586573630e2ae2d",
    name: "faza",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b30aca855b1b38aa2633",
  },
  {
    id: "60f4578d2586573630e2ae32",
    name: "kiunga",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b30aca855b1b38aa2633",
  },
  {
    id: "60f457932586573630e2ae37",
    name: "basuba",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b30aca855b1b38aa2633",
  },
  {
    id: "60f457ad2586573630e2ae40",
    name: "hindi",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b337ca855b1b38aa2638",
  },
  {
    id: "60f457b32586573630e2ae45",
    name: "mkunumbi",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b337ca855b1b38aa2638",
  },
  {
    id: "60f457bb2586573630e2ae4a",
    name: "hongwe",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b337ca855b1b38aa2638",
  },
  {
    id: "60f457c32586573630e2ae4f",
    name: "witu",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b337ca855b1b38aa2638",
  },
  {
    id: "60f457ca2586573630e2ae54",
    name: "bahari",
    countyId: "60f05960a26f650268a1f7ce",
    constituencyId: "60f0b337ca855b1b38aa2638",
  },
  {
    id: "60f4581c2586573630e2ae5a",
    name: "chala",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b357ca855b1b38aa263c",
  },
  {
    id: "60f458262586573630e2ae5f",
    name: "mahoo",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b357ca855b1b38aa263c",
  },
  {
    id: "60f4582b2586573630e2ae64",
    name: "bomani",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b357ca855b1b38aa263c",
  },
  {
    id: "60f458322586573630e2ae69",
    name: "mboghoni",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b357ca855b1b38aa263c",
  },
  {
    id: "60f458372586573630e2ae6e",
    name: "mata",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b357ca855b1b38aa263c",
  },
  {
    id: "60f4584c2586573630e2ae73",
    name: "wundanyi/ mbale",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b365ca855b1b38aa2640",
  },
  {
    id: "60f458552586573630e2ae78",
    name: "werugha",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b365ca855b1b38aa2640",
  },
  {
    id: "60f4585e2586573630e2ae7d",
    name: "wumingu/ kishushe",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b365ca855b1b38aa2640",
  },
  {
    id: "60f458672586573630e2ae82",
    name: "mwanda/ mgange",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b365ca855b1b38aa2640",
  },
  {
    id: "60f4587a2586573630e2ae87",
    name: "ronge",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b371ca855b1b38aa2644",
  },
  {
    id: "60f458812586573630e2ae8c",
    name: "mwatate",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b371ca855b1b38aa2644",
  },
  {
    id: "60f458882586573630e2ae91",
    name: "bura",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b371ca855b1b38aa2644",
  },
  {
    id: "60f4588f2586573630e2ae96",
    name: "chawia",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b371ca855b1b38aa2644",
  },
  {
    id: "60f458972586573630e2ae9b",
    name: "wusi/ kishamba",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b371ca855b1b38aa2644",
  },
  {
    id: "60f458c42586573630e2aea0",
    name: "mbololo",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b37bca855b1b38aa2648",
  },
  {
    id: "60f459002586573630e2aea7",
    name: "sagala",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b37bca855b1b38aa2648",
  },
  {
    id: "60f459062586573630e2aeac",
    name: "marungu",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b37bca855b1b38aa2648",
  },
  {
    id: "60f459122586573630e2aeb1",
    name: "kaigau",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b37bca855b1b38aa2648",
  },
  {
    id: "60f4591d2586573630e2aeb6",
    name: "ngolia",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b37bca855b1b38aa2648",
  },
  {
    id: "60f459da2586573630e2aec2",
    name: "voi kaloleni",
    countyId: "60f05985a26f650268a1f7d1",
    constituencyId: "60f0b37bca855b1b38aa2648",
  },
  {
    id: "60f482ddf84b2e2c443dbd58",
    name: "waberi",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3adca855b1b38aa264c",
  },
  {
    id: "60f482eaf84b2e2c443dbd5d",
    name: "galbet",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3adca855b1b38aa264c",
  },
  {
    id: "60f482f7f84b2e2c443dbd62",
    name: "garissa township",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3adca855b1b38aa264c",
  },
  {
    id: "60f482fff84b2e2c443dbd67",
    name: "iftin",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3adca855b1b38aa264c",
  },
  {
    id: "60f4830df84b2e2c443dbd6c",
    name: "balambala",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3bfca855b1b38aa2652",
  },
  {
    id: "60f48316f84b2e2c443dbd71",
    name: "danyere",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3bfca855b1b38aa2652",
  },
  {
    id: "60f4831df84b2e2c443dbd76",
    name: "jarajara",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3bfca855b1b38aa2652",
  },
  {
    id: "60f48324f84b2e2c443dbd7b",
    name: "saka",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3bfca855b1b38aa2652",
  },
  {
    id: "60f4832cf84b2e2c443dbd80",
    name: "sankuri",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3bfca855b1b38aa2652",
  },
  {
    id: "60f48344f84b2e2c443dbd85",
    name: "modogashe",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3c8ca855b1b38aa2656",
  },
  {
    id: "60f48361f84b2e2c443dbd8a",
    name: "bename",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3c8ca855b1b38aa2656",
  },
  {
    id: "60f4836ef84b2e2c443dbd8f",
    name: "goreale",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3c8ca855b1b38aa2656",
  },
  {
    id: "60f48378f84b2e2c443dbd94",
    name: "maalamin",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3c8ca855b1b38aa2656",
  },
  {
    id: "60f48380f84b2e2c443dbd99",
    name: "sabena",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3c8ca855b1b38aa2656",
  },
  {
    id: "60f48388f84b2e2c443dbd9e",
    name: "baraki",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3c8ca855b1b38aa2656",
  },
  {
    id: "60f48398f84b2e2c443dbda3",
    name: "dertu",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3d1ca855b1b38aa265a",
  },
  {
    id: "60f4839ff84b2e2c443dbda8",
    name: "dadaab",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3d1ca855b1b38aa265a",
  },
  {
    id: "60f483a5f84b2e2c443dbdad",
    name: "labasigale",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3d1ca855b1b38aa265a",
  },
  {
    id: "60f483abf84b2e2c443dbdb2",
    name: "damajale",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3d1ca855b1b38aa265a",
  },
  {
    id: "60f483b4f84b2e2c443dbdb7",
    name: "liboi",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3d1ca855b1b38aa265a",
  },
  {
    id: "60f483bbf84b2e2c443dbdbc",
    name: "abakaile",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3d1ca855b1b38aa265a",
  },
  {
    id: "60f483fbf84b2e2c443dbdc7",
    name: "bura.",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3dbca855b1b38aa265e",
  },
  {
    id: "60f48408f84b2e2c443dbdcc",
    name: "dekaharia",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3dbca855b1b38aa265e",
  },
  {
    id: "60f48410f84b2e2c443dbdd1",
    name: "jarajila",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3dbca855b1b38aa265e",
  },
  {
    id: "60f48418f84b2e2c443dbdd6",
    name: "fafi",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3dbca855b1b38aa265e",
  },
  {
    id: "60f4841ff84b2e2c443dbddb",
    name: "nanighi",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3dbca855b1b38aa265e",
  },
  {
    id: "60f4842cf84b2e2c443dbde0",
    name: "hulugho",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3e7ca855b1b38aa2662",
  },
  {
    id: "60f48433f84b2e2c443dbde5",
    name: "sangailu",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3e7ca855b1b38aa2662",
  },
  {
    id: "60f48439f84b2e2c443dbdea",
    name: "ijara",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3e7ca855b1b38aa2662",
  },
  {
    id: "60f48441f84b2e2c443dbdef",
    name: "masalani",
    countyId: "60f059a9a26f650268a1f7d4",
    constituencyId: "60f0b3e7ca855b1b38aa2662",
  },
  {
    id: "60f4873cf84b2e2c443dbdf5",
    name: "gurar",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f48742f84b2e2c443dbdfa",
    name: "bute",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f4874cf84b2e2c443dbdff",
    name: "korondile",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f48750f84b2e2c443dbe04",
    name: "malkagufu",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f48756f84b2e2c443dbe09",
    name: "batalu",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f4875bf84b2e2c443dbe0e",
    name: "danaba",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f4876df84b2e2c443dbe13",
    name: "godoma",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b3ffca855b1b38aa2666",
  },
  {
    id: "60f487b8f84b2e2c443dbe18",
    name: "wagbri",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b406ca855b1b38aa266a",
  },
  {
    id: "60f487daf84b2e2c443dbe1d",
    name: "wajir township",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b406ca855b1b38aa266a",
  },
  {
    id: "60f487e2f84b2e2c443dbe22",
    name: "barwago",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b406ca855b1b38aa266a",
  },
  {
    id: "60f487e9f84b2e2c443dbe27",
    name: "khorof/ harar",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b406ca855b1b38aa266a",
  },
  {
    id: "60f487faf84b2e2c443dbe2c",
    name: "elben",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b410ca855b1b38aa266e",
  },
  {
    id: "60f487fff84b2e2c443dbe31",
    name: "sarman",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b410ca855b1b38aa266e",
  },
  {
    id: "60f48804f84b2e2c443dbe36",
    name: "tarbaj",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b410ca855b1b38aa266e",
  },
  {
    id: "60f48809f84b2e2c443dbe3b",
    name: "wargadud",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b410ca855b1b38aa266e",
  },
  {
    id: "60f48810f84b2e2c443dbe40",
    name: "arbajahan",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b41dca855b1b38aa2672",
  },
  {
    id: "60f489a2f84b2e2c443dbe49",
    name: "hadado/ athibohol",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b41dca855b1b38aa2672",
  },
  {
    id: "60f489baf84b2e2c443dbe4e",
    name: "ademasajide",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b41dca855b1b38aa2672",
  },
  {
    id: "60f489c2f84b2e2c443dbe53",
    name: "ganyure",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b41dca855b1b38aa2672",
  },
  {
    id: "60f489c7f84b2e2c443dbe58",
    name: "wagalla",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b41dca855b1b38aa2672",
  },
  {
    id: "60f489d8f84b2e2c443dbe5d",
    name: "eldas",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b428ca855b1b38aa2676",
  },
  {
    id: "60f489e7f84b2e2c443dbe67",
    name: "della",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b428ca855b1b38aa2676",
  },
  {
    id: "60f48a13f84b2e2c443dbe6c",
    name: "lakoley south/ basir",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b428ca855b1b38aa2676",
  },
  {
    id: "60f48a1ef84b2e2c443dbe71",
    name: "elnur/ tula tula",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b428ca855b1b38aa2676",
  },
  {
    id: "60f48a33f84b2e2c443dbe76",
    name: "benane",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b431ca855b1b38aa267a",
  },
  {
    id: "60f48a3bf84b2e2c443dbe7b",
    name: "burder",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b431ca855b1b38aa267a",
  },
  {
    id: "60f48a41f84b2e2c443dbe80",
    name: "dadaja bulla",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b431ca855b1b38aa267a",
  },
  {
    id: "60f48a49f84b2e2c443dbe85",
    name: "habaswein",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b431ca855b1b38aa267a",
  },
  {
    id: "60f48a50f84b2e2c443dbe8a",
    name: "lagboghol south",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b431ca855b1b38aa267a",
  },
  {
    id: "60f48a57f84b2e2c443dbe8f",
    name: "ibrahim ure",
    countyId: "60f059c7a26f650268a1f7d7",
    constituencyId: "60f0b431ca855b1b38aa267a",
  },
  {
    id: "60f48b3ff84b2e2c443dbe95",
    name: "takaba south",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b446ca855b1b38aa267e",
  },
  {
    id: "60f48b47f84b2e2c443dbe9a",
    name: "takaba",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b446ca855b1b38aa267e",
  },
  {
    id: "60f48b4df84b2e2c443dbe9f",
    name: "lagsure",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b446ca855b1b38aa267e",
  },
  {
    id: "60f48b52f84b2e2c443dbea4",
    name: "dandu",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b446ca855b1b38aa267e",
  },
  {
    id: "60f48b59f84b2e2c443dbea9",
    name: "gither",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b446ca855b1b38aa267e",
  },
  {
    id: "60f48b67f84b2e2c443dbeae",
    name: "banissa",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b451ca855b1b38aa2682",
  },
  {
    id: "60f48b6df84b2e2c443dbeb3",
    name: "derkhale",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b451ca855b1b38aa2682",
  },
  {
    id: "60f48b72f84b2e2c443dbeb8",
    name: "guba",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b451ca855b1b38aa2682",
  },
  {
    id: "60f48b78f84b2e2c443dbebd",
    name: "malkamari",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b451ca855b1b38aa2682",
  },
  {
    id: "60f48b7df84b2e2c443dbec2",
    name: "kiliwehiri",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b451ca855b1b38aa2682",
  },
  {
    id: "60f48b90f84b2e2c443dbec7",
    name: "ashabito",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b45bca855b1b38aa2686",
  },
  {
    id: "60f48b95f84b2e2c443dbecc",
    name: "guticha",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b45bca855b1b38aa2686",
  },
  {
    id: "60f48b9cf84b2e2c443dbed1",
    name: "marothile",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b45bca855b1b38aa2686",
  },
  {
    id: "60f48ba2f84b2e2c443dbed6",
    name: "rhamu",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b45bca855b1b38aa2686",
  },
  {
    id: "60f48ba8f84b2e2c443dbedb",
    name: "rhamu dimtu",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b45bca855b1b38aa2686",
  },
  {
    id: "60f48bb4f84b2e2c443dbee0",
    name: "wargadou",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b465ca855b1b38aa268a",
  },
  {
    id: "60f48bb9f84b2e2c443dbee5",
    name: "kutulo",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b465ca855b1b38aa268a",
  },
  {
    id: "60f48bbff84b2e2c443dbeea",
    name: "elwak south",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b465ca855b1b38aa268a",
  },
  {
    id: "60f48bc5f84b2e2c443dbeef",
    name: "elwak north",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b465ca855b1b38aa268a",
  },
  {
    id: "60f48bcbf84b2e2c443dbef4",
    name: "shimbir fatuma",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b465ca855b1b38aa268a",
  },
  {
    id: "60f48bdaf84b2e2c443dbef9",
    name: "arabia",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b473ca855b1b38aa268e",
  },
  {
    id: "60f48be0f84b2e2c443dbefe",
    name: "libehia",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b473ca855b1b38aa268e",
  },
  {
    id: "60f48be7f84b2e2c443dbf03",
    name: "khalalio",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b473ca855b1b38aa268e",
  },
  {
    id: "60f48becf84b2e2c443dbf08",
    name: "neboi",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b473ca855b1b38aa268e",
  },
  {
    id: "60f48bfaf84b2e2c443dbf0d",
    name: "mandera township",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b473ca855b1b38aa268e",
  },
  {
    id: "60f48c39f84b2e2c443dbf18",
    name: "sala.",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b47fca855b1b38aa2692",
  },
  {
    id: "60f48c43f84b2e2c443dbf1d",
    name: "fino",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b47fca855b1b38aa2692",
  },
  {
    id: "60f48c4af84b2e2c443dbf22",
    name: "lafey",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b47fca855b1b38aa2692",
  },
  {
    id: "60f48c50f84b2e2c443dbf27",
    name: "waranqara",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b47fca855b1b38aa2692",
  },
  {
    id: "60f48c56f84b2e2c443dbf2c",
    name: "alango gof",
    countyId: "60f06354a26f650268a1f7dd",
    constituencyId: "60f0b47fca855b1b38aa2692",
  },
  {
    id: "60f52ddac301ee30d05e470b",
    name: "butiye",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52de1c301ee30d05e4710",
    name: "sololo",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52dedc301ee30d05e4715",
    name: "heillu/ manyatta",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52df3c301ee30d05e471a",
    name: "golbo",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52df9c301ee30d05e471f",
    name: "moyale township",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52e00c301ee30d05e4724",
    name: "uran",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52e06c301ee30d05e4729",
    name: "obbu",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b4f4ca855b1b38aa2696",
  },
  {
    id: "60f52e15c301ee30d05e472e",
    name: "dukana",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b506ca855b1b38aa269c",
  },
  {
    id: "60f52e1cc301ee30d05e4733",
    name: "maikona",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b506ca855b1b38aa269c",
  },
  {
    id: "60f52e21c301ee30d05e4738",
    name: "turbi",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b506ca855b1b38aa269c",
  },
  {
    id: "60f52e27c301ee30d05e473d",
    name: "north horr",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b506ca855b1b38aa269c",
  },
  {
    id: "60f52e2dc301ee30d05e4742",
    name: "illeret",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b506ca855b1b38aa269c",
  },
  {
    id: "60f52e3ec301ee30d05e4747",
    name: "sagate/ jaldesa",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b512ca855b1b38aa26a0",
  },
  {
    id: "60f52e47c301ee30d05e474c",
    name: "karare",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b512ca855b1b38aa26a0",
  },
  {
    id: "60f52e4dc301ee30d05e4751",
    name: "marsabit central",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b512ca855b1b38aa26a0",
  },
  {
    id: "60f52e60c301ee30d05e4756",
    name: "loiyangalani",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b51cca855b1b38aa26a4",
  },
  {
    id: "60f52e6dc301ee30d05e475b",
    name: "kargi/ south horr",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b51cca855b1b38aa26a4",
  },
  {
    id: "60f52e77c301ee30d05e4760",
    name: "korr/ ngurunit",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b51cca855b1b38aa26a4",
  },
  {
    id: "60f52e7ec301ee30d05e4765",
    name: "logo logo",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b51cca855b1b38aa26a4",
  },
  {
    id: "60f52e85c301ee30d05e476a",
    name: "laisamis",
    countyId: "60f06594a26f650268a1f7e3",
    constituencyId: "60f0b51cca855b1b38aa26a4",
  },
  {
    id: "60f530efc301ee30d05e4770",
    name: "wabera",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f530f8c301ee30d05e4775",
    name: "bulla pesa",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f530ffc301ee30d05e477a",
    name: "chari",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f53106c301ee30d05e477f",
    name: "cherab",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f5310cc301ee30d05e4784",
    name: "ngare mara",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f53114c301ee30d05e4789",
    name: "burat",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f5311ec301ee30d05e478e",
    name: "oldo/ nyiro",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b535ca855b1b38aa26a8",
  },
  {
    id: "60f53134c301ee30d05e4793",
    name: "garba tulla",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b546ca855b1b38aa26ac",
  },
  {
    id: "60f5313cc301ee30d05e4798",
    name: "kina",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b546ca855b1b38aa26ac",
  },
  {
    id: "60f53143c301ee30d05e479d",
    name: "sericho",
    countyId: "60f06a73a26f650268a1f7e9",
    constituencyId: "60f0b546ca855b1b38aa26ac",
  },
  {
    id: "60f531bcc301ee30d05e47a3",
    name: "maua",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b55bca855b1b38aa26b0",
  },
  {
    id: "60f531c5c301ee30d05e47a8",
    name: "kegoi/ antubochiu",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b55bca855b1b38aa26b0",
  },
  {
    id: "60f531cbc301ee30d05e47ad",
    name: "athiru",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b55bca855b1b38aa26b0",
  },
  {
    id: "60f531d0c301ee30d05e47b2",
    name: "gaiti",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b55bca855b1b38aa26b0",
  },
  {
    id: "60f531d4c301ee30d05e47b7",
    name: "akachiu",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b55bca855b1b38aa26b0",
  },
  {
    id: "60f531dac301ee30d05e47bc",
    name: "kanuni",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b55bca855b1b38aa26b0",
  },
  {
    id: "60f531efc301ee30d05e47c1",
    name: "akirang'ondu",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b56fca855b1b38aa26b6",
  },
  {
    id: "60f531fbc301ee30d05e47c8",
    name: "ruujine",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b56fca855b1b38aa26b6",
  },
  {
    id: "60f53201c301ee30d05e47cd",
    name: "igembe east njia",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b56fca855b1b38aa26b6",
  },
  {
    id: "60f53209c301ee30d05e47d2",
    name: "kangeta",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b56fca855b1b38aa26b6",
  },
  {
    id: "60f5322fc301ee30d05e47d7",
    name: "antuambui",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b57aca855b1b38aa26ba",
  },
  {
    id: "60f53233c301ee30d05e47dc",
    name: "ntunene",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b57aca855b1b38aa26ba",
  },
  {
    id: "60f5323cc301ee30d05e47e1",
    name: "antubetwe kiongo",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b57aca855b1b38aa26ba",
  },
  {
    id: "60f53243c301ee30d05e47e6",
    name: "naathui",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b57aca855b1b38aa26ba",
  },
  {
    id: "60f53249c301ee30d05e47eb",
    name: "amwathi",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b57aca855b1b38aa26ba",
  },
  {
    id: "60f5325bc301ee30d05e47f0",
    name: "athwana",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b583ca855b1b38aa26be",
  },
  {
    id: "60f53260c301ee30d05e47f5",
    name: "akithi",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b583ca855b1b38aa26be",
  },
  {
    id: "60f53265c301ee30d05e47fa",
    name: "kianjai",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b583ca855b1b38aa26be",
  },
  {
    id: "60f5326bc301ee30d05e47ff",
    name: "nkomo",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b583ca855b1b38aa26be",
  },
  {
    id: "60f53270c301ee30d05e4804",
    name: "mbeu",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b583ca855b1b38aa26be",
  },
  {
    id: "60f53298c301ee30d05e4809",
    name: "thangatha",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b58fca855b1b38aa26c2",
  },
  {
    id: "60f532a0c301ee30d05e480e",
    name: "mikinduri",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b58fca855b1b38aa26c2",
  },
  {
    id: "60f532a6c301ee30d05e4813",
    name: "kiguchwa",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b58fca855b1b38aa26c2",
  },
  {
    id: "60f532acc301ee30d05e4818",
    name: "mithara",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b58fca855b1b38aa26c2",
  },
  {
    id: "60f532b1c301ee30d05e481d",
    name: "karama",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b58fca855b1b38aa26c2",
  },
  {
    id: "60f532bfc301ee30d05e4822",
    name: "municipality",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b59aca855b1b38aa26c6",
  },
  {
    id: "60f532c5c301ee30d05e4827",
    name: "ntima east",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b59aca855b1b38aa26c6",
  },
  {
    id: "60f532cdc301ee30d05e482c",
    name: "ntima west",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b59aca855b1b38aa26c6",
  },
  {
    id: "60f532d5c301ee30d05e4831",
    name: "nyaki west",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b59aca855b1b38aa26c6",
  },
  {
    id: "60f532ddc301ee30d05e4836",
    name: "nyaki east",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b59aca855b1b38aa26c6",
  },
  {
    id: "60f532eec301ee30d05e483b",
    name: "timau",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5a5ca855b1b38aa26ca",
  },
  {
    id: "60f532f4c301ee30d05e4840",
    name: "kisima",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5a5ca855b1b38aa26ca",
  },
  {
    id: "60f532fbc301ee30d05e4845",
    name: "kiirua/ naari",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5a5ca855b1b38aa26ca",
  },
  {
    id: "60f53302c301ee30d05e484a",
    name: "ruiri/ rwarera",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5a5ca855b1b38aa26ca",
  },
  {
    id: "60f53315c301ee30d05e484f",
    name: "mwanganthia",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5b2ca855b1b38aa26ce",
  },
  {
    id: "60f5331dc301ee30d05e4854",
    name: "abothuguchi central",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5b2ca855b1b38aa26ce",
  },
  {
    id: "60f53324c301ee30d05e4859",
    name: "abothuguchi west",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5b2ca855b1b38aa26ce",
  },
  {
    id: "60f5332ac301ee30d05e485e",
    name: "kiagu",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5b2ca855b1b38aa26ce",
  },
  {
    id: "60f53335c301ee30d05e4863",
    name: "kibirichia",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5b2ca855b1b38aa26ce",
  },
  {
    id: "60f53343c301ee30d05e4868",
    name: "mitunguu",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5bfca855b1b38aa26d2",
  },
  {
    id: "60f53349c301ee30d05e486d",
    name: "igoji east",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5bfca855b1b38aa26d2",
  },
  {
    id: "60f53350c301ee30d05e4872",
    name: "igoji west",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5bfca855b1b38aa26d2",
  },
  {
    id: "60f53357c301ee30d05e4877",
    name: "abogeta east",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5bfca855b1b38aa26d2",
  },
  {
    id: "60f5335dc301ee30d05e487c",
    name: "abogeta west",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5bfca855b1b38aa26d2",
  },
  {
    id: "60f53365c301ee30d05e4881",
    name: "nkuene",
    countyId: "60f06aaaa26f650268a1f7ee",
    constituencyId: "60f0b5bfca855b1b38aa26d2",
  },
  {
    id: "60f533ccc301ee30d05e4887",
    name: "mitheru",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5dcca855b1b38aa26d6",
  },
  {
    id: "60f533d1c301ee30d05e488c",
    name: "muthambi",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5dcca855b1b38aa26d6",
  },
  {
    id: "60f533d7c301ee30d05e4891",
    name: "mwimbi",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5dcca855b1b38aa26d6",
  },
  {
    id: "60f533ddc301ee30d05e4896",
    name: "ganga",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5dcca855b1b38aa26d6",
  },
  {
    id: "60f533e4c301ee30d05e489b",
    name: "chogoria",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5dcca855b1b38aa26d6",
  },
  {
    id: "60f533f7c301ee30d05e48a0",
    name: "mariani",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5efca855b1b38aa26da",
  },
  {
    id: "60f533fcc301ee30d05e48a5",
    name: "karingani",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5efca855b1b38aa26da",
  },
  {
    id: "60f53401c301ee30d05e48aa",
    name: "magumoni",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5efca855b1b38aa26da",
  },
  {
    id: "60f53407c301ee30d05e48af",
    name: "mugwe",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5efca855b1b38aa26da",
  },
  {
    id: "60f53411c301ee30d05e48b4",
    name: "igambang'ombe",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b5efca855b1b38aa26da",
  },
  {
    id: "60f53423c301ee30d05e48b9",
    name: "gatunga",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b657ca855b1b38aa26de",
  },
  {
    id: "60f53429c301ee30d05e48be",
    name: "mukothima",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b657ca855b1b38aa26de",
  },
  {
    id: "60f5342dc301ee30d05e48c3",
    name: "nkondi",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b657ca855b1b38aa26de",
  },
  {
    id: "60f53434c301ee30d05e48c8",
    name: "chiakariga",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b657ca855b1b38aa26de",
  },
  {
    id: "60f5343ac301ee30d05e48cd",
    name: "marimanti",
    countyId: "60f06ac3a26f650268a1f7f1",
    constituencyId: "60f0b657ca855b1b38aa26de",
  },
  {
    id: "60f534a0c301ee30d05e48d3",
    name: "ruguru/ ngandori",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b66eca855b1b38aa26e2",
  },
  {
    id: "60f534aec301ee30d05e48da",
    name: "kithimu",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b66eca855b1b38aa26e2",
  },
  {
    id: "60f534b8c301ee30d05e48e1",
    name: "mbeti north",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b66eca855b1b38aa26e2",
  },
  {
    id: "60f534bec301ee30d05e48e6",
    name: "kirimari",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b66eca855b1b38aa26e2",
  },
  {
    id: "60f534c4c301ee30d05e48eb",
    name: "gaturi south",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b66eca855b1b38aa26e2",
  },
  {
    id: "60f534d0c301ee30d05e48f0",
    name: "gaturi north",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b679ca855b1b38aa26e6",
  },
  {
    id: "60f534d6c301ee30d05e48f5",
    name: "kagaari south",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b679ca855b1b38aa26e6",
  },
  {
    id: "60f534ddc301ee30d05e48fa",
    name: "kagaari north",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b679ca855b1b38aa26e6",
  },
  {
    id: "60f534e2c301ee30d05e48ff",
    name: "central ward",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b679ca855b1b38aa26e6",
  },
  {
    id: "60f534e9c301ee30d05e4904",
    name: "kyeni north",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b679ca855b1b38aa26e6",
  },
  {
    id: "60f534f6c301ee30d05e4909",
    name: "kyeni south",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b679ca855b1b38aa26e6",
  },
  {
    id: "60f535e6c301ee30d05e490e",
    name: "mwea",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b685ca855b1b38aa26ea",
  },
  {
    id: "60f535ecc301ee30d05e4913",
    name: "amakim",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b685ca855b1b38aa26ea",
  },
  {
    id: "60f535f2c301ee30d05e4918",
    name: "mbeti south",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b685ca855b1b38aa26ea",
  },
  {
    id: "60f535f8c301ee30d05e491d",
    name: "mavuria",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b685ca855b1b38aa26ea",
  },
  {
    id: "60f535fec301ee30d05e4922",
    name: "kiambere",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b685ca855b1b38aa26ea",
  },
  {
    id: "60f53605c301ee30d05e4927",
    name: "nthawa",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b68fca855b1b38aa26ee",
  },
  {
    id: "60f5360ac301ee30d05e492c",
    name: "muminji",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b68fca855b1b38aa26ee",
  },
  {
    id: "60f53611c301ee30d05e4931",
    name: "evurore",
    countyId: "60f06af5a26f650268a1f7f7",
    constituencyId: "60f0b68fca855b1b38aa26ee",
  },
  {
    id: "60f55b1bc301ee30d05e4938",
    name: "ngomeni",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6a6ca855b1b38aa26f2",
  },
  {
    id: "60f55b1fc301ee30d05e493d",
    name: "kyuso",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6a6ca855b1b38aa26f2",
  },
  {
    id: "60f55b24c301ee30d05e4942",
    name: "mumoni",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6a6ca855b1b38aa26f2",
  },
  {
    id: "60f55b29c301ee30d05e4947",
    name: "tseikuru",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6a6ca855b1b38aa26f2",
  },
  {
    id: "60f55b2ec301ee30d05e494c",
    name: "tharaka",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6a6ca855b1b38aa26f2",
  },
  {
    id: "60f55b3cc301ee30d05e4951",
    name: "kyome/ thaana",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6b0ca855b1b38aa26f6",
  },
  {
    id: "60f55b45c301ee30d05e4956",
    name: "nguutani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6b0ca855b1b38aa26f6",
  },
  {
    id: "60f55b4ac301ee30d05e495b",
    name: "migwani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6b0ca855b1b38aa26f6",
  },
  {
    id: "60f55b51c301ee30d05e4960",
    name: "kiomo/ kyethani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6b0ca855b1b38aa26f6",
  },
  {
    id: "60f55b6ec301ee30d05e4967",
    name: "mwingi central",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6c4ca855b1b38aa26fa",
  },
  {
    id: "60f55b75c301ee30d05e496c",
    name: "kivou",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6c4ca855b1b38aa26fa",
  },
  {
    id: "60f55b84c301ee30d05e4971",
    name: "nguni",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6c4ca855b1b38aa26fa",
  },
  {
    id: "60f55b8ac301ee30d05e4976",
    name: "nuu",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6c4ca855b1b38aa26fa",
  },
  {
    id: "60f55b95c301ee30d05e497b",
    name: "mui",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6c4ca855b1b38aa26fa",
  },
  {
    id: "60f55b9bc301ee30d05e4980",
    name: "waita",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6c4ca855b1b38aa26fa",
  },
  {
    id: "60f55bacc301ee30d05e4985",
    name: "mutonguni",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6cfca855b1b38aa26fe",
  },
  {
    id: "60f55bb1c301ee30d05e498a",
    name: "kauwi",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6cfca855b1b38aa26fe",
  },
  {
    id: "60f55bb6c301ee30d05e498f",
    name: "matinyani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6cfca855b1b38aa26fe",
  },
  {
    id: "60f55bc0c301ee30d05e4994",
    name: "kwa mutonga/ kithum ula",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6cfca855b1b38aa26fe",
  },
  {
    id: "60f55bd7c301ee30d05e4999",
    name: "kisasi",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6daca855b1b38aa2702",
  },
  {
    id: "60f55bddc301ee30d05e499e",
    name: "mbitini",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6daca855b1b38aa2702",
  },
  {
    id: "60f55be4c301ee30d05e49a3",
    name: "kwavonza/ yatta",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6daca855b1b38aa2702",
  },
  {
    id: "60f55bebc301ee30d05e49a8",
    name: "kanyangi",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6daca855b1b38aa2702",
  },
  {
    id: "60f55bf9c301ee30d05e49ad",
    name: "miambani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6e4ca855b1b38aa2706",
  },
  {
    id: "60f55c18c301ee30d05e49b2",
    name: "kitui township",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6e4ca855b1b38aa2706",
  },
  {
    id: "60f55c1ec301ee30d05e49b7",
    name: "kyangwithya west",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6e4ca855b1b38aa2706",
  },
  {
    id: "60f55c24c301ee30d05e49bc",
    name: "mulango",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6e4ca855b1b38aa2706",
  },
  {
    id: "60f55c2ac301ee30d05e49c1",
    name: "kyangwithya east",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6e4ca855b1b38aa2706",
  },
  {
    id: "60f55c40c301ee30d05e49c6",
    name: "zombe/ mwitika",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6f0ca855b1b38aa270a",
  },
  {
    id: "60f55c43c301ee30d05e49cb",
    name: "nzambani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6f0ca855b1b38aa270a",
  },
  {
    id: "60f55c49c301ee30d05e49d0",
    name: "chuluni",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6f0ca855b1b38aa270a",
  },
  {
    id: "60f55c51c301ee30d05e49d5",
    name: "voo/ kyamatu",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6f0ca855b1b38aa270a",
  },
  {
    id: "60f55c5ac301ee30d05e49da",
    name: "endau/ malalani",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6f0ca855b1b38aa270a",
  },
  {
    id: "60f55c62c301ee30d05e49df",
    name: "mutito/ kaliku",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6f0ca855b1b38aa270a",
  },
  {
    id: "60f55c70c301ee30d05e49e4",
    name: "ikana/ kyantune",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6fcca855b1b38aa270e",
  },
  {
    id: "60f55c76c301ee30d05e49e9",
    name: "mutomo",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6fcca855b1b38aa270e",
  },
  {
    id: "60f55c7bc301ee30d05e49ee",
    name: "mutha",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6fcca855b1b38aa270e",
  },
  {
    id: "60f55c81c301ee30d05e49f3",
    name: "ikutha",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6fcca855b1b38aa270e",
  },
  {
    id: "60f55c8bc301ee30d05e49f8",
    name: "kanziko",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6fcca855b1b38aa270e",
  },
  {
    id: "60f55c90c301ee30d05e49fd",
    name: "athi",
    countyId: "60f06b2ba26f650268a1f7fa",
    constituencyId: "60f0b6fcca855b1b38aa270e",
  },
  {
    id: "60f55ce9c301ee30d05e4a03",
    name: "kivaa",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b732ca855b1b38aa2712",
  },
  {
    id: "60f55cf0c301ee30d05e4a08",
    name: "masinga",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b732ca855b1b38aa2712",
  },
  {
    id: "60f55d09c301ee30d05e4a0f",
    name: "masinga central",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b732ca855b1b38aa2712",
  },
  {
    id: "60f55d15c301ee30d05e4a14",
    name: "ekalakala",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b732ca855b1b38aa2712",
  },
  {
    id: "60f55d18c301ee30d05e4a19",
    name: "muthesya",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b732ca855b1b38aa2712",
  },
  {
    id: "60f55d1dc301ee30d05e4a1e",
    name: "ndithini",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b732ca855b1b38aa2712",
  },
  {
    id: "60f55d29c301ee30d05e4a23",
    name: "ndalani",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b73dca855b1b38aa2716",
  },
  {
    id: "60f55d2ec301ee30d05e4a28",
    name: "matuu",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b73dca855b1b38aa2716",
  },
  {
    id: "60f55d34c301ee30d05e4a2d",
    name: "kithimani",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b73dca855b1b38aa2716",
  },
  {
    id: "60f55d39c301ee30d05e4a32",
    name: "ikomba",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b73dca855b1b38aa2716",
  },
  {
    id: "60f55d3ec301ee30d05e4a37",
    name: "katangi",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b73dca855b1b38aa2716",
  },
  {
    id: "60f55d4cc301ee30d05e4a3c",
    name: "kangundo north",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b749ca855b1b38aa271a",
  },
  {
    id: "60f55d53c301ee30d05e4a41",
    name: "kangundo central",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b749ca855b1b38aa271a",
  },
  {
    id: "60f55d58c301ee30d05e4a46",
    name: "kangundo east",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b749ca855b1b38aa271a",
  },
  {
    id: "60f55d60c301ee30d05e4a4b",
    name: "kangundo west",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b749ca855b1b38aa271a",
  },
  {
    id: "60f55d6ec301ee30d05e4a50",
    name: "tala",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b754ca855b1b38aa271e",
  },
  {
    id: "60f55d74c301ee30d05e4a55",
    name: "matungulu north",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b754ca855b1b38aa271e",
  },
  {
    id: "60f55d79c301ee30d05e4a5a",
    name: "matungulu east",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b754ca855b1b38aa271e",
  },
  {
    id: "60f55d7fc301ee30d05e4a5f",
    name: "matungulu west",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b754ca855b1b38aa271e",
  },
  {
    id: "60f55d86c301ee30d05e4a64",
    name: "kyeleni",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b754ca855b1b38aa271e",
  },
  {
    id: "60f55d92c301ee30d05e4a69",
    name: "mitaboni",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b75fca855b1b38aa2722",
  },
  {
    id: "60f55d98c301ee30d05e4a6e",
    name: "kathiani central",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b75fca855b1b38aa2722",
  },
  {
    id: "60f55da6c301ee30d05e4a73",
    name: "upper kaewa/ iveti",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b75fca855b1b38aa2722",
  },
  {
    id: "60f55dafc301ee30d05e4a78",
    name: "lower kaewa/ kaani",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b75fca855b1b38aa2722",
  },
  {
    id: "60f55dbdc301ee30d05e4a7d",
    name: "athi river",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b768ca855b1b38aa2726",
  },
  {
    id: "60f55dc4c301ee30d05e4a82",
    name: "kinanie",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b768ca855b1b38aa2726",
  },
  {
    id: "60f55dc9c301ee30d05e4a87",
    name: "muthwani",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b768ca855b1b38aa2726",
  },
  {
    id: "60f55dcfc301ee30d05e4a8c",
    name: "syokimau/ mulolongo",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b768ca855b1b38aa2726",
  },
  {
    id: "60f55ddcc301ee30d05e4a91",
    name: "kalama",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55de2c301ee30d05e4a96",
    name: "mua",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55de6c301ee30d05e4a9b",
    name: "mutitini",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55decc301ee30d05e4aa0",
    name: "machakos central",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55df3c301ee30d05e4aa5",
    name: "mumbuni north",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55e20c301ee30d05e4aaa",
    name: "muvuti/ kiima-kimwe",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55e2ec301ee30d05e4aaf",
    name: "kola",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b772ca855b1b38aa272a",
  },
  {
    id: "60f55e39c301ee30d05e4ab4",
    name: "mbiuni",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b77cca855b1b38aa272e",
  },
  {
    id: "60f55e42c301ee30d05e4ab9",
    name: "makutano/ mwala",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b77cca855b1b38aa272e",
  },
  {
    id: "60f55e49c301ee30d05e4abe",
    name: "masii",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b77cca855b1b38aa272e",
  },
  {
    id: "60f55e4fc301ee30d05e4ac3",
    name: "muthetheni",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b77cca855b1b38aa272e",
  },
  {
    id: "60f55e55c301ee30d05e4ac8",
    name: "wamunyu",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b77cca855b1b38aa272e",
  },
  {
    id: "60f55e5ac301ee30d05e4acd",
    name: "kibauni",
    countyId: "60f06b5da26f650268a1f7fd",
    constituencyId: "60f0b77cca855b1b38aa272e",
  },
  {
    id: "60f55eacc301ee30d05e4ad3",
    name: "tulimani",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b791ca855b1b38aa2732",
  },
  {
    id: "60f55eb1c301ee30d05e4ad8",
    name: "mbooni",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b791ca855b1b38aa2732",
  },
  {
    id: "60f55eb8c301ee30d05e4add",
    name: "kithungo/ kitundu",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b791ca855b1b38aa2732",
  },
  {
    id: "60f55ebfc301ee30d05e4ae2",
    name: "kiteta/ kisau",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b791ca855b1b38aa2732",
  },
  {
    id: "60f55ecac301ee30d05e4ae7",
    name: "waia-kako",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b791ca855b1b38aa2732",
  },
  {
    id: "60f55ed1c301ee30d05e4aec",
    name: "kalawa",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b791ca855b1b38aa2732",
  },
  {
    id: "60f55eddc301ee30d05e4af1",
    name: "kasikeu",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b799ca855b1b38aa2736",
  },
  {
    id: "60f55ee4c301ee30d05e4af6",
    name: "mukaa",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b799ca855b1b38aa2736",
  },
  {
    id: "60f55eedc301ee30d05e4afb",
    name: "kiima kiu/ kalanzoni",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b799ca855b1b38aa2736",
  },
  {
    id: "60f55ef3c301ee30d05e4b00",
    name: "ukia",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7a2ca855b1b38aa273a",
  },
  {
    id: "60f55ef8c301ee30d05e4b05",
    name: "kee",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7a2ca855b1b38aa273a",
  },
  {
    id: "60f55efdc301ee30d05e4b0a",
    name: "kilungu",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7a2ca855b1b38aa273a",
  },
  {
    id: "60f55f06c301ee30d05e4b0f",
    name: "ilima",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7a2ca855b1b38aa273a",
  },
  {
    id: "60f55f7bc301ee30d05e4b14",
    name: "wote",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55f83c301ee30d05e4b19",
    name: "muvau/ kikuumini",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55f8dc301ee30d05e4b1e",
    name: "mavindini",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55f92c301ee30d05e4b23",
    name: "kitise/ kithuki",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55f98c301ee30d05e4b28",
    name: "kathonzweni",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55fa2c301ee30d05e4b2d",
    name: "nzau/ kilili/ kalamba",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55fc7c301ee30d05e4b38",
    name: "mbitini.",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7abca855b1b38aa273e",
  },
  {
    id: "60f55fddc301ee30d05e4b3d",
    name: "makindu",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7b4ca855b1b38aa2742",
  },
  {
    id: "60f55fe2c301ee30d05e4b42",
    name: "nguumo",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7b4ca855b1b38aa2742",
  },
  {
    id: "60f55fe8c301ee30d05e4b47",
    name: "kikumbulyu north",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7b4ca855b1b38aa2742",
  },
  {
    id: "60f55feec301ee30d05e4b4c",
    name: "kimumbulyu south",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7b4ca855b1b38aa2742",
  },
  {
    id: "60f55ffcc301ee30d05e4b51",
    name: "nguu/ masumba",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7b4ca855b1b38aa2742",
  },
  {
    id: "60f56007c301ee30d05e4b56",
    name: "emali/ mulala",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7b4ca855b1b38aa2742",
  },
  {
    id: "60f56043c301ee30d05e4b5b",
    name: "masongaleni",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7beca855b1b38aa2746",
  },
  {
    id: "60f5604ac301ee30d05e4b60",
    name: "mtito andei",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7beca855b1b38aa2746",
  },
  {
    id: "60f56050c301ee30d05e4b65",
    name: "thange",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7beca855b1b38aa2746",
  },
  {
    id: "60f56059c301ee30d05e4b6a",
    name: "ivingoni/ nzambani",
    countyId: "60f06b69a26f650268a1f800",
    constituencyId: "60f0b7beca855b1b38aa2746",
  },
  {
    id: "60f5a7784675650584112042",
    name: "kaeris",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0baadca855b1b38aa27d2",
  },
  {
    id: "60f5a77f4675650584112047",
    name: "lake zone",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0baadca855b1b38aa27d2",
  },
  {
    id: "60f5a786467565058411204c",
    name: "lapur",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0baadca855b1b38aa27d2",
  },
  {
    id: "60f5a78e4675650584112051",
    name: "kaaleng/ kaikor",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0baadca855b1b38aa27d2",
  },
  {
    id: "60f5a7944675650584112056",
    name: "kibish",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0baadca855b1b38aa27d2",
  },
  {
    id: "60f5a79d467565058411205b",
    name: "nakalale",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0baadca855b1b38aa27d2",
  },
  {
    id: "60f5a7ab4675650584112060",
    name: "kakuma",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7b24675650584112065",
    name: "lopur",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7b7467565058411206a",
    name: "letea",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7bd467565058411206f",
    name: "songot",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7c34675650584112074",
    name: "kalobeyei",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7c94675650584112079",
    name: "lokichoggio",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7d0467565058411207e",
    name: "nanaam",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bab7ca855b1b38aa27d6",
  },
  {
    id: "60f5a7e24675650584112083",
    name: "kerio delta",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bac6ca855b1b38aa27da",
  },
  {
    id: "60f5a7ec4675650584112088",
    name: "kang'atotha",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bac6ca855b1b38aa27da",
  },
  {
    id: "60f5a7f9467565058411208d",
    name: "kalokol",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bac6ca855b1b38aa27da",
  },
  {
    id: "60f5a7fe4675650584112092",
    name: "lodwar township",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bac6ca855b1b38aa27da",
  },
  {
    id: "60f5a80d4675650584112097",
    name: "kanamkemer",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bac6ca855b1b38aa27da",
  },
  {
    id: "60f5a856467565058411209c",
    name: "kotaruk/ lobei",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bad0ca855b1b38aa27de",
  },
  {
    id: "60f5a85946756505841120a1",
    name: "turkwel",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bad0ca855b1b38aa27de",
  },
  {
    id: "60f5a89746756505841120a6",
    name: "loima",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bad0ca855b1b38aa27de",
  },
  {
    id: "60f5a89c46756505841120ab",
    name: "lokiriama/ loren gippi",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bad0ca855b1b38aa27de",
  },
  {
    id: "60f5a8f046756505841120b0",
    name: "kaputir",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0badaca855b1b38aa27e2",
  },
  {
    id: "60f5a8f846756505841120b5",
    name: "katilu",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0badaca855b1b38aa27e2",
  },
  {
    id: "60f5a8fe46756505841120ba",
    name: "lobokat",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0badaca855b1b38aa27e2",
  },
  {
    id: "60f5a90446756505841120bf",
    name: "kalapata",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0badaca855b1b38aa27e2",
  },
  {
    id: "60f5a90946756505841120c4",
    name: "lokichar",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0badaca855b1b38aa27e2",
  },
  {
    id: "60f5a99b46756505841120c9",
    name: "kapedo/ napeitom",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bae5ca855b1b38aa27e6",
  },
  {
    id: "60f5a9cf46756505841120ce",
    name: "katilia",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bae5ca855b1b38aa27e6",
  },
  {
    id: "60f5a9d646756505841120d3",
    name: "lokori/ kochodin",
    countyId: "60f06c38a26f650268a1f814",
    constituencyId: "60f0bae5ca855b1b38aa27e6",
  },
  {
    id: "60f5aa1f46756505841120d9",
    name: "riwo",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc065103d52e8c76c583",
  },
  {
    id: "60f5aa2646756505841120de",
    name: "kapenguria",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc065103d52e8c76c583",
  },
  {
    id: "60f5aa2d46756505841120e3",
    name: "mnagei",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc065103d52e8c76c583",
  },
  {
    id: "60f5aa3446756505841120e8",
    name: "siyoi",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc065103d52e8c76c583",
  },
  {
    id: "60f5aa3a46756505841120ed",
    name: "endugh",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc065103d52e8c76c583",
  },
  {
    id: "60f5aa4046756505841120f2",
    name: "sook",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc065103d52e8c76c583",
  },
  {
    id: "60f5aa4c46756505841120f7",
    name: "sekerr",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc5e74a8da3390672931",
  },
  {
    id: "60f5aa6746756505841120fc",
    name: "masool",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc5e74a8da3390672931",
  },
  {
    id: "60f5aa6d4675650584112101",
    name: "lomut",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc5e74a8da3390672931",
  },
  {
    id: "60f5aa784675650584112106",
    name: "weiwei",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc5e74a8da3390672931",
  },
  {
    id: "60f5aa89467565058411210b",
    name: "suam",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7274a8da3390672935",
  },
  {
    id: "60f5aa8f4675650584112110",
    name: "kodich",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7274a8da3390672935",
  },
  {
    id: "60f5aa974675650584112115",
    name: "kasei",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7274a8da3390672935",
  },
  {
    id: "60f5aa9d467565058411211a",
    name: "kapchok",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7274a8da3390672935",
  },
  {
    id: "60f5aaa3467565058411211f",
    name: "kiwawa",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7274a8da3390672935",
  },
  {
    id: "60f5aaa94675650584112124",
    name: "alale",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7274a8da3390672935",
  },
  {
    id: "60f5aab54675650584112129",
    name: "chepareria",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7b74a8da3390672939",
  },
  {
    id: "60f5aabb467565058411212e",
    name: "batei",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7b74a8da3390672939",
  },
  {
    id: "60f5aabf4675650584112133",
    name: "lelan",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7b74a8da3390672939",
  },
  {
    id: "60f5aac44675650584112138",
    name: "tapach",
    countyId: "60f06c4ca26f650268a1f81a",
    constituencyId: "60f0bc7b74a8da3390672939",
  },
  {
    id: "60f5ab0c467565058411213e",
    name: "lodokejek",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc8f74a8da339067293d",
  },
  {
    id: "60f5ab164675650584112143",
    name: "suguta marmar",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc8f74a8da339067293d",
  },
  {
    id: "60f5ab1c4675650584112148",
    name: "maralal",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc8f74a8da339067293d",
  },
  {
    id: "60f5ab21467565058411214d",
    name: "loosuk",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc8f74a8da339067293d",
  },
  {
    id: "60f5ab264675650584112152",
    name: "poro",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc8f74a8da339067293d",
  },
  {
    id: "60f5ab374675650584112157",
    name: "el-barta",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab3a467565058411215c",
    name: "nachola",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab404675650584112161",
    name: "ndoto",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab454675650584112166",
    name: "nyiro",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab4a467565058411216b",
    name: "angata",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab4f4675650584112170",
    name: "nanyokie",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab554675650584112175",
    name: "baawa",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bc9c74a8da3390672941",
  },
  {
    id: "60f5ab62467565058411217a",
    name: "waso",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bca674a8da3390672945",
  },
  {
    id: "60f5ab69467565058411217f",
    name: "wamba west",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bca674a8da3390672945",
  },
  {
    id: "60f5ab704675650584112184",
    name: "wamba east",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bca674a8da3390672945",
  },
  {
    id: "60f5ab774675650584112189",
    name: "wamba north",
    countyId: "60f06c5ea26f650268a1f81d",
    constituencyId: "60f0bca674a8da3390672945",
  },
  {
    id: "60f5abd4467565058411218f",
    name: "kapomboi",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcba74a8da3390672949",
  },
  {
    id: "60f5abdc4675650584112194",
    name: "kwanza",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcba74a8da3390672949",
  },
  {
    id: "60f5abe54675650584112199",
    name: "keiyo",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcba74a8da3390672949",
  },
  {
    id: "60f5abe8467565058411219e",
    name: "bidii",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcba74a8da3390672949",
  },
  {
    id: "60f5ac0546756505841121a3",
    name: "chepchoina",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bccd74a8da339067294d",
  },
  {
    id: "60f5ac0a46756505841121a8",
    name: "endebess",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bccd74a8da339067294d",
  },
  {
    id: "60f5ac1046756505841121ad",
    name: "matumbei",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bccd74a8da339067294d",
  },
  {
    id: "60f5ac2046756505841121b2",
    name: "kinyoro",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcd674a8da3390672951",
  },
  {
    id: "60f5ac3546756505841121b7",
    name: "matisi",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcd674a8da3390672951",
  },
  {
    id: "60f5ac3a46756505841121bc",
    name: "tuwani",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcd674a8da3390672951",
  },
  {
    id: "60f5ac4346756505841121c1",
    name: "saboti",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcd674a8da3390672951",
  },
  {
    id: "60f5ac4946756505841121c6",
    name: "machewa",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcd674a8da3390672951",
  },
  {
    id: "60f5ac5446756505841121cb",
    name: "kiminini",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bce374a8da3390672955",
  },
  {
    id: "60f5ac5946756505841121d0",
    name: "waitaluk",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bce374a8da3390672955",
  },
  {
    id: "60f5ac6146756505841121d5",
    name: "sirende",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bce374a8da3390672955",
  },
  {
    id: "60f5ac7946756505841121dc",
    name: "kiminini hospital",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bce374a8da3390672955",
  },
  {
    id: "60f5ac8246756505841121e1",
    name: "sikhendu",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bce374a8da3390672955",
  },
  {
    id: "60f5ac8846756505841121e6",
    name: "nabiswa",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bce374a8da3390672955",
  },
  {
    id: "60f5ac9646756505841121eb",
    name: "sinyerere",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5ac9c46756505841121f0",
    name: "makutano",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5aca246756505841121f5",
    name: "kaplamai",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5aca846756505841121fa",
    name: "motosiet",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5acb046756505841121ff",
    name: "cherangany/ suwerwa",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5acbe4675650584112204",
    name: "chepsiro/ kiptoror",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5acc64675650584112209",
    name: "sitatunga",
    countyId: "60f06c77a26f650268a1f820",
    constituencyId: "60f0bcee74a8da3390672959",
  },
  {
    id: "60f5ad12467565058411220f",
    name: "moi's bridge",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ad1b4675650584112214",
    name: "kapkures",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ad264675650584112219",
    name: "ziwa",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ad2d467565058411221e",
    name: "segero/ barsombe",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ae204675650584112225",
    name: "kipsomba",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ae2c467565058411222a",
    name: "soy",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ae35467565058411222f",
    name: "kuinet/ kapsuswa",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bcff74a8da339067295d",
  },
  {
    id: "60f5ae434675650584112234",
    name: "ngenyilel",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd1e74a8da3390672961",
  },
  {
    id: "60f5ae4a4675650584112239",
    name: "tapsagoi",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd1e74a8da3390672961",
  },
  {
    id: "60f5ae4f467565058411223e",
    name: "kamagut",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd1e74a8da3390672961",
  },
  {
    id: "60f5ae544675650584112243",
    name: "kiplombe",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd1e74a8da3390672961",
  },
  {
    id: "60f5ae594675650584112248",
    name: "kapsaos",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd1e74a8da3390672961",
  },
  {
    id: "60f5ae64467565058411224d",
    name: "huruma",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd1e74a8da3390672961",
  },
  {
    id: "60f5ae7d4675650584112252",
    name: "tembelio",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd2774a8da3390672965",
  },
  {
    id: "60f5ae874675650584112257",
    name: "sergoit",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd2774a8da3390672965",
  },
  {
    id: "60f5ae91467565058411225c",
    name: "karuna/ meibeki",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd2774a8da3390672965",
  },
  {
    id: "60f5ae984675650584112261",
    name: "moiben",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd2774a8da3390672965",
  },
  {
    id: "60f5ae9e4675650584112266",
    name: "kimumu",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd2774a8da3390672965",
  },
  {
    id: "60f5aeae467565058411226b",
    name: "kapsoya",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3074a8da3390672969",
  },
  {
    id: "60f5aeb64675650584112270",
    name: "kaptagat",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3074a8da3390672969",
  },
  {
    id: "60f5aebe4675650584112275",
    name: "ainabkoi/ olare",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3074a8da3390672969",
  },
  {
    id: "60f5aed0467565058411227a",
    name: "simat/ kapseret",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3974a8da339067296d",
  },
  {
    id: "60f5aedc467565058411227f",
    name: "kipkenyo",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3974a8da339067296d",
  },
  {
    id: "60f5aee34675650584112284",
    name: "ngeria",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3974a8da339067296d",
  },
  {
    id: "60f5aee94675650584112289",
    name: "megun",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3974a8da339067296d",
  },
  {
    id: "60f5aef0467565058411228e",
    name: "langas",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd3974a8da339067296d",
  },
  {
    id: "60f5aeff4675650584112293",
    name: "racecourse",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd4474a8da3390672971",
  },
  {
    id: "60f5af084675650584112298",
    name: "cheptiret/ kipchamo",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd4474a8da3390672971",
  },
  {
    id: "60f5af12467565058411229d",
    name: "tulwet/ chuiyat",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd4474a8da3390672971",
  },
  {
    id: "60f5af1a46756505841122a2",
    name: "tarakwa",
    countyId: "60f06c8aa26f650268a1f823",
    constituencyId: "60f0bd4474a8da3390672971",
  },
  {
    id: "60f5af6246756505841122a8",
    name: "kapyego",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd5974a8da3390672975",
  },
  {
    id: "60f5af6946756505841122ad",
    name: "sambirir",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd5974a8da3390672975",
  },
  {
    id: "60f5af6f46756505841122b2",
    name: "endo",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd5974a8da3390672975",
  },
  {
    id: "60f5af7846756505841122b7",
    name: "embobut/ embulot",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd5974a8da3390672975",
  },
  {
    id: "60f5af8d46756505841122bc",
    name: "kapsowar",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6574a8da3390672979",
  },
  {
    id: "60f5af9746756505841122c3",
    name: "sengwer",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6574a8da3390672979",
  },
  {
    id: "60f5afa946756505841122c8",
    name: "cherang'any/ chebororwa",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6574a8da3390672979",
  },
  {
    id: "60f5afba46756505841122cd",
    name: "moiben/ kuserwo",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6574a8da3390672979",
  },
  {
    id: "60f5afbe46756505841122d2",
    name: "arror",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6574a8da3390672979",
  },
  {
    id: "60f5afcb46756505841122d7",
    name: "emsoo",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6e74a8da339067297d",
  },
  {
    id: "60f5b00546756505841122de",
    name: "kamariny",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6e74a8da339067297d",
  },
  {
    id: "60f5b00c46756505841122e3",
    name: "kapchemutwa",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6e74a8da339067297d",
  },
  {
    id: "60f5b01246756505841122e8",
    name: "tambach",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd6e74a8da339067297d",
  },
  {
    id: "60f5b02046756505841122ed",
    name: "kaptarakwa",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd7874a8da3390672981",
  },
  {
    id: "60f5b02546756505841122f2",
    name: "chepkorio",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd7874a8da3390672981",
  },
  {
    id: "60f5b02b46756505841122f7",
    name: "soy north",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd7874a8da3390672981",
  },
  {
    id: "60f5b03146756505841122fc",
    name: "soy south",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd7874a8da3390672981",
  },
  {
    id: "60f5b0374675650584112301",
    name: "kabiemit",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd7874a8da3390672981",
  },
  {
    id: "60f5b03d4675650584112306",
    name: "metkei",
    countyId: "60f06ca3a26f650268a1f826",
    constituencyId: "60f0bd7874a8da3390672981",
  },
  {
    id: "60f5b08a467565058411230c",
    name: "songhor/ soba",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bd9874a8da3390672985",
  },
  {
    id: "60f5b0914675650584112311",
    name: "tindiret",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bd9874a8da3390672985",
  },
  {
    id: "60f5b0d94675650584112316",
    name: "chemelil/ chemase",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bd9874a8da3390672985",
  },
  {
    id: "60f5b0df467565058411231b",
    name: "kapsimotwo",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bd9874a8da3390672985",
  },
  {
    id: "60f5b0f14675650584112320",
    name: "kabwareng",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bda274a8da3390672989",
  },
  {
    id: "60f5b0f74675650584112325",
    name: "terik",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bda274a8da3390672989",
  },
  {
    id: "60f5b0fd467565058411232a",
    name: "kemeloi-maraba",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bda274a8da3390672989",
  },
  {
    id: "60f5b103467565058411232f",
    name: "kobujoi",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bda274a8da3390672989",
  },
  {
    id: "60f5b1094675650584112334",
    name: "kaptumo-kaboi",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bda274a8da3390672989",
  },
  {
    id: "60f5b1104675650584112339",
    name: "koyo-ndurio",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bda274a8da3390672989",
  },
  {
    id: "60f5b123467565058411233e",
    name: "nandi hills",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb074a8da339067298d",
  },
  {
    id: "60f5b1284675650584112343",
    name: "chepkunyuk",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb074a8da339067298d",
  },
  {
    id: "60f5b1344675650584112348",
    name: "ol'lessos",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb074a8da339067298d",
  },
  {
    id: "60f5b13b467565058411234d",
    name: "kapchorua",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb074a8da339067298d",
  },
  {
    id: "60f5b14e4675650584112352",
    name: "chemundu/ kapng'etuny",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb874a8da3390672991",
  },
  {
    id: "60f5b1564675650584112357",
    name: "kosirai",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb874a8da3390672991",
  },
  {
    id: "60f5b1854675650584112361",
    name: "lelmokwo/ ngechek",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb874a8da3390672991",
  },
  {
    id: "60f5b18e4675650584112366",
    name: "kaptel/ kamoiywo",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb874a8da3390672991",
  },
  {
    id: "60f5b194467565058411236b",
    name: "kiptuya",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdb874a8da3390672991",
  },
  {
    id: "60f5b1a14675650584112370",
    name: "chepkumia",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdc974a8da3390672995",
  },
  {
    id: "60f5b1a64675650584112375",
    name: "kapkangani",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdc974a8da3390672995",
  },
  {
    id: "60f5b1ac467565058411237a",
    name: "kapsabet",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdc974a8da3390672995",
  },
  {
    id: "60f5b1b1467565058411237f",
    name: "kilibwoni",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdc974a8da3390672995",
  },
  {
    id: "60f5b1c04675650584112384",
    name: "chepterwai",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5b1c54675650584112389",
    name: "kipkaren",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5b1cc467565058411238e",
    name: "kurgung/ surungai",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5b1d24675650584112393",
    name: "kabiyet",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5b1d74675650584112398",
    name: "ndalat",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5b1dd467565058411239d",
    name: "kabisaga",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5b1e546756505841123a2",
    name: "sangalo/ kebulonik",
    countyId: "60f06cb4a26f650268a1f829",
    constituencyId: "60f0bdd074a8da3390672999",
  },
  {
    id: "60f5bfe32aa184410ca4f85a",
    name: "tirioko",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5bff02aa184410ca4f85f",
    name: "kolowa",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5bff72aa184410ca4f864",
    name: "ribkwo",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5bffd2aa184410ca4f869",
    name: "silale",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5c0012aa184410ca4f86e",
    name: "loiyamorock",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5c0132aa184410ca4f873",
    name: "tangulbei/ korossi",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5c01e2aa184410ca4f878",
    name: "churo/ amaya",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be6c74a8da339067299d",
  },
  {
    id: "60f5c02a2aa184410ca4f87d",
    name: "barwessa",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7374a8da33906729a1",
  },
  {
    id: "60f5c0312aa184410ca4f882",
    name: "kabartonjo",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7374a8da33906729a1",
  },
  {
    id: "60f5c0542aa184410ca4f887",
    name: "saimo/ kipsara man",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7374a8da33906729a1",
  },
  {
    id: "60f5c3312aa184410ca4f88d",
    name: "saimo/ soi",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7374a8da33906729a1",
  },
  {
    id: "60f5c36a2aa184410ca4f892",
    name: "bartabwa",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7374a8da33906729a1",
  },
  {
    id: "60f5c37b2aa184410ca4f897",
    name: "kabarnet",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7a74a8da33906729a5",
  },
  {
    id: "60f5c3812aa184410ca4f89c",
    name: "sacho",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7a74a8da33906729a5",
  },
  {
    id: "60f5c3882aa184410ca4f8a1",
    name: "tenges",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7a74a8da33906729a5",
  },
  {
    id: "60f5c38f2aa184410ca4f8a6",
    name: "ewalel/ chapcha",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7a74a8da33906729a5",
  },
  {
    id: "60f5c3972aa184410ca4f8ab",
    name: "kapropita",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be7a74a8da33906729a5",
  },
  {
    id: "60f5c3a92aa184410ca4f8b0",
    name: "marigat",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8174a8da33906729a9",
  },
  {
    id: "60f5c3af2aa184410ca4f8b5",
    name: "ilchamus",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8174a8da33906729a9",
  },
  {
    id: "60f5c3b42aa184410ca4f8ba",
    name: "mochongoi",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8174a8da33906729a9",
  },
  {
    id: "60f5c3ba2aa184410ca4f8bf",
    name: "mukutani",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8174a8da33906729a9",
  },
  {
    id: "60f5c3ca2aa184410ca4f8c4",
    name: "mogotio",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8774a8da33906729ad",
  },
  {
    id: "60f5c3e12aa184410ca4f8c9",
    name: "emining",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8774a8da33906729ad",
  },
  {
    id: "60f5c3ed2aa184410ca4f8ce",
    name: "kisanana",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8774a8da33906729ad",
  },
  {
    id: "60f5c3fb2aa184410ca4f8d3",
    name: "lembus",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8f74a8da33906729b1",
  },
  {
    id: "60f5c4032aa184410ca4f8d8",
    name: "lembus kwen",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8f74a8da33906729b1",
  },
  {
    id: "60f5c40c2aa184410ca4f8dd",
    name: "ravine",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8f74a8da33906729b1",
  },
  {
    id: "60f5c4142aa184410ca4f8e2",
    name: "mumberes/ maji mazuri",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8f74a8da33906729b1",
  },
  {
    id: "60f5c4202aa184410ca4f8e7",
    name: "lembus/ pekerra",
    countyId: "60f06cc4a26f650268a1f82c",
    constituencyId: "60f0be8f74a8da33906729b1",
  },
  {
    id: "60f6d54218b6e30015c66f51",
    name: "ol-moran",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea074a8da33906729b5",
  },
  {
    id: "60f6d55118b6e30015c66f56",
    name: "rumuruti",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea074a8da33906729b5",
  },
  {
    id: "60f6d56518b6e30015c66f5d",
    name: "laikipia township",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea074a8da33906729b5",
  },
  {
    id: "60f6d59918b6e30015c66f66",
    name: "marmanet",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea074a8da33906729b5",
  },
  {
    id: "60f6d59f18b6e30015c66f6b",
    name: "igwamiti salama",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea074a8da33906729b5",
  },
  {
    id: "60f6d5ac18b6e30015c66f70",
    name: "ngobit",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea974a8da33906729b9",
  },
  {
    id: "60f6d5b418b6e30015c66f75",
    name: "tigithi",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea974a8da33906729b9",
  },
  {
    id: "60f6d5ba18b6e30015c66f7a",
    name: "thingithu",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea974a8da33906729b9",
  },
  {
    id: "60f6d5bf18b6e30015c66f7f",
    name: "nanyuki",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea974a8da33906729b9",
  },
  {
    id: "60f6d5c418b6e30015c66f84",
    name: "umande",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0bea974a8da33906729b9",
  },
  {
    id: "60f6d5d018b6e30015c66f89",
    name: "sosian",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0beb074a8da33906729bd",
  },
  {
    id: "60f6d5d918b6e30015c66f8e",
    name: "segera",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0beb074a8da33906729bd",
  },
  {
    id: "60f6d5df18b6e30015c66f93",
    name: "mugogodo west",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0beb074a8da33906729bd",
  },
  {
    id: "60f6d5e718b6e30015c66f98",
    name: "mugogodo east",
    countyId: "60f06cdaa26f650268a1f82f",
    constituencyId: "60f0beb074a8da33906729bd",
  },
  {
    id: "60f6d65199b3f122bc55e4a5",
    name: "mariashoni",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bec274a8da33906729c1",
  },
  {
    id: "60f6d65899b3f122bc55e4aa",
    name: "elburgon",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bec274a8da33906729c1",
  },
  {
    id: "60f6d66299b3f122bc55e4af",
    name: "turi",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bec274a8da33906729c1",
  },
  {
    id: "60f6d66799b3f122bc55e4b4",
    name: "molo",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bec274a8da33906729c1",
  },
  {
    id: "60f6d67899b3f122bc55e4b9",
    name: "mau narok",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beca74a8da33906729c5",
  },
  {
    id: "60f6d68099b3f122bc55e4be",
    name: "mauche",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beca74a8da33906729c5",
  },
  {
    id: "60f6d68799b3f122bc55e4c3",
    name: "kihingo",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beca74a8da33906729c5",
  },
  {
    id: "60f6d68c99b3f122bc55e4c8",
    name: "nessuit",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beca74a8da33906729c5",
  },
  {
    id: "60f6d69699b3f122bc55e4cd",
    name: "lare",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beca74a8da33906729c5",
  },
  {
    id: "60f6d69c99b3f122bc55e4d2",
    name: "njoro",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beca74a8da33906729c5",
  },
  {
    id: "60f6d6b799b3f122bc55e4d9",
    name: "naivasha biashara",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6c099b3f122bc55e4de",
    name: "hells gate",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6c799b3f122bc55e4e3",
    name: "lake view",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6cd99b3f122bc55e4e8",
    name: "maiella",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6d799b3f122bc55e4ed",
    name: "mai mahiu",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6dc99b3f122bc55e4f2",
    name: "olkaria",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6e999b3f122bc55e4f7",
    name: "naivasha east",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d6ef99b3f122bc55e4fc",
    name: "viwandani",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bed474a8da33906729c9",
  },
  {
    id: "60f6d70299b3f122bc55e501",
    name: "gilgil",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beda74a8da33906729cd",
  },
  {
    id: "60f6d70799b3f122bc55e506",
    name: "elementaita",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beda74a8da33906729cd",
  },
  {
    id: "60f6d71399b3f122bc55e50b",
    name: "mbaruk/ eburu",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beda74a8da33906729cd",
  },
  {
    id: "60f6d71f99b3f122bc55e510",
    name: "malewa west",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beda74a8da33906729cd",
  },
  {
    id: "60f6d72699b3f122bc55e515",
    name: "murindati",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beda74a8da33906729cd",
  },
  {
    id: "60f6d73799b3f122bc55e51a",
    name: "amalo",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bee174a8da33906729d1",
  },
  {
    id: "60f6d73b99b3f122bc55e51f",
    name: "keringet",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bee174a8da33906729d1",
  },
  {
    id: "60f6d74099b3f122bc55e524",
    name: "kiptagich",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bee174a8da33906729d1",
  },
  {
    id: "60f6d74599b3f122bc55e529",
    name: "tinet",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bee174a8da33906729d1",
  },
  {
    id: "60f6d75399b3f122bc55e52e",
    name: "kiptororo",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beea74a8da33906729d5",
  },
  {
    id: "60f6d75899b3f122bc55e533",
    name: "nyota",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beea74a8da33906729d5",
  },
  {
    id: "60f6d75e99b3f122bc55e538",
    name: "sirikwa",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beea74a8da33906729d5",
  },
  {
    id: "60f6d76399b3f122bc55e53d",
    name: "kamara",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0beea74a8da33906729d5",
  },
  {
    id: "60f6d77099b3f122bc55e542",
    name: "subukia",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef174a8da33906729d9",
  },
  {
    id: "60f6d77599b3f122bc55e547",
    name: "waseges",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef174a8da33906729d9",
  },
  {
    id: "60f6d77a99b3f122bc55e54c",
    name: "kabazi",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef174a8da33906729d9",
  },
  {
    id: "60f6d78a99b3f122bc55e551",
    name: "menengai west",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef974a8da33906729dd",
  },
  {
    id: "60f6d79099b3f122bc55e556",
    name: "soin",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef974a8da33906729dd",
  },
  {
    id: "60f6d79699b3f122bc55e55b",
    name: "visoi",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef974a8da33906729dd",
  },
  {
    id: "60f6d79b99b3f122bc55e560",
    name: "mosop",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef974a8da33906729dd",
  },
  {
    id: "60f6d7a099b3f122bc55e565",
    name: "solai",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bef974a8da33906729dd",
  },
  {
    id: "60f6d7b199b3f122bc55e56a",
    name: "dundori",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0befe74a8da33906729e1",
  },
  {
    id: "60f6d7b999b3f122bc55e56f",
    name: "kabatini",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0befe74a8da33906729e1",
  },
  {
    id: "60f6d7bf99b3f122bc55e574",
    name: "kiamaina",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0befe74a8da33906729e1",
  },
  {
    id: "60f6d7c699b3f122bc55e579",
    name: "lanet/ umoja",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0befe74a8da33906729e1",
  },
  {
    id: "60f6d7cf99b3f122bc55e57e",
    name: "bahati",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0befe74a8da33906729e1",
  },
  {
    id: "60f6d7e099b3f122bc55e583",
    name: "barut",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf0674a8da33906729e5",
  },
  {
    id: "60f6d7e499b3f122bc55e588",
    name: "london",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf0674a8da33906729e5",
  },
  {
    id: "60f6d7eb99b3f122bc55e58d",
    name: "kaptembwo",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf0674a8da33906729e5",
  },
  {
    id: "60f6d7f699b3f122bc55e594",
    name: "rhoda",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf0674a8da33906729e5",
  },
  {
    id: "60f6d7fc99b3f122bc55e599",
    name: "shaabab",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf0674a8da33906729e5",
  },
  {
    id: "60f6d81e99b3f122bc55e5a0",
    name: "nakuru biashara",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf1174a8da33906729e9",
  },
  {
    id: "60f6d82399b3f122bc55e5a5",
    name: "kivumbini",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf1174a8da33906729e9",
  },
  {
    id: "60f6d82899b3f122bc55e5aa",
    name: "flamingo",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf1174a8da33906729e9",
  },
  {
    id: "60f6d82f99b3f122bc55e5af",
    name: "menengai",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf1174a8da33906729e9",
  },
  {
    id: "60f6d83599b3f122bc55e5b4",
    name: "nakuru east",
    countyId: "60f06ce5a26f650268a1f832",
    constituencyId: "60f0bf1174a8da33906729e9",
  },
  {
    id: "60f6dbc68a6fc3333c4e3ee7",
    name: "kilgoris central",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf2674a8da33906729ed",
  },
  {
    id: "60f6dbd08a6fc3333c4e3eec",
    name: "keyian",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf2674a8da33906729ed",
  },
  {
    id: "60f6dbd58a6fc3333c4e3ef1",
    name: "angata barikoi",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf2674a8da33906729ed",
  },
  {
    id: "60f6dbda8a6fc3333c4e3ef6",
    name: "shankoe",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf2674a8da33906729ed",
  },
  {
    id: "60f6dbdf8a6fc3333c4e3efb",
    name: "kimintet",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf2674a8da33906729ed",
  },
  {
    id: "60f6dbe48a6fc3333c4e3f00",
    name: "lolgorian",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf2674a8da33906729ed",
  },
  {
    id: "60f6dbf58a6fc3333c4e3f05",
    name: "ilkerin",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3274a8da33906729f1",
  },
  {
    id: "60f6dbfb8a6fc3333c4e3f0a",
    name: "ololmasani",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3274a8da33906729f1",
  },
  {
    id: "60f6dc008a6fc3333c4e3f0f",
    name: "mogondo",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3274a8da33906729f1",
  },
  {
    id: "60f6dc068a6fc3333c4e3f14",
    name: "kapsasian",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3274a8da33906729f1",
  },
  {
    id: "60f6dc148a6fc3333c4e3f19",
    name: "olpusimoru",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3a74a8da33906729f5",
  },
  {
    id: "60f6dc198a6fc3333c4e3f1e",
    name: "olokurto",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3a74a8da33906729f5",
  },
  {
    id: "60f6dc1e8a6fc3333c4e3f23",
    name: "narok town",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3a74a8da33906729f5",
  },
  {
    id: "60f6dc8d8a6fc3333c4e3f28",
    name: "nkareta",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3a74a8da33906729f5",
  },
  {
    id: "60f6dc928a6fc3333c4e3f2d",
    name: "olorropil",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3a74a8da33906729f5",
  },
  {
    id: "60f6dc9f8a6fc3333c4e3f32",
    name: "melili",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf3a74a8da33906729f5",
  },
  {
    id: "60f6dcc78a6fc3333c4e3f37",
    name: "mosiro",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4174a8da33906729f9",
  },
  {
    id: "60f6dccd8a6fc3333c4e3f3c",
    name: "ildamat",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4174a8da33906729f9",
  },
  {
    id: "60f6dcd28a6fc3333c4e3f41",
    name: "keekonyokie",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4174a8da33906729f9",
  },
  {
    id: "60f6dcd68a6fc3333c4e3f46",
    name: "suswa",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4174a8da33906729f9",
  },
  {
    id: "60f6dce88a6fc3333c4e3f4b",
    name: "majimoto/ naroos",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4874a8da33906729fd",
  },
  {
    id: "60f6dcf38a6fc3333c4e3f50",
    name: "uraololulung'a",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4874a8da33906729fd",
  },
  {
    id: "60f6dcfa8a6fc3333c4e3f55",
    name: "melelo",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4874a8da33906729fd",
  },
  {
    id: "60f6dcff8a6fc3333c4e3f5a",
    name: "loita",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4874a8da33906729fd",
  },
  {
    id: "60f6dd048a6fc3333c4e3f5f",
    name: "sogoo",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4874a8da33906729fd",
  },
  {
    id: "60f6dd098a6fc3333c4e3f64",
    name: "sagamian",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4874a8da33906729fd",
  },
  {
    id: "60f6dd158a6fc3333c4e3f69",
    name: "ilmotiok",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4f74a8da3390672a01",
  },
  {
    id: "60f6dd1a8a6fc3333c4e3f6e",
    name: "mara",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4f74a8da3390672a01",
  },
  {
    id: "60f6dd1f8a6fc3333c4e3f73",
    name: "siana",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4f74a8da3390672a01",
  },
  {
    id: "60f6dd248a6fc3333c4e3f78",
    name: "naikarra",
    countyId: "60f06cf0a26f650268a1f835",
    constituencyId: "60f0bf4f74a8da3390672a01",
  },
  {
    id: "60f6f62a4960a627acbf6f1c",
    name: "olkeri",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf6c74a8da3390672a05",
  },
  {
    id: "60f6f62e4960a627acbf6f21",
    name: "ongata rongai",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf6c74a8da3390672a05",
  },
  {
    id: "60f6f6334960a627acbf6f26",
    name: "nkaimurunya",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf6c74a8da3390672a05",
  },
  {
    id: "60f6f63d4960a627acbf6f2b",
    name: "oloolua",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf6c74a8da3390672a05",
  },
  {
    id: "60f6f6454960a627acbf6f30",
    name: "ngong",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf6c74a8da3390672a05",
  },
  {
    id: "60f6f6514960a627acbf6f35",
    name: "purko",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7374a8da3390672a09",
  },
  {
    id: "60f6f6c34960a627acbf6f40",
    name: "ildamat.",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7374a8da3390672a09",
  },
  {
    id: "60f6f6d14960a627acbf6f45",
    name: "dalalekutuk",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7374a8da3390672a09",
  },
  {
    id: "60f6f6df4960a627acbf6f4a",
    name: "matapato north",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7374a8da3390672a09",
  },
  {
    id: "60f6f6e54960a627acbf6f4f",
    name: "matapato south",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7374a8da3390672a09",
  },
  {
    id: "60f6f6ee4960a627acbf6f54",
    name: "kaputiei north",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7874a8da3390672a0d",
  },
  {
    id: "60f6f6f34960a627acbf6f59",
    name: "kitengela",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7874a8da3390672a0d",
  },
  {
    id: "60f6f71e4960a627acbf6f60",
    name: "oloosirkon/ sholinke",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7874a8da3390672a0d",
  },
  {
    id: "60f6f7254960a627acbf6f65",
    name: "kenyawa-poka",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7874a8da3390672a0d",
  },
  {
    id: "60f6f72c4960a627acbf6f6a",
    name: "imaroro",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7874a8da3390672a0d",
  },
  {
    id: "60f6f7514960a627acbf6f71",
    name: "iloodokilani",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7f74a8da3390672a11",
  },
  {
    id: "60f6f7574960a627acbf6f76",
    name: "magadi",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7f74a8da3390672a11",
  },
  {
    id: "60f6f7674960a627acbf6f7b",
    name: "ewuaso oonkidong'i",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7f74a8da3390672a11",
  },
  {
    id: "60f6f7834960a627acbf6f86",
    name: "mosiro.",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf7f74a8da3390672a11",
  },
  {
    id: "60f6f7974960a627acbf6f8b",
    name: "entonet/ lenkisi",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf8574a8da3390672a15",
  },
  {
    id: "60f6f7a64960a627acbf6f90",
    name: "mbirikani/ eselen",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf8574a8da3390672a15",
  },
  {
    id: "60f6f7ab4960a627acbf6f95",
    name: "keikuku",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf8574a8da3390672a15",
  },
  {
    id: "60f6f7b34960a627acbf6f9a",
    name: "rombo",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf8574a8da3390672a15",
  },
  {
    id: "60f6f7b94960a627acbf6f9f",
    name: "kimana",
    countyId: "60f06cfea26f650268a1f838",
    constituencyId: "60f0bf8574a8da3390672a15",
  },
  {
    id: "60f6f8c54960a627acbf6fa5",
    name: "londiani",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bf9d74a8da3390672a19",
  },
  {
    id: "60f6f8d54960a627acbf6faa",
    name: "kedowa/ kimugul",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bf9d74a8da3390672a19",
  },
  {
    id: "60f6f8da4960a627acbf6faf",
    name: "chepseon",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bf9d74a8da3390672a19",
  },
  {
    id: "60f6f8e44960a627acbf6fb4",
    name: "tendeno/ sorget",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bf9d74a8da3390672a19",
  },
  {
    id: "60f6f8f44960a627acbf6fb9",
    name: "kunyak",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfa474a8da3390672a1d",
  },
  {
    id: "60f6f8fa4960a627acbf6fbe",
    name: "kamasian",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfa474a8da3390672a1d",
  },
  {
    id: "60f6f8ff4960a627acbf6fc3",
    name: "kipkelion",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfa474a8da3390672a1d",
  },
  {
    id: "60f6f9044960a627acbf6fc8",
    name: "chilchila",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfa474a8da3390672a1d",
  },
  {
    id: "60f6f9184960a627acbf6fcd",
    name: "kapsoit",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfab74a8da3390672a21",
  },
  {
    id: "60f6f91b4960a627acbf6fd2",
    name: "ainamoi",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfab74a8da3390672a21",
  },
  {
    id: "60f6f9244960a627acbf6fd7",
    name: "kipchebor",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfab74a8da3390672a21",
  },
  {
    id: "60f6f92f4960a627acbf6fdc",
    name: "kapkugerwet",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfab74a8da3390672a21",
  },
  {
    id: "60f6f9344960a627acbf6fe1",
    name: "kipchimchim",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfab74a8da3390672a21",
  },
  {
    id: "60f6f9a54960a627acbf6ff3",
    name: "kapsaos.",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfab74a8da3390672a21",
  },
  {
    id: "60f6f9c44960a627acbf6ff8",
    name: "kisiara",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9ca4960a627acbf6ffd",
    name: "tebesonik",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9cf4960a627acbf7002",
    name: "cheboin",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9d54960a627acbf7007",
    name: "chemosot",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9db4960a627acbf700c",
    name: "litein",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9e14960a627acbf7011",
    name: "cheplanget",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9e84960a627acbf7016",
    name: "kapkatet",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb174a8da3390672a25",
  },
  {
    id: "60f6f9f84960a627acbf701b",
    name: "waldai",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb974a8da3390672a29",
  },
  {
    id: "60f6f9fd4960a627acbf7020",
    name: "kabianga",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb974a8da3390672a29",
  },
  {
    id: "60f6fa054960a627acbf7025",
    name: "cheptororiet/ seretut",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb974a8da3390672a29",
  },
  {
    id: "60f6fa0e4960a627acbf702a",
    name: "chaik",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb974a8da3390672a29",
  },
  {
    id: "60f6fa134960a627acbf702f",
    name: "kapsuser",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfb974a8da3390672a29",
  },
  {
    id: "60f6fa214960a627acbf7034",
    name: "sigowet",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfc374a8da3390672a2d",
  },
  {
    id: "60f6fa2b4960a627acbf7039",
    name: "kaplelartet",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfc374a8da3390672a2d",
  },
  {
    id: "60f6fa364960a627acbf703e",
    name: "soliat",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfc374a8da3390672a2d",
  },
  {
    id: "60f6fabe18d09c08e4165cd2",
    name: "soin.",
    countyId: "60f06d13a26f650268a1f83b",
    constituencyId: "60f0bfc374a8da3390672a2d",
  },
  {
    id: "60f6fb1a18d09c08e4165cd8",
    name: "ndanai/ abosi",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfd574a8da3390672a31",
  },
  {
    id: "60f6fb2518d09c08e4165cdd",
    name: "chemagel",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfd574a8da3390672a31",
  },
  {
    id: "60f6fb2b18d09c08e4165ce2",
    name: "kipsonoi",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfd574a8da3390672a31",
  },
  {
    id: "60f6fb2f18d09c08e4165ce7",
    name: "apletundo",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfd574a8da3390672a31",
  },
  {
    id: "60f6fb4018d09c08e4165cec",
    name: "rongena/ manaret",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfd574a8da3390672a31",
  },
  {
    id: "60f6fb5318d09c08e4165cf1",
    name: "kong'asis",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfdc74a8da3390672a35",
  },
  {
    id: "60f6fb5c18d09c08e4165cf6",
    name: "nyangores",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfdc74a8da3390672a35",
  },
  {
    id: "60f6fb6118d09c08e4165cfb",
    name: "sigor",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfdc74a8da3390672a35",
  },
  {
    id: "60f6fb6618d09c08e4165d00",
    name: "chebunyo",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfdc74a8da3390672a35",
  },
  {
    id: "60f6fb6b18d09c08e4165d05",
    name: "siongiroi",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfdc74a8da3390672a35",
  },
  {
    id: "60f6fb7718d09c08e4165d0a",
    name: "merigi",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe174a8da3390672a39",
  },
  {
    id: "60f6fb7c18d09c08e4165d0f",
    name: "kembu",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe174a8da3390672a39",
  },
  {
    id: "60f6fbad18d09c08e4165d14",
    name: "longisa",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe174a8da3390672a39",
  },
  {
    id: "60f6fbb318d09c08e4165d19",
    name: "kipreres",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe174a8da3390672a39",
  },
  {
    id: "60f6fbb818d09c08e4165d1e",
    name: "chemaner",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe174a8da3390672a39",
  },
  {
    id: "60f6fbc418d09c08e4165d23",
    name: "silibwet township",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe774a8da3390672a3d",
  },
  {
    id: "60f6fbca18d09c08e4165d28",
    name: "ndaraweta",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe774a8da3390672a3d",
  },
  {
    id: "60f6fbce18d09c08e4165d2d",
    name: "singorwet",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe774a8da3390672a3d",
  },
  {
    id: "60f6fbd318d09c08e4165d32",
    name: "chesoen",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe774a8da3390672a3d",
  },
  {
    id: "60f6fbd718d09c08e4165d37",
    name: "mutarakwa",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfe774a8da3390672a3d",
  },
  {
    id: "60f6fbe318d09c08e4165d3c",
    name: "chepchabas",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfed74a8da3390672a41",
  },
  {
    id: "60f6fbe818d09c08e4165d41",
    name: "kimulot",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfed74a8da3390672a41",
  },
  {
    id: "60f6fbee18d09c08e4165d46",
    name: "mogogosiek",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfed74a8da3390672a41",
  },
  {
    id: "60f6fbf218d09c08e4165d4b",
    name: "boito",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfed74a8da3390672a41",
  },
  {
    id: "60f6fbf718d09c08e4165d50",
    name: "embomos",
    countyId: "60f06d1fa26f650268a1f83e",
    constituencyId: "60f0bfed74a8da3390672a41",
  },
  {
    id: "60f7df4fa942e62ec43dbb56",
    name: "mautuma",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0bffc74a8da3390672a45",
  },
  {
    id: "60f7e4ae1e35371514ae8efb",
    name: "lugari",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0bffc74a8da3390672a45",
  },
  {
    id: "60f7e4dc1e35371514ae8f04",
    name: "lumakanda",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0bffc74a8da3390672a45",
  },
  {
    id: "60f7e4e21e35371514ae8f09",
    name: "chekalini",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0bffc74a8da3390672a45",
  },
  {
    id: "60f7e4e71e35371514ae8f0e",
    name: "chevaywa",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0bffc74a8da3390672a45",
  },
  {
    id: "60f7e4ee1e35371514ae8f13",
    name: "lawandeti",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0bffc74a8da3390672a45",
  },
  {
    id: "60f7e4fc1e35371514ae8f18",
    name: "likuyani",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c00b74a8da3390672a49",
  },
  {
    id: "60f7e5081e35371514ae8f1d",
    name: "sango",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c00b74a8da3390672a49",
  },
  {
    id: "60f7e50d1e35371514ae8f22",
    name: "kongoni",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c00b74a8da3390672a49",
  },
  {
    id: "60f7e5151e35371514ae8f27",
    name: "nzoia",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c00b74a8da3390672a49",
  },
  {
    id: "60f7e51a1e35371514ae8f2c",
    name: "sinoko",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c00b74a8da3390672a49",
  },
  {
    id: "60f7e5271e35371514ae8f31",
    name: "west kabras",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e52f1e35371514ae8f36",
    name: "chemuche east",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e5351e35371514ae8f3b",
    name: "kabras",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e53c1e35371514ae8f40",
    name: "butali/ chegulo",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e5431e35371514ae8f45",
    name: "manda-shivanga",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e5491e35371514ae8f4a",
    name: "shirugu-mugai",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e5541e35371514ae8f4f",
    name: "south kabras",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02074a8da3390672a4d",
  },
  {
    id: "60f7e5671e35371514ae8f54",
    name: "butsotso east",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02574a8da3390672a51",
  },
  {
    id: "60f7e56e1e35371514ae8f59",
    name: "butsotso south",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02574a8da3390672a51",
  },
  {
    id: "60f7e5741e35371514ae8f5e",
    name: "butsotso central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02574a8da3390672a51",
  },
  {
    id: "60f7e5821e35371514ae8f63",
    name: "sheywe",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02574a8da3390672a51",
  },
  {
    id: "60f7e59f1e35371514ae8f69",
    name: "mahiakalo",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02574a8da3390672a51",
  },
  {
    id: "60f7e5a61e35371514ae8f6e",
    name: "shirere",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02574a8da3390672a51",
  },
  {
    id: "60f7e5b41e35371514ae8f73",
    name: "ingostse-mathia",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02a74a8da3390672a55",
  },
  {
    id: "60f7e5b91e35371514ae8f78",
    name: "shinoyi-shikomari",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02a74a8da3390672a55",
  },
  {
    id: "60f7e5c01e35371514ae8f7d",
    name: "esumeyia",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02a74a8da3390672a55",
  },
  {
    id: "60f7e5c71e35371514ae8f82",
    name: "bunyala west",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02a74a8da3390672a55",
  },
  {
    id: "60f7e5cd1e35371514ae8f87",
    name: "bunyal east",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02a74a8da3390672a55",
  },
  {
    id: "60f7e5da1e35371514ae8f8c",
    name: "bunyala central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c02a74a8da3390672a55",
  },
  {
    id: "60f7e5f21e35371514ae8f91",
    name: "mumias central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03274a8da3390672a59",
  },
  {
    id: "60f7e5f91e35371514ae8f96",
    name: "mumias north",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03274a8da3390672a59",
  },
  {
    id: "60f7e5ff1e35371514ae8f9b",
    name: "etenje",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03274a8da3390672a59",
  },
  {
    id: "60f7e6041e35371514ae8fa0",
    name: "musanda",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03274a8da3390672a59",
  },
  {
    id: "60f7e61f1e35371514ae8fa5",
    name: "lusheya/ lubinu",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03874a8da3390672a5d",
  },
  {
    id: "60f7e6261e35371514ae8faa",
    name: "malaha/ isongo/ makunga",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03874a8da3390672a5d",
  },
  {
    id: "60f7e62d1e35371514ae8faf",
    name: "east wanga",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03874a8da3390672a5d",
  },
  {
    id: "60f7e6431e35371514ae8fb4",
    name: "koyonzo",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03f74a8da3390672a61",
  },
  {
    id: "60f7e6481e35371514ae8fb9",
    name: "kholera",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03f74a8da3390672a61",
  },
  {
    id: "60f7e64e1e35371514ae8fbe",
    name: "khalaba",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03f74a8da3390672a61",
  },
  {
    id: "60f7e6531e35371514ae8fc3",
    name: "mayoni",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03f74a8da3390672a61",
  },
  {
    id: "60f7e6581e35371514ae8fc8",
    name: "namamali",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c03f74a8da3390672a61",
  },
  {
    id: "60f7e6671e35371514ae8fcd",
    name: "marama west",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04774a8da3390672a65",
  },
  {
    id: "60f7e6721e35371514ae8fd2",
    name: "marama central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04774a8da3390672a65",
  },
  {
    id: "60f7e67a1e35371514ae8fd7",
    name: "marenyo-shianda",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04774a8da3390672a65",
  },
  {
    id: "60f7e6801e35371514ae8fdc",
    name: "maram north",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04774a8da3390672a65",
  },
  {
    id: "60f7e6861e35371514ae8fe1",
    name: "marama south",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04774a8da3390672a65",
  },
  {
    id: "60f7e6981e35371514ae8fe6",
    name: "kisa north",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04d74a8da3390672a69",
  },
  {
    id: "60f7e6a01e35371514ae8feb",
    name: "kisa east",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04d74a8da3390672a69",
  },
  {
    id: "60f7e6a51e35371514ae8ff0",
    name: "kisa west",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04d74a8da3390672a69",
  },
  {
    id: "60f7e6af1e35371514ae8ff5",
    name: "kisa central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c04d74a8da3390672a69",
  },
  {
    id: "60f7e6be1e35371514ae8ffa",
    name: "isukha north",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05474a8da3390672a6d",
  },
  {
    id: "60f7e6c51e35371514ae8fff",
    name: "murhanda",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05474a8da3390672a6d",
  },
  {
    id: "60f7e6c91e35371514ae9004",
    name: "isukha central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05474a8da3390672a6d",
  },
  {
    id: "60f7e6da1e35371514ae9009",
    name: "isukha south",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05474a8da3390672a6d",
  },
  {
    id: "60f7e6de1e35371514ae900e",
    name: "isukha east",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05474a8da3390672a6d",
  },
  {
    id: "60f7e6e31e35371514ae9013",
    name: "isukha west",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05474a8da3390672a6d",
  },
  {
    id: "60f7e6f21e35371514ae9018",
    name: "idakho south",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05b74a8da3390672a71",
  },
  {
    id: "60f7e6fc1e35371514ae901d",
    name: "idakho east",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05b74a8da3390672a71",
  },
  {
    id: "60f7e7031e35371514ae9022",
    name: "idakho north",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05b74a8da3390672a71",
  },
  {
    id: "60f7e7091e35371514ae9027",
    name: "idakho central",
    countyId: "60f06d8ca26f650268a1f845",
    constituencyId: "60f0c05b74a8da3390672a71",
  },
  {
    id: "60f7ec7a831ef10bec9775be",
    name: "lugaga-wamuluma",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09174a8da3390672a75",
  },
  {
    id: "60f7ec80831ef10bec9775c3",
    name: "south maragoli",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09174a8da3390672a75",
  },
  {
    id: "60f7ec88831ef10bec9775c8",
    name: "central maragoli",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09174a8da3390672a75",
  },
  {
    id: "60f7ec8d831ef10bec9775cd",
    name: "mungoma",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09174a8da3390672a75",
  },
  {
    id: "60f7ec9d831ef10bec9775d2",
    name: "lyaduywa/ izava",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09774a8da3390672a79",
  },
  {
    id: "60f7eca2831ef10bec9775d7",
    name: "west sabatia",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09774a8da3390672a79",
  },
  {
    id: "60f7eca9831ef10bec9775dc",
    name: "chavakali",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09774a8da3390672a79",
  },
  {
    id: "60f7ecae831ef10bec9775e1",
    name: "north maragoli",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09774a8da3390672a79",
  },
  {
    id: "60f7ecb5831ef10bec9775e6",
    name: "wodanga",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09774a8da3390672a79",
  },
  {
    id: "60f7ecc2831ef10bec9775eb",
    name: "busali",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09774a8da3390672a79",
  },
  {
    id: "60f7ecce831ef10bec9775f0",
    name: "shiru",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7ecd6831ef10bec9775f5",
    name: "gisambai",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7ecdb831ef10bec9775fa",
    name: "shamakhokho",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7ece1831ef10bec9775ff",
    name: "banja",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7ece6831ef10bec977604",
    name: "muhudi",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7eced831ef10bec977609",
    name: "tambaa",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7ecf3831ef10bec97760e",
    name: "jepkoyai",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c09d74a8da3390672a7d",
  },
  {
    id: "60f7ed00831ef10bec977613",
    name: "luanda township",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0a274a8da3390672a81",
  },
  {
    id: "60f7ed06831ef10bec977618",
    name: "wemilabi",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0a274a8da3390672a81",
  },
  {
    id: "60f7ed0c831ef10bec97761d",
    name: "mwibona",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0a274a8da3390672a81",
  },
  {
    id: "60f7ed12831ef10bec977622",
    name: "luanda south",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0a274a8da3390672a81",
  },
  {
    id: "60f7ed18831ef10bec977627",
    name: "emabungo",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0a274a8da3390672a81",
  },
  {
    id: "60f7ed26831ef10bec97762c",
    name: "north east bunyore",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0aa74a8da3390672a85",
  },
  {
    id: "60f7ed2d831ef10bec977631",
    name: "central bunyore",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0aa74a8da3390672a85",
  },
  {
    id: "60f7ed33831ef10bec977636",
    name: "west bunyore",
    countyId: "60f06d9ca26f650268a1f848",
    constituencyId: "60f0c0aa74a8da3390672a85",
  },
  {
    id: "60f7ed6f831ef10bec97763c",
    name: "cheptais",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0c874a8da3390672a89",
  },
  {
    id: "60f7ed76831ef10bec977641",
    name: "chesikaki",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0c874a8da3390672a89",
  },
  {
    id: "60f7ed7d831ef10bec977646",
    name: "chepyuk",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0c874a8da3390672a89",
  },
  {
    id: "60f7ed84831ef10bec97764b",
    name: "kapkateny",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0c874a8da3390672a89",
  },
  {
    id: "60f7ed88831ef10bec977650",
    name: "kaptama",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0c874a8da3390672a89",
  },
  {
    id: "60f7ed8e831ef10bec977655",
    name: "elgon",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0c874a8da3390672a89",
  },
  {
    id: "60f7ed99831ef10bec97765a",
    name: "namwela",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d074a8da3390672a8d",
  },
  {
    id: "60f7eda5831ef10bec97765f",
    name: "malakisi/ south kulisiru",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d074a8da3390672a8d",
  },
  {
    id: "60f7edac831ef10bec977664",
    name: "lwandanyi",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d074a8da3390672a8d",
  },
  {
    id: "60f7edba831ef10bec977669",
    name: "kabuchai/ chwele",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d674a8da3390672a91",
  },
  {
    id: "60f7edc1831ef10bec97766e",
    name: "west nalondo",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d674a8da3390672a91",
  },
  {
    id: "60f7edc7831ef10bec977673",
    name: "bwake/ luuya",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d674a8da3390672a91",
  },
  {
    id: "60f7edd0831ef10bec977678",
    name: "mukuyuni",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d674a8da3390672a91",
  },
  {
    id: "60f7eddd831ef10bec97767d",
    name: "south bukusu",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0d674a8da3390672a91",
  },
  {
    id: "60f7edea831ef10bec977682",
    name: "bumula",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0ea74a8da3390672a97",
  },
  {
    id: "60f7edef831ef10bec977687",
    name: "khasoko",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0ea74a8da3390672a97",
  },
  {
    id: "60f7edf4831ef10bec97768c",
    name: "kabula",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0ea74a8da3390672a97",
  },
  {
    id: "60f7edfa831ef10bec977691",
    name: "kimaeti",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0ea74a8da3390672a97",
  },
  {
    id: "60f7ee0e831ef10bec977698",
    name: "south bukusu.",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0ea74a8da3390672a97",
  },
  {
    id: "60f7ee15831ef10bec97769d",
    name: "siboti",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c0ea74a8da3390672a97",
  },
  {
    id: "60f7ee23831ef10bec9776a2",
    name: "bukembe west",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee29831ef10bec9776a7",
    name: "bukembe east",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee3e831ef10bec9776ac",
    name: "bungoma township",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee4a831ef10bec9776b3",
    name: "musikoma",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee54831ef10bec9776b8",
    name: "east snag'alo",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee5a831ef10bec9776bd",
    name: "marakatu",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee5e831ef10bec9776c2",
    name: "tuuti",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee6a831ef10bec9776c7",
    name: "west sang'alo",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10574a8da3390672a9b",
  },
  {
    id: "60f7ee79831ef10bec9776cc",
    name: "mihuu",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10c74a8da3390672a9f",
  },
  {
    id: "60f7ee7e831ef10bec9776d1",
    name: "ndivisi",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10c74a8da3390672a9f",
  },
  {
    id: "60f7ee84831ef10bec9776d6",
    name: "maraka",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c10c74a8da3390672a9f",
  },
  {
    id: "60f7ee90831ef10bec9776db",
    name: "sitikho",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11274a8da3390672aa3",
  },
  {
    id: "60f7ee95831ef10bec9776e0",
    name: "matulo",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11274a8da3390672aa3",
  },
  {
    id: "60f7ee9b831ef10bec9776e5",
    name: "bokoli",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11274a8da3390672aa3",
  },
  {
    id: "60f7eea7831ef10bec9776ea",
    name: "kibingei",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11a74a8da3390672aa7",
  },
  {
    id: "60f7eead831ef10bec9776ef",
    name: "kimilili",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11a74a8da3390672aa7",
  },
  {
    id: "60f7eeb2831ef10bec9776f4",
    name: "maeni",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11a74a8da3390672aa7",
  },
  {
    id: "60f7eeb7831ef10bec9776f9",
    name: "kamukuywa",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c11a74a8da3390672aa7",
  },
  {
    id: "60f7eec3831ef10bec9776fe",
    name: "mbakalo",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c12074a8da3390672aab",
  },
  {
    id: "60f7eeca831ef10bec977703",
    name: "naitiri/ kabuyefwe",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c12074a8da3390672aab",
  },
  {
    id: "60f7eed1831ef10bec977708",
    name: "milima",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c12074a8da3390672aab",
  },
  {
    id: "60f7eed9831ef10bec97770d",
    name: "ndalu/ tabani",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c12074a8da3390672aab",
  },
  {
    id: "60f7eee1831ef10bec977712",
    name: "tongaren",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c12074a8da3390672aab",
  },
  {
    id: "60f7eee6831ef10bec977717",
    name: "soysambu/ mitua",
    countyId: "60f06da9a26f650268a1f84b",
    constituencyId: "60f0c12074a8da3390672aab",
  },
  {
    id: "60f7ef44831ef10bec97771d",
    name: "malaba central",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13374a8da3390672aaf",
  },
  {
    id: "60f7ef4b831ef10bec977722",
    name: "malaba north",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13374a8da3390672aaf",
  },
  {
    id: "60f7ef55831ef10bec977727",
    name: "ang'urai south",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13374a8da3390672aaf",
  },
  {
    id: "60f7ef5b831ef10bec97772c",
    name: "malaba south",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13374a8da3390672aaf",
  },
  {
    id: "60f7ef64831ef10bec977731",
    name: "ang'urai north",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13374a8da3390672aaf",
  },
  {
    id: "60f7ef6e831ef10bec977736",
    name: "ang'urai east",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13374a8da3390672aaf",
  },
  {
    id: "60f7ef7d831ef10bec97773b",
    name: "ang'orom",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13b74a8da3390672ab3",
  },
  {
    id: "60f7ef87831ef10bec977740",
    name: "chakoi south",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13b74a8da3390672ab3",
  },
  {
    id: "60f7ef8c831ef10bec977745",
    name: "amukura central",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13b74a8da3390672ab3",
  },
  {
    id: "60f7ef93831ef10bec97774a",
    name: "chakoi north",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13b74a8da3390672ab3",
  },
  {
    id: "60f7ef99831ef10bec97774f",
    name: "amukura east",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13b74a8da3390672ab3",
  },
  {
    id: "60f7efa1831ef10bec977754",
    name: "amukura west",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c13b74a8da3390672ab3",
  },
  {
    id: "60f7efaa831ef10bec977759",
    name: "nambale township",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14274a8da3390672ab7",
  },
  {
    id: "60f7efb7831ef10bec97775e",
    name: "bukhayo north/ waltsi",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14274a8da3390672ab7",
  },
  {
    id: "60f7efbf831ef10bec977763",
    name: "bukhayo east",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14274a8da3390672ab7",
  },
  {
    id: "60f7efc9831ef10bec977768",
    name: "bukhayo central",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14274a8da3390672ab7",
  },
  {
    id: "60f7efd9831ef10bec97776d",
    name: "bukhayo west",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14774a8da3390672abb",
  },
  {
    id: "60f7efea831ef10bec977772",
    name: "mayenje",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14774a8da3390672abb",
  },
  {
    id: "60f7f02f831ef10bec977777",
    name: "matayos south",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14774a8da3390672abb",
  },
  {
    id: "60f7f034831ef10bec97777c",
    name: "busibwabo",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14774a8da3390672abb",
  },
  {
    id: "60f7f03e831ef10bec977781",
    name: "burumba",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14774a8da3390672abb",
  },
  {
    id: "60f7f0a1831ef10bec977786",
    name: "marachi west",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14d74a8da3390672abf",
  },
  {
    id: "60f7f0a5831ef10bec97778b",
    name: "kingandole",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14d74a8da3390672abf",
  },
  {
    id: "60f7f0ac831ef10bec977790",
    name: "marachi central",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14d74a8da3390672abf",
  },
  {
    id: "60f7f0b2831ef10bec977795",
    name: "marachi east",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14d74a8da3390672abf",
  },
  {
    id: "60f7f0b8831ef10bec97779a",
    name: "marachi north",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14d74a8da3390672abf",
  },
  {
    id: "60f7f0bf831ef10bec97779f",
    name: "elugulu",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c14d74a8da3390672abf",
  },
  {
    id: "60f7f118831ef10bec9777a4",
    name: "namboboto nambuku",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15374a8da3390672ac3",
  },
  {
    id: "60f7f11c831ef10bec9777a9",
    name: "nangina",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15374a8da3390672ac3",
  },
  {
    id: "60f7f125831ef10bec9777ae",
    name: "ageng'a nanguba",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15374a8da3390672ac3",
  },
  {
    id: "60f7f12c831ef10bec9777b3",
    name: "bwiri",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15374a8da3390672ac3",
  },
  {
    id: "60f7f289831ef10bec9777c7",
    name: "bunyala central.",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15974a8da3390672ac7",
  },
  {
    id: "60f7f291831ef10bec9777cc",
    name: "bunyala north",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15974a8da3390672ac7",
  },
  {
    id: "60f7f29e831ef10bec9777d3",
    name: "bunyala west.",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15974a8da3390672ac7",
  },
  {
    id: "60f7f2a3831ef10bec9777d8",
    name: "bunyala south",
    countyId: "60f06db6a26f650268a1f84e",
    constituencyId: "60f0c15974a8da3390672ac7",
  },
  {
    id: "60f7fc125fa1fb26f0ce48e2",
    name: "west ugenya",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c16874a8da3390672acb",
  },
  {
    id: "60f7fc245fa1fb26f0ce48e7",
    name: "ukwala",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c16874a8da3390672acb",
  },
  {
    id: "60f7fc2f5fa1fb26f0ce48ec",
    name: "north ugenya",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c16874a8da3390672acb",
  },
  {
    id: "60f7fc355fa1fb26f0ce48f1",
    name: "east ugenya",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c16874a8da3390672acb",
  },
  {
    id: "60f7fc405fa1fb26f0ce48f6",
    name: "sidindi",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17374a8da3390672acf",
  },
  {
    id: "60f7fc4c5fa1fb26f0ce48fb",
    name: "sigomere",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17374a8da3390672acf",
  },
  {
    id: "60f7fc515fa1fb26f0ce4900",
    name: "ugunja",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17374a8da3390672acf",
  },
  {
    id: "60f7fc635fa1fb26f0ce4905",
    name: "usonga",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17974a8da3390672ad3",
  },
  {
    id: "60f7fc695fa1fb26f0ce490a",
    name: "west alego",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17974a8da3390672ad3",
  },
  {
    id: "60f7fc705fa1fb26f0ce490f",
    name: "central alego",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17974a8da3390672ad3",
  },
  {
    id: "60f7fc7a5fa1fb26f0ce4914",
    name: "siaya township",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17974a8da3390672ad3",
  },
  {
    id: "60f7fc805fa1fb26f0ce4919",
    name: "north alego",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17974a8da3390672ad3",
  },
  {
    id: "60f7fc8b5fa1fb26f0ce491e",
    name: "south east alego",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c17974a8da3390672ad3",
  },
  {
    id: "60f7fca85fa1fb26f0ce4923",
    name: "north gem",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fcb65fa1fb26f0ce4928",
    name: "west gem",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fcbe5fa1fb26f0ce492d",
    name: "central gem",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fcc55fa1fb26f0ce4932",
    name: "yala",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fcd45fa1fb26f0ce4937",
    name: "gem township",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fcda5fa1fb26f0ce493c",
    name: "east gem",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fce15fa1fb26f0ce4941",
    name: "south gem",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18174a8da3390672ad7",
  },
  {
    id: "60f7fcec5fa1fb26f0ce4946",
    name: "west yimbo",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18774a8da3390672adb",
  },
  {
    id: "60f7fcf45fa1fb26f0ce494b",
    name: "central sakwa",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18774a8da3390672adb",
  },
  {
    id: "60f7fcfd5fa1fb26f0ce4950",
    name: "south sakwa",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18774a8da3390672adb",
  },
  {
    id: "60f7fd045fa1fb26f0ce4955",
    name: "yimbo east",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18774a8da3390672adb",
  },
  {
    id: "60f7fd0d5fa1fb26f0ce495a",
    name: "west sakwa",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18774a8da3390672adb",
  },
  {
    id: "60f7fd195fa1fb26f0ce495f",
    name: "north sakwa",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18774a8da3390672adb",
  },
  {
    id: "60f7fd375fa1fb26f0ce4964",
    name: "east asembo",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18f74a8da3390672adf",
  },
  {
    id: "60f7fd3e5fa1fb26f0ce4969",
    name: "west asembo",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18f74a8da3390672adf",
  },
  {
    id: "60f7fd445fa1fb26f0ce496e",
    name: "north uyoma",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18f74a8da3390672adf",
  },
  {
    id: "60f7fd4a5fa1fb26f0ce4973",
    name: "south uyoma",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18f74a8da3390672adf",
  },
  {
    id: "60f7fd515fa1fb26f0ce4978",
    name: "west uyoma",
    countyId: "60f06dfaa26f650268a1f856",
    constituencyId: "60f0c18f74a8da3390672adf",
  },
  {
    id: "60f7fda35fa1fb26f0ce497e",
    name: "kajulu",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1a674a8da3390672ae3",
  },
  {
    id: "60f7fdaa5fa1fb26f0ce4983",
    name: "kolwa east",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1a674a8da3390672ae3",
  },
  {
    id: "60f7fdb55fa1fb26f0ce4988",
    name: "manyatta b",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1a674a8da3390672ae3",
  },
  {
    id: "60f7fdc15fa1fb26f0ce498d",
    name: "nyalenda a",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1a674a8da3390672ae3",
  },
  {
    id: "60f7fdcc5fa1fb26f0ce4992",
    name: "kolwa central",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1a674a8da3390672ae3",
  },
  {
    id: "60f7fdd55fa1fb26f0ce4997",
    name: "south west kisumu",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ad74a8da3390672ae7",
  },
  {
    id: "60f7fe2d5fa1fb26f0ce49a0",
    name: "central kisumu",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ad74a8da3390672ae7",
  },
  {
    id: "60f7fe375fa1fb26f0ce49a5",
    name: "kisumu north",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ad74a8da3390672ae7",
  },
  {
    id: "60f7fe3f5fa1fb26f0ce49aa",
    name: "west kisumu",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ad74a8da3390672ae7",
  },
  {
    id: "60f7fe465fa1fb26f0ce49af",
    name: "north west kisumu",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ad74a8da3390672ae7",
  },
  {
    id: "60f7fe575fa1fb26f0ce49b4",
    name: "railways",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1e774a8da3390672aeb",
  },
  {
    id: "60f7fe5c5fa1fb26f0ce49b9",
    name: "migosi",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1e774a8da3390672aeb",
  },
  {
    id: "60f7fe615fa1fb26f0ce49be",
    name: "shaurimoyo kaloleni",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1e774a8da3390672aeb",
  },
  {
    id: "60f7fe685fa1fb26f0ce49c3",
    name: "market milimani",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1e774a8da3390672aeb",
  },
  {
    id: "60f7fe6d5fa1fb26f0ce49c8",
    name: "kondele",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1e774a8da3390672aeb",
  },
  {
    id: "60f7fe735fa1fb26f0ce49cd",
    name: "nyalenda b",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1e774a8da3390672aeb",
  },
  {
    id: "60f7fe825fa1fb26f0ce49d2",
    name: "west seme",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ed74a8da3390672aef",
  },
  {
    id: "60f7fe885fa1fb26f0ce49d7",
    name: "central seme",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ed74a8da3390672aef",
  },
  {
    id: "60f7fe9c5fa1fb26f0ce49dc",
    name: "east seme",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ed74a8da3390672aef",
  },
  {
    id: "60f7fea25fa1fb26f0ce49e1",
    name: "north seme",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1ed74a8da3390672aef",
  },
  {
    id: "60f7feb75fa1fb26f0ce49e6",
    name: "east kano/ waidhi",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f474a8da3390672af3",
  },
  {
    id: "60f7fec05fa1fb26f0ce49eb",
    name: "awasi/ onjiko",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f474a8da3390672af3",
  },
  {
    id: "60f7fec65fa1fb26f0ce49f0",
    name: "ahero",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f474a8da3390672af3",
  },
  {
    id: "60f7fecc5fa1fb26f0ce49f5",
    name: "kabonyo/ kanyag wal",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f474a8da3390672af3",
  },
  {
    id: "60f7fed25fa1fb26f0ce49fa",
    name: "kobura",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f474a8da3390672af3",
  },
  {
    id: "60f7fedd5fa1fb26f0ce49ff",
    name: "miwani",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f974a8da3390672af7",
  },
  {
    id: "60f7fee35fa1fb26f0ce4a04",
    name: "ombeyi",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f974a8da3390672af7",
  },
  {
    id: "60f7feed5fa1fb26f0ce4a09",
    name: "masogo/ nyag'oma",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f974a8da3390672af7",
  },
  {
    id: "60f7ff2f5fa1fb26f0ce4a0e",
    name: "chemeli",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f974a8da3390672af7",
  },
  {
    id: "60f7ff3a5fa1fb26f0ce4a13",
    name: "muhoroni/ koru",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c1f974a8da3390672af7",
  },
  {
    id: "60f7ff605fa1fb26f0ce4a19",
    name: "south east nyakach",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c20174a8da3390672afb",
  },
  {
    id: "60f7ff655fa1fb26f0ce4a1e",
    name: "west nyakach",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c20174a8da3390672afb",
  },
  {
    id: "60f7ff6b5fa1fb26f0ce4a23",
    name: "north nyakach",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c20174a8da3390672afb",
  },
  {
    id: "60f7ff705fa1fb26f0ce4a28",
    name: "central nyakach",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c20174a8da3390672afb",
  },
  {
    id: "60f7ff775fa1fb26f0ce4a2d",
    name: "south west nyakach",
    countyId: "60f06e0aa26f650268a1f859",
    constituencyId: "60f0c20174a8da3390672afb",
  },
  {
    id: "60f7ffc95fa1fb26f0ce4a33",
    name: "west kasipul",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22474a8da3390672aff",
  },
  {
    id: "60f7ffcd5fa1fb26f0ce4a38",
    name: "south kasipul",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22474a8da3390672aff",
  },
  {
    id: "60f7ffd55fa1fb26f0ce4a3d",
    name: "central kasipul",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22474a8da3390672aff",
  },
  {
    id: "60f7ffda5fa1fb26f0ce4a42",
    name: "east kamagak",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22474a8da3390672aff",
  },
  {
    id: "60f7ffe05fa1fb26f0ce4a47",
    name: "west kamagak",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22474a8da3390672aff",
  },
  {
    id: "60f7ffec5fa1fb26f0ce4a4c",
    name: "kabondo east",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22e74a8da3390672b03",
  },
  {
    id: "60f7fff45fa1fb26f0ce4a51",
    name: "kabondo west",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22e74a8da3390672b03",
  },
  {
    id: "60f800585fa1fb26f0ce4a56",
    name: "kokwanyo kakelo",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22e74a8da3390672b03",
  },
  {
    id: "60f8005f5fa1fb26f0ce4a5b",
    name: "kojwach",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c22e74a8da3390672b03",
  },
  {
    id: "60f800725fa1fb26f0ce4a60",
    name: "west karachuonyo",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c23474a8da3390672b07",
  },
  {
    id: "60f8007a5fa1fb26f0ce4a65",
    name: "north karachuonyo",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c23474a8da3390672b07",
  },
  {
    id: "60f800845fa1fb26f0ce4a6a",
    name: "central kanyaluo",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c23474a8da3390672b07",
  },
  {
    id: "60f800905fa1fb26f0ce4a6f",
    name: "kibiri",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c23474a8da3390672b07",
  },
  {
    id: "60f800945fa1fb26f0ce4a74",
    name: "wangchieng",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c23474a8da3390672b07",
  },
  {
    id: "60f800995fa1fb26f0ce4a79",
    name: "kendu bay town",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c23474a8da3390672b07",
  },
  {
    id: "60f800b95fa1fb26f0ce4a82",
    name: "kagan",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c24474a8da3390672b0b",
  },
  {
    id: "60f800bf5fa1fb26f0ce4a87",
    name: "kochia",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c24474a8da3390672b0b",
  },
  {
    id: "60f800ca5fa1fb26f0ce4a8c",
    name: "homa bay central",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c24c74a8da3390672b0f",
  },
  {
    id: "60f800d25fa1fb26f0ce4a91",
    name: "homa bay arujo",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c24c74a8da3390672b0f",
  },
  {
    id: "60f800d75fa1fb26f0ce4a96",
    name: "homa bay west",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c24c74a8da3390672b0f",
  },
  {
    id: "60f800e55fa1fb26f0ce4a9b",
    name: "homa bay east",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c24c74a8da3390672b0f",
  },
  {
    id: "60f800f55fa1fb26f0ce4aa0",
    name: "kwabwai",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f800fa5fa1fb26f0ce4aa5",
    name: "kanyadoto",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f800fe5fa1fb26f0ce4aaa",
    name: "kanyikela",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f801035fa1fb26f0ce4aaf",
    name: "kabuoch north",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f8010f5fa1fb26f0ce4ab4",
    name: "kabuoch south/ pala",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f801165fa1fb26f0ce4ab9",
    name: "kanyamwa kologi",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f8011c5fa1fb26f0ce4abe",
    name: "kanyamwa kosewe",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25474a8da3390672b13",
  },
  {
    id: "60f801695fa1fb26f0ce4ac3",
    name: "mfangano island",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25a74a8da3390672b17",
  },
  {
    id: "60f801705fa1fb26f0ce4ac8",
    name: "rusinga island",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25a74a8da3390672b17",
  },
  {
    id: "60f801795fa1fb26f0ce4acd",
    name: "kasgunga",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25a74a8da3390672b17",
  },
  {
    id: "60f801805fa1fb26f0ce4ad2",
    name: "gember",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25a74a8da3390672b17",
  },
  {
    id: "60f801855fa1fb26f0ce4ad7",
    name: "lambwe",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c25a74a8da3390672b17",
  },
  {
    id: "60f8019d5fa1fb26f0ce4adc",
    name: "gwassi south",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c26474a8da3390672b1b",
  },
  {
    id: "60f801a35fa1fb26f0ce4ae1",
    name: "gwassi north",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c26474a8da3390672b1b",
  },
  {
    id: "60f801ad5fa1fb26f0ce4ae6",
    name: "kaksingri west",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c26474a8da3390672b1b",
  },
  {
    id: "60f801b35fa1fb26f0ce4aeb",
    name: "ruma-kakshingri",
    countyId: "60f06e17a26f650268a1f85c",
    constituencyId: "60f0c26474a8da3390672b1b",
  },
  {
    id: "60f801f65fa1fb26f0ce4af1",
    name: "north kamagambo",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c28674a8da3390672b1f",
  },
  {
    id: "60f801fd5fa1fb26f0ce4af6",
    name: "central kamagambo",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c28674a8da3390672b1f",
  },
  {
    id: "60f802035fa1fb26f0ce4afb",
    name: "east kamagambo",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c28674a8da3390672b1f",
  },
  {
    id: "60f802085fa1fb26f0ce4b00",
    name: "south kamagambo",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c28674a8da3390672b1f",
  },
  {
    id: "60f802175fa1fb26f0ce4b05",
    name: "north east sakwa",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c28d74a8da3390672b23",
  },
  {
    id: "60f802375fa1fb26f0ce4b10",
    name: "god jope",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29374a8da3390672b27",
  },
  {
    id: "60f802405fa1fb26f0ce4b15",
    name: "suna central",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29374a8da3390672b27",
  },
  {
    id: "60f802475fa1fb26f0ce4b1a",
    name: "kakrao",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29374a8da3390672b27",
  },
  {
    id: "60f802855fa1fb26f0ce4b1f",
    name: "kwa",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29374a8da3390672b27",
  },
  {
    id: "60f802a65fa1fb26f0ce4b24",
    name: "wiga",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29974a8da3390672b2b",
  },
  {
    id: "60f802ac5fa1fb26f0ce4b29",
    name: "wasweta ii",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29974a8da3390672b2b",
  },
  {
    id: "60f802b75fa1fb26f0ce4b2e",
    name: "ragan-oruba",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29974a8da3390672b2b",
  },
  {
    id: "60f802bd5fa1fb26f0ce4b33",
    name: "wasimbete",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c29974a8da3390672b2b",
  },
  {
    id: "60f802cb5fa1fb26f0ce4b38",
    name: "west kanyamkago",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a374a8da3390672b2f",
  },
  {
    id: "60f802d05fa1fb26f0ce4b3d",
    name: "north kanyamkago",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a374a8da3390672b2f",
  },
  {
    id: "60f802d75fa1fb26f0ce4b42",
    name: "central kanyam kago",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a374a8da3390672b2f",
  },
  {
    id: "60f802df5fa1fb26f0ce4b47",
    name: "south kanyamkago",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a374a8da3390672b2f",
  },
  {
    id: "60f802e55fa1fb26f0ce4b4c",
    name: "east kanyamkago",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a374a8da3390672b2f",
  },
  {
    id: "60f802fa5fa1fb26f0ce4b51",
    name: "kachie'g",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f803015fa1fb26f0ce4b56",
    name: "kanyasa",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f803075fa1fb26f0ce4b5b",
    name: "north kadem",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f8030d5fa1fb26f0ce4b60",
    name: "macalder/ kanyarwanda",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f803135fa1fb26f0ce4b65",
    name: "kaler",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f8031a5fa1fb26f0ce4b6a",
    name: "got kachola",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f8031f5fa1fb26f0ce4b6f",
    name: "muhuru",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2a874a8da3390672b33",
  },
  {
    id: "60f8032d5fa1fb26f0ce4b74",
    name: "bukira east",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f803335fa1fb26f0ce4b79",
    name: "bukira central/ ikerege",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f803395fa1fb26f0ce4b7e",
    name: "isibania",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f8033f5fa1fb26f0ce4b83",
    name: "makerero",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f803445fa1fb26f0ce4b88",
    name: "masaba",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f803495fa1fb26f0ce4b8d",
    name: "tagare",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f803bd5fa1fb26f0ce4b92",
    name: "nyamosense/ komosoko",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2ae74a8da3390672b37",
  },
  {
    id: "60f803d25fa1fb26f0ce4b97",
    name: "gokeharaka/ getamwega",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2b574a8da3390672b3b",
  },
  {
    id: "60f803da5fa1fb26f0ce4b9c",
    name: "ntimaru west",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2b574a8da3390672b3b",
  },
  {
    id: "60f803e45fa1fb26f0ce4ba1",
    name: "ntimaru east",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2b574a8da3390672b3b",
  },
  {
    id: "60f803e95fa1fb26f0ce4ba6",
    name: "nyabasi east",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2b574a8da3390672b3b",
  },
  {
    id: "60f803f05fa1fb26f0ce4bab",
    name: "nyabasi west",
    countyId: "60f06e25a26f650268a1f85f",
    constituencyId: "60f0c2b574a8da3390672b3b",
  },
  {
    id: "60f804565fa1fb26f0ce4bb1",
    name: "bomariba",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2c674a8da3390672b3f",
  },
  {
    id: "60f8045e5fa1fb26f0ce4bb6",
    name: "bogiakumu",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2c674a8da3390672b3f",
  },
  {
    id: "60f804635fa1fb26f0ce4bbb",
    name: "bokeira",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2c674a8da3390672b3f",
  },
  {
    id: "60f804685fa1fb26f0ce4bc0",
    name: "riana",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2c674a8da3390672b3f",
  },
  {
    id: "60f8046e5fa1fb26f0ce4bc5",
    name: "bogetenga",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2c674a8da3390672b3f",
  },
  {
    id: "60f804785fa1fb26f0ce4bca",
    name: "borabu/ chitago",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2c674a8da3390672b3f",
  },
  {
    id: "60f8047d5fa1fb26f0ce4bcf",
    name: "moticho",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2cc74a8da3390672b43",
  },
  {
    id: "60f807a25fa1fb26f0ce4bd7",
    name: "getenga",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2cc74a8da3390672b43",
  },
  {
    id: "60f807ac5fa1fb26f0ce4bdc",
    name: "tabaka",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2cc74a8da3390672b43",
  },
  {
    id: "60f807b45fa1fb26f0ce4be1",
    name: "boikanga",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2cc74a8da3390672b43",
  },
  {
    id: "60f807ca5fa1fb26f0ce4be6",
    name: "borabu masaba",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2d474a8da3390672b47",
  },
  {
    id: "60f807d35fa1fb26f0ce4beb",
    name: "boochi borabu",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2d474a8da3390672b47",
  },
  {
    id: "60f807da5fa1fb26f0ce4bf0",
    name: "bokimonge",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2d474a8da3390672b47",
  },
  {
    id: "60f807e15fa1fb26f0ce4bf5",
    name: "magenche",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2d474a8da3390672b47",
  },
  {
    id: "60f807e95fa1fb26f0ce4bfa",
    name: "masige west",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f808355fa1fb26f0ce4bff",
    name: "masige east",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f8086f5fa1fb26f0ce4c04",
    name: "basi central",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f808745fa1fb26f0ce4c09",
    name: "nyacheki",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f808815fa1fb26f0ce4c0e",
    name: "bassi bogetaorio",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f808985fa1fb26f0ce4c13",
    name: "bobasi chache",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f8089d5fa1fb26f0ce4c18",
    name: "sameta/ mokwerero",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f808a45fa1fb26f0ce4c1d",
    name: "bobasi/ boitangare",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2da74a8da3390672b4b",
  },
  {
    id: "60f808b75fa1fb26f0ce4c22",
    name: "majoge basi",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2e174a8da3390672b4f",
  },
  {
    id: "60f808bf5fa1fb26f0ce4c27",
    name: "boochi/ tendere",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2e174a8da3390672b4f",
  },
  {
    id: "60f808ca5fa1fb26f0ce4c2c",
    name: "bosoti/ sengera",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2e174a8da3390672b4f",
  },
  {
    id: "60f808d45fa1fb26f0ce4c31",
    name: "ichuni",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2ec74a8da3390672b53",
  },
  {
    id: "60f808d95fa1fb26f0ce4c36",
    name: "nyamasibi",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2ec74a8da3390672b53",
  },
  {
    id: "60f808e15fa1fb26f0ce4c3b",
    name: "masimba",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2ec74a8da3390672b53",
  },
  {
    id: "60f808e55fa1fb26f0ce4c40",
    name: "gesusu",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2ec74a8da3390672b53",
  },
  {
    id: "60f808ec5fa1fb26f0ce4c45",
    name: "kiamokama",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2ec74a8da3390672b53",
  },
  {
    id: "60f808fb5fa1fb26f0ce4c4a",
    name: "bobaracho",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2f474a8da3390672b57",
  },
  {
    id: "60f809025fa1fb26f0ce4c4f",
    name: "kisii central",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2f474a8da3390672b57",
  },
  {
    id: "60f8090c5fa1fb26f0ce4c54",
    name: "keumbu",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2f474a8da3390672b57",
  },
  {
    id: "60f809135fa1fb26f0ce4c59",
    name: "kiogoro",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2f474a8da3390672b57",
  },
  {
    id: "60f809185fa1fb26f0ce4c5e",
    name: "birongo",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2f474a8da3390672b57",
  },
  {
    id: "60f8091d5fa1fb26f0ce4c63",
    name: "ibeno",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c2f474a8da3390672b57",
  },
  {
    id: "60f809295fa1fb26f0ce4c68",
    name: "monyerero",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30274a8da3390672b5b",
  },
  {
    id: "60f8092f5fa1fb26f0ce4c6d",
    name: "sensi",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30274a8da3390672b5b",
  },
  {
    id: "60f809345fa1fb26f0ce4c72",
    name: "marani",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30274a8da3390672b5b",
  },
  {
    id: "60f8093a5fa1fb26f0ce4c77",
    name: "mwamonari",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30274a8da3390672b5b",
  },
  {
    id: "60f809465fa1fb26f0ce4c7c",
    name: "bogusero",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30a74a8da3390672b5f",
  },
  {
    id: "60f8094b5fa1fb26f0ce4c81",
    name: "bogeka",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30a74a8da3390672b5f",
  },
  {
    id: "60f809535fa1fb26f0ce4c86",
    name: "nyakoe",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30a74a8da3390672b5f",
  },
  {
    id: "60f809595fa1fb26f0ce4c8b",
    name: "kitutu central",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30a74a8da3390672b5f",
  },
  {
    id: "60f8095f5fa1fb26f0ce4c90",
    name: "nyatieko",
    countyId: "60f06e32a26f650268a1f862",
    constituencyId: "60f0c30a74a8da3390672b5f",
  },
  {
    id: "60f8099d5fa1fb26f0ce4c96",
    name: "rigoma",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c31c74a8da3390672b63",
  },
  {
    id: "60f809c35fa1fb26f0ce4c9b",
    name: "gachuba",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c31c74a8da3390672b63",
  },
  {
    id: "60f809ca5fa1fb26f0ce4ca0",
    name: "kemera",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c31c74a8da3390672b63",
  },
  {
    id: "60f809d05fa1fb26f0ce4ca5",
    name: "magombo",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c31c74a8da3390672b63",
  },
  {
    id: "60f809d45fa1fb26f0ce4caa",
    name: "manga",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c31c74a8da3390672b63",
  },
  {
    id: "60f809de5fa1fb26f0ce4caf",
    name: "gesima",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c31c74a8da3390672b63",
  },
  {
    id: "60f809ee5fa1fb26f0ce4cb4",
    name: "nyamaiya",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32374a8da3390672b67",
  },
  {
    id: "60f809f45fa1fb26f0ce4cb9",
    name: "bogichora",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32374a8da3390672b67",
  },
  {
    id: "60f80a005fa1fb26f0ce4cbe",
    name: "bosamaro",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32374a8da3390672b67",
  },
  {
    id: "60f80a065fa1fb26f0ce4cc3",
    name: "bonyamatuta",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32374a8da3390672b67",
  },
  {
    id: "60f80a155fa1fb26f0ce4cc8",
    name: "nyamira township",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32374a8da3390672b67",
  },
  {
    id: "60f80a265fa1fb26f0ce4ccd",
    name: "itibo",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32c74a8da3390672b6b",
  },
  {
    id: "60f80a2b5fa1fb26f0ce4cd2",
    name: "bomwagamo",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32c74a8da3390672b6b",
  },
  {
    id: "60f80a365fa1fb26f0ce4cd9",
    name: "magwagwa",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32c74a8da3390672b6b",
  },
  {
    id: "60f80a3d5fa1fb26f0ce4cde",
    name: "ekerenyo",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c32c74a8da3390672b6b",
  },
  {
    id: "60f80a4a5fa1fb26f0ce4ce3",
    name: "mekenene",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c33274a8da3390672b6f",
  },
  {
    id: "60f80a4f5fa1fb26f0ce4ce8",
    name: "kiabonyoru",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c33274a8da3390672b6f",
  },
  {
    id: "60f80a565fa1fb26f0ce4ced",
    name: "esise",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c33274a8da3390672b6f",
  },
  {
    id: "60f80a605fa1fb26f0ce4cf2",
    name: "nyansiongo",
    countyId: "60f06e3fa26f650268a1f865",
    constituencyId: "60f0c33274a8da3390672b6f",
  },
  {
    id: "60f83913557035391c9eeb37",
    name: "kitisuru",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35474a8da3390672b73",
  },
  {
    id: "60f83921557035391c9eeb3c",
    name: "parklands/ highridge",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35474a8da3390672b73",
  },
  {
    id: "60f83928557035391c9eeb41",
    name: "karura",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35474a8da3390672b73",
  },
  {
    id: "60f8392e557035391c9eeb46",
    name: "kangemi",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35474a8da3390672b73",
  },
  {
    id: "60f83933557035391c9eeb4b",
    name: "mountain view",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35474a8da3390672b73",
  },
  {
    id: "60f8393e557035391c9eeb50",
    name: "kilimani",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35a74a8da3390672b77",
  },
  {
    id: "60f83942557035391c9eeb55",
    name: "kawangware",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35a74a8da3390672b77",
  },
  {
    id: "60f83947557035391c9eeb5a",
    name: "gatina",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35a74a8da3390672b77",
  },
  {
    id: "60f8394e557035391c9eeb5f",
    name: "kileleshwa",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35a74a8da3390672b77",
  },
  {
    id: "60f83953557035391c9eeb64",
    name: "kabiro",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c35a74a8da3390672b77",
  },
  {
    id: "60f8396c557035391c9eeb69",
    name: "mutu-ini",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36174a8da3390672b7b",
  },
  {
    id: "60f83971557035391c9eeb6e",
    name: "ngando",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36174a8da3390672b7b",
  },
  {
    id: "60f83976557035391c9eeb73",
    name: "riruta",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36174a8da3390672b7b",
  },
  {
    id: "60f8397d557035391c9eeb78",
    name: "uthiru/ ruthimitu",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36174a8da3390672b7b",
  },
  {
    id: "60f83984557035391c9eeb7d",
    name: "waithaka",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36174a8da3390672b7b",
  },
  {
    id: "60f83993557035391c9eeb82",
    name: "karen",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36874a8da3390672b7f",
  },
  {
    id: "60f83998557035391c9eeb87",
    name: "nairobi west",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36874a8da3390672b7f",
  },
  {
    id: "60f8399f557035391c9eeb8c",
    name: "nmugumu-ini",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36874a8da3390672b7f",
  },
  {
    id: "60f839a8557035391c9eeb91",
    name: "south c",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36874a8da3390672b7f",
  },
  {
    id: "60f839b2557035391c9eeb96",
    name: "nyayo highrise",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36874a8da3390672b7f",
  },
  {
    id: "60f83a56557035391c9eeb9b",
    name: "laini saba",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36f74a8da3390672b83",
  },
  {
    id: "60f83a5d557035391c9eeba0",
    name: "lindi",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36f74a8da3390672b83",
  },
  {
    id: "60f83a63557035391c9eeba5",
    name: "makina",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36f74a8da3390672b83",
  },
  {
    id: "60f83a69557035391c9eebaa",
    name: "woodley/ kenyatta",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36f74a8da3390672b83",
  },
  {
    id: "60f83a6f557035391c9eebaf",
    name: "golf course",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36f74a8da3390672b83",
  },
  {
    id: "60f83a75557035391c9eebb4",
    name: "sarangombe",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c36f74a8da3390672b83",
  },
  {
    id: "60f83a85557035391c9eebb9",
    name: "githurai",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37574a8da3390672b87",
  },
  {
    id: "60f83a89557035391c9eebbe",
    name: "kahawa west",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37574a8da3390672b87",
  },
  {
    id: "60f83a90557035391c9eebc3",
    name: "zimmerman",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37574a8da3390672b87",
  },
  {
    id: "60f83a99557035391c9eebc8",
    name: "roysambu",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37574a8da3390672b87",
  },
  {
    id: "60f83a9e557035391c9eebcd",
    name: "kahawa",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37574a8da3390672b87",
  },
  {
    id: "60f83aa7557035391c9eebd2",
    name: "clay city",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37c74a8da3390672b8b",
  },
  {
    id: "60f83ab2557035391c9eebd9",
    name: "mwiki.",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37c74a8da3390672b8b",
  },
  {
    id: "60f83b24557035391c9eebec",
    name: "kasarani",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37c74a8da3390672b8b",
  },
  {
    id: "60f83b3c557035391c9eebf2",
    name: "njiru",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37c74a8da3390672b8b",
  },
  {
    id: "60f83b42557035391c9eebf7",
    name: "ruai",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c37c74a8da3390672b8b",
  },
  {
    id: "60f83b57557035391c9eebfc",
    name: "baba dogo",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38374a8da3390672b8f",
  },
  {
    id: "60f83b62557035391c9eec01",
    name: "utalii",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38374a8da3390672b8f",
  },
  {
    id: "60f83b67557035391c9eec06",
    name: "mathare north",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38374a8da3390672b8f",
  },
  {
    id: "60f83b6d557035391c9eec0b",
    name: "lucky summer",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38374a8da3390672b8f",
  },
  {
    id: "60f83b74557035391c9eec10",
    name: "korogocho",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38374a8da3390672b8f",
  },
  {
    id: "60f83b7c557035391c9eec15",
    name: "imara daima",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38974a8da3390672b93",
  },
  {
    id: "60f83b81557035391c9eec1a",
    name: "kwa njenga",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38974a8da3390672b93",
  },
  {
    id: "60f83b86557035391c9eec1f",
    name: "kwa rueben",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38974a8da3390672b93",
  },
  {
    id: "60f83b8d557035391c9eec24",
    name: "pipeline",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38974a8da3390672b93",
  },
  {
    id: "60f83b91557035391c9eec29",
    name: "kware",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c38974a8da3390672b93",
  },
  {
    id: "60f83ba9557035391c9eec2e",
    name: "kariobangi north",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c39d74a8da3390672b97",
  },
  {
    id: "60f83bb2557035391c9eec33",
    name: "dandora area i",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c39d74a8da3390672b97",
  },
  {
    id: "60f83bbb557035391c9eec38",
    name: "dandora area ii",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c39d74a8da3390672b97",
  },
  {
    id: "60f83bc0557035391c9eec3d",
    name: "dandora area iii",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c39d74a8da3390672b97",
  },
  {
    id: "60f83bc5557035391c9eec42",
    name: "dandora area iv",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c39d74a8da3390672b97",
  },
  {
    id: "60f83bd3557035391c9eec47",
    name: "kayole north",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a374a8da3390672b9b",
  },
  {
    id: "60f83bd9557035391c9eec4c",
    name: "kayole central",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a374a8da3390672b9b",
  },
  {
    id: "60f83be0557035391c9eec51",
    name: "kayole south",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a374a8da3390672b9b",
  },
  {
    id: "60f83be7557035391c9eec56",
    name: "komarock",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a374a8da3390672b9b",
  },
  {
    id: "60f83bf1557035391c9eec5b",
    name: "matopeni/ spring valley",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a374a8da3390672b9b",
  },
  {
    id: "60f83c02557035391c9eec60",
    name: "upper savannah",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a974a8da3390672b9f",
  },
  {
    id: "60f83c07557035391c9eec65",
    name: "lower savannah",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a974a8da3390672b9f",
  },
  {
    id: "60f83c0d557035391c9eec6a",
    name: "embakasi",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a974a8da3390672b9f",
  },
  {
    id: "60f83c14557035391c9eec6f",
    name: "utawala",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a974a8da3390672b9f",
  },
  {
    id: "60f83c19557035391c9eec74",
    name: "mihango",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3a974a8da3390672b9f",
  },
  {
    id: "60f83c2a557035391c9eec79",
    name: "umoja i",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3af74a8da3390672ba3",
  },
  {
    id: "60f83c2e557035391c9eec7e",
    name: "umoja ii",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3af74a8da3390672ba3",
  },
  {
    id: "60f83c35557035391c9eec83",
    name: "mowlem",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3af74a8da3390672ba3",
  },
  {
    id: "60f83c3e557035391c9eec88",
    name: "kariobangi south",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3af74a8da3390672ba3",
  },
  {
    id: "60f83c42557035391c9eec8d",
    name: "maringo/ hamza",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3af74a8da3390672ba3",
  },
  {
    id: "60f83c55557035391c9eec94",
    name: "harambee",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3b574a8da3390672ba7",
  },
  {
    id: "60f83c5a557035391c9eec99",
    name: "makongeni",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3b574a8da3390672ba7",
  },
  {
    id: "60f83c5f557035391c9eec9e",
    name: "pumwani",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3b574a8da3390672ba7",
  },
  {
    id: "60f83c63557035391c9eeca3",
    name: "eastleigh north",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3b574a8da3390672ba7",
  },
  {
    id: "60f83ced557035391c9eecb2",
    name: "eastleigh south",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3bc74a8da3390672bab",
  },
  {
    id: "60f83cf6557035391c9eecb7",
    name: "airbase",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3bc74a8da3390672bab",
  },
  {
    id: "60f83cfb557035391c9eecbc",
    name: "california",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3bc74a8da3390672bab",
  },
  {
    id: "60f83d07557035391c9eecc1",
    name: "ngara",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3bc74a8da3390672bab",
  },
  {
    id: "60f83d0b557035391c9eecc6",
    name: "nairobi central",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3bc74a8da3390672bab",
  },
  {
    id: "60f83d19557035391c9eeccb",
    name: "pangani",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c174a8da3390672baf",
  },
  {
    id: "60f83d1f557035391c9eecd0",
    name: "ziwani/ kariokor",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c174a8da3390672baf",
  },
  {
    id: "60f83d26557035391c9eecd5",
    name: "landimawe",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c174a8da3390672baf",
  },
  {
    id: "60f83d2b557035391c9eecda",
    name: "nairobi south",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c174a8da3390672baf",
  },
  {
    id: "60f83d3b557035391c9eece1",
    name: "hospital.",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c174a8da3390672baf",
  },
  {
    id: "60f83d48557035391c9eece6",
    name: "mabatini",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c974a8da3390672bb3",
  },
  {
    id: "60f83d53557035391c9eeced",
    name: "huruma.",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c974a8da3390672bb3",
  },
  {
    id: "60f83d91557035391c9eecf9",
    name: "ngei",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c974a8da3390672bb3",
  },
  {
    id: "60f83da2557035391c9eecff",
    name: "mlango kubwa",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c974a8da3390672bb3",
  },
  {
    id: "60f83da8557035391c9eed04",
    name: "kiamaiko",
    countyId: "60f06e7ba26f650268a1f86d",
    constituencyId: "60f0c3c974a8da3390672bb3",
  },
];

export { counties, constituencies, wards };
